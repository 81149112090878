import { QueryParamsObject } from '@/case-detail/services/detail.view.route.service';
import { UUID } from '@/common/types/common.types';

class DetailViewPersistenceService {
  localStorageKey = 'CaseDetailViewState';

  allState: Record<
    UUID,
    {
      timestamp: number;
      data: QueryParamsObject;
    }
  >;

  constructor() {
    this.allState = JSON.parse(localStorage.getItem(this.localStorageKey) ?? '{}');
  }

  update(data: QueryParamsObject, caseId: UUID) {
    this.allState[caseId] = {
      timestamp: Date.now(),
      data,
    };
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.allState));
  }

  get(caseId: UUID) {
    const caseState = this.allState[caseId];
    if (!caseState) {
      return null;
    }

    // if older than a month, ignore
    const oneMonthInMs = 30 * 24 * 60 * 60 * 100;
    if (caseState.timestamp + oneMonthInMs < Date.now()) return null;

    return caseState.data;
  }
}

export const detailViewPersistenceService = new DetailViewPersistenceService();
