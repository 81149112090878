import { App } from 'vue';

import { authService } from '@/common/services/auth/auth.service';
import { Permission } from '@/common/services/auth/permissions';
import { TenantFeature, UserRole } from '@/common/services/entity.service';

export const vueAuthPlugin = {
  install: (app: App) => {
    app.config.globalProperties.$hasRole = (role: UserRole) => authService.hasRole(role);
    app.config.globalProperties.$hasFeature = (feature: TenantFeature) => authService.hasFeature(feature);
    app.config.globalProperties.$hasPermission = (permission: Permission) => authService.hasPermission(permission);
    app.config.globalProperties.$isLegaliAdmin = () => authService.hasPermission('LEGALI_ADMIN');
    app.config.globalProperties.$isExternalUser = () =>
      authService.hasRole('EXTERNAL_BASIC_ROLE') ||
      authService.hasRole('EXTERNAL_COORDINATOR_ROLE') ||
      authService.hasRole('EXTERNAL_ORGANISATION_ROLE');
    app.config.globalProperties.$currentUserId = () => authService.state.userId;
  },
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $hasRole: (role: UserRole) => boolean;
    $hasFeature: (feature: TenantFeature) => boolean;
    $hasPermission: (permission: Permission) => boolean;
    $isLegaliAdmin: () => boolean;
    $isExternalUser: () => boolean;
    $currentUserId: () => string | null;
  }
}
