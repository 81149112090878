import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { reactive } from 'vue';

import { config } from '@/app/config';
import { $t, i18nService } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import copilotCatalogService from '@/case-detail/subviews/copilot/services/copilot.catalog.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import entityService, {
  COPILOT_FORMAT_TWEAKS,
  CopilotCatalogQuestionLocalized,
  CopilotFormatTweak,
  USER_PROFESSIONS,
  UserProfession,
  UserProfessionValue,
} from '@/common/services/entity.service';
import logger from '@/common/services/logging';
import preferencesService from '@/common/services/preferences.service';
import { API } from '@/common/types/api.types';
import {
  AnswerTweak,
  ContextStrategy,
  ContextStrategyDescription,
  CopilotAnswer,
  CopilotCatalogQuestionHistoryResponse,
  CopilotConversationEntry,
  CopilotHistoryResponse,
  CopilotQuestion,
  CopilotRequest,
  CopilotResponse,
} from '@/common/types/api-types/copilot.api.types';
import { EmptyObject } from '@/common/types/common.types';

export type CopilotMessage = CopilotConversationEntry & {
  quote?: string;
  score?: number;
  error?: boolean;
  showPlausibilitySourcesIssues?: boolean;
  showPlausibilityAssessmentsIssues?: boolean;
};

class CopilotService {
  USER_PROFESSIONS: USER_PROFESSIONS = {};

  FORMAT_TWEAKS: COPILOT_FORMAT_TWEAKS | EmptyObject = {};

  state: {
    scope: API.Copilot.Scope;
    isLoading: boolean;
    isFatalError: boolean;
    requestId: string | null;
    historyId: string | undefined;
    conversation: CopilotMessage[];
    followUpQuestions: API.Copilot.FollowUpQuestion[] | undefined;
    contextStrategies: ContextStrategyDescription[];

    defaults: {
      agent: API.Copilot.Agent;
      contextStrategy: ContextStrategy;
      profession: UserProfessionValue;
      language: string;
      tweaks: AnswerTweak[];
      checkPlausibility: boolean;
      addReferences: boolean;
      multipleAnswers: API.Copilot.MultipleAnswers;
      model: string;
    };

    playgroundState: {
      question: CopilotQuestion | null;
    };

    documentViewerState: {
      documentId: string | undefined;
      page: number; // 1-based
    };
  };

  constructor(scope: API.Copilot.Scope) {
    this.state = reactive({
      scope,
      isFatalError: false,
      isLoading: false,
      requestId: null,
      historyId: undefined,
      conversation: [], // list of questions & answers
      followUpQuestions: [],
      contextStrategies: [],

      defaults: {
        agent: 'PLAN',
        contextStrategy: 'PLAN',
        contextSource: '',
        profession: entityService.USER_PROFESSIONS.OTHER,
        tweaks: [] as AnswerTweak[],
        language: 'en',
        checkPlausibility: false,
        addReferences: false,
        multipleAnswers: 'PLAN',
        model: 'gpt-4o',
      },

      // debug
      playgroundState: {
        question: {
          prompt: '',
          displayPrompt: '',
          contextStrategy: 'PLAN',
          contextSource: '',
          agent: 'PLAN',
          multipleAnswers: 'SINGLE',
          tweaks: [],
          targetLanguage: 'en',
          profession: 'OTHER',
          checkPlausibility: false,
          addReferences: false,
        },
      },

      documentViewerState: {
        documentId: undefined,
        page: 1,
      },
    });

    i18nService.runAfterLocaleInit(() => {
      this.FORMAT_TWEAKS = entityService.COPILOT_FORMAT_TWEAKS;
      this.USER_PROFESSIONS = entityService.USER_PROFESSIONS;
      this.state.defaults.language = i18nService.state.locale.language;
    });

    // events
    broadcastEventBus.subscribe('DOCUMENT_CHANGED_EVENT', this.handleDocumentChangedEvent.bind(this));
  }

  // Getters
  getScope(): API.Copilot.Scope {
    return this.state.scope;
  }

  getFeatureName(): string {
    const scope = this.getScope();
    return scope === 'DOCUMENT' ? 'DocPilot' : 'CasePilot';
  }

  isLoading(): boolean {
    return this.state.isLoading || this.state.isFatalError;
  }

  isFatalError(): boolean {
    return this.state.isFatalError;
  }

  getConversation(): CopilotMessage[] {
    return this.state.conversation;
  }

  getDefaultContextSource(): string | undefined {
    if (this.state.scope === 'DOCUMENT') {
      return this.getDocumentViewerState().documentId!;
    }

    return undefined;
  }

  // NOTE(ndv): returns the latest 10 conversation question-answer pairs, omitting erroneous ones.
  getConversationHistory(): CopilotConversationEntry[] {
    const history = [];

    for (const conversationEntry of this.getConversation()) {
      if (conversationEntry?.error) {
        // assistant error, remove last user message as well
        history.pop();
        continue;
      }

      if (conversationEntry.qa) {
        for (const text of conversationEntry.qa) {
          if ('debugger' in text) {
            delete text.debugger;
          }
        }
      }

      delete conversationEntry.score;
      delete conversationEntry.error;
      delete conversationEntry.showPlausibilityAssessmentsIssues;
      delete conversationEntry.showPlausibilitySourcesIssues;

      history.push(conversationEntry);
    }

    // remove last entry because it is the current question and not part of the history
    history.pop();

    // limit history to 20 items
    return history.slice(-20);
  }

  getSuggestedPrompts(catalogKey: API.Copilot.Scope): CopilotCatalogQuestionLocalized[] {
    const catalog = copilotCatalogService.getCatalogLocalized(catalogKey);
    const prompts = Object.values(catalog).filter((p) => p.showAsSuggestion);

    // filter by metadata
    const document = documentService.getSelected();
    if (!document?.id) {
      return prompts;
    }

    return prompts.filter((p) => {
      if (!p?.conditionalOnDocType) {
        return true;
      }
      return document.metadata.DOCTYPE.value.startsWith(p.conditionalOnDocType);
    });
  }

  getTweakPrompts(): CopilotCatalogQuestionLocalized[] {
    return Object.entries(copilotCatalogService.getCatalogLocalized('DOCUMENT'))
      .filter(([key]) => key.startsWith('TWEAK_'))
      .map(([, prompt]) => prompt);
  }

  getFormatTweaks(): CopilotFormatTweak[] {
    return Object.values(this.FORMAT_TWEAKS).filter((tweak) => tweak.display);
  }

  getAgents(): { key: API.Copilot.Agent; description: string }[] {
    // NOTE(mba): move to json to make it reusable in PLAN agent
    return [
      { key: 'PLAN', description: 'Agent that is automatically selected based on the question.' },
      {
        key: 'GENERAL',
        description: 'Agent that is used to reply to general questions that are not about evaluating a personal injury insurance case.',
      },
      {
        key: 'MEDICAL',
        description: 'Agent that is used to reply to general questions that are not about evaluating a personal injury insurance case.',
      },
    ];
  }

  getMultipleAnswers(): { key: API.Copilot.MultipleAnswers; description: string }[] {
    return [
      { key: 'SINGLE', description: 'Generate just one answer.' },
      {
        key: 'BIASED',
        description: 'Generate biased answers if possible.',
      },
      {
        key: 'COMPARE_CONTEXT_STRATEGY',
        description: 'Generate different answers using mutliple contexts.',
      },
      {
        key: 'COMPARE_LLM',
        description: 'Generate different answers using mutliple LLMs.',
      },
      {
        key: 'PLAN',
        description: 'Let the planning agent decide if multiple answers make sense.',
      },
    ];
  }

  getContextSourcePlaceholder(contextStrategy: ContextStrategy): string {
    for (const strategy of this.state.contextStrategies) {
      if (strategy.key === contextStrategy) {
        return strategy.contextSourcePlaceholder ?? '';
      }
    }
    return '';
  }

  getFollowUpQuestions(): CopilotCatalogQuestionLocalized[] {
    if (!this.state.followUpQuestions) {
      return [];
    }

    return this.state.followUpQuestions.map((question) => ({
      key: uuidv4() as unknown as string, // typing hack to add dynamic item
      title: question.preview,
      prompt: question.followup,
      description: question.followup,
      showAsSuggestion: true,
      resetHistory: false,
      addReferences: false,
      checkPlausibility: false,
      category: 'AI',
      agent: 'PLAN',
    }));
  }

  getUserProfessions(): UserProfessionValue[] {
    // filter professions that do not have a prompt
    return Object.values(this.USER_PROFESSIONS).filter((p) => !!p.promptDescription);
  }

  getDocumentViewerState(): { documentId: string | undefined; page: number } {
    return this.state.documentViewerState;
  }

  // Setters

  setFatalError(isFatalError: boolean) {
    this.state.isFatalError = isFatalError;
  }

  setDocumentViewerState(documentId: string, page: number) {
    if (documentId && this.state.documentViewerState.documentId !== documentId) {
      this.state.documentViewerState.documentId = documentId;
    }

    if (page && page > 1 && this.state.documentViewerState.page !== page) {
      this.state.documentViewerState.page = page;
    }
  }

  loadConversation(copilotResponse: CopilotResponse): void {
    this.state.historyId = copilotResponse.historyId;
    this.state.conversation = copilotResponse.conversationItems;
  }

  init(): void {
    const { scope } = this.state;

    let myProfession = authService.state.data!.userDetails.profession!;

    if (myProfession.includes('_EXTERNAL')) {
      myProfession = <UserProfession>myProfession.replace('_EXTERNAL', '');
    }

    const userProfession = this.USER_PROFESSIONS[myProfession];
    const userTweaks = preferencesService.state.copilotPreferences.tweaks;

    if (scope === 'LEGALCASE') {
      this.state.defaults = {
        agent: 'PLAN',
        contextStrategy: 'PLAN',
        language: i18nService.state.locale.language,
        profession: userProfession,
        tweaks: userTweaks as AnswerTweak[],
        multipleAnswers: 'PLAN',
        checkPlausibility: true,
        addReferences: true,
        model: 'gpt-4o',
      };
    } else if (scope === 'DOCUMENT') {
      this.state.defaults = {
        agent: 'PLAN',
        contextStrategy: 'DOCUMENT',
        language: i18nService.state.locale.language,
        profession: userProfession,
        tweaks: userTweaks as AnswerTweak[],
        multipleAnswers: 'PLAN',
        checkPlausibility: true,
        addReferences: false,
        model: 'gpt-4o',
      };
    }
  }

  selectAnswer(selectedQAIndex: number) {
    const lastConversationEntry = this.state.conversation[this.state.conversation.length - 1];
    if (!lastConversationEntry) {
      return;
    }

    lastConversationEntry.selectedQAIndex = selectedQAIndex;
  }

  selectedAnswer(message: CopilotMessage): CopilotAnswer {
    return <CopilotAnswer>message.qa[message.selectedQAIndex];
  }

  displayCost(message: CopilotMessage): string {
    let totalCompletionTokens = 0;
    let totalPromptTokens = 0;
    for (const qa of message.qa) {
      if ('text' in qa) {
        if (qa.debugger?.totalCompletionTokens) {
          totalCompletionTokens += qa.debugger.totalCompletionTokens;
        }
        if (qa.debugger?.totalPromptTokens) {
          totalPromptTokens += qa.debugger.totalPromptTokens;
        }
      }
    }

    const totalCost = (totalPromptTokens * 5) / 1_000_000 + (totalCompletionTokens * 15) / 1_000_000;
    const totalCostRounded = Math.round(totalCost * 1_000) / 1_000;
    let cost = `Prompt Tokens    : ${totalPromptTokens}\n`;
    cost += `Completion Tokens: ${totalCompletionTokens}\n`;
    cost += `Cost             : ${totalCostRounded}$`;
    return cost;
  }

  prompt(message: CopilotMessage): string {
    const question = <CopilotQuestion>message.qa[message.selectedQAIndex];
    if (question.displayPrompt) {
      return question.displayPrompt;
    }
    return question.prompt;
  }

  quote(message: CopilotMessage): string {
    const question = <CopilotQuestion>message.qa[message.selectedQAIndex];
    return question.quote ?? '';
  }

  // Actions
  async sendQuestion(
    question: Partial<CopilotQuestion>,
    context: Record<string, string> = {},
    resetHistory: boolean = false,
    playground: boolean = false,
  ): Promise<void> {
    this.state.isLoading = true;
    this.state.followUpQuestions = [];

    if (resetHistory) {
      this.state.conversation = [];
    }

    const mergedContext = {
      // common params, these are used for all prompts
      language: $t(`Common.Language.${i18nService.state.locale.language}`),
      profession: this.state.defaults.profession.promptDescription,
      // prompt-specific context
      ...context,
    };

    const processedQuestion: CopilotQuestion = {
      catalogKey: question.catalogKey,
      prompt: this.processPrompt(question.prompt!, mergedContext),
      displayPrompt: question.displayPrompt,
      quote: question.quote,
      contextStrategy: question.contextStrategy ?? this.state.defaults.contextStrategy,
      contextSource: question.contextSource || this.getDefaultContextSource(),
      agent: question.agent ?? this.state.defaults.agent,
      tweaks: question.tweaks ?? this.state.defaults.tweaks,
      targetLanguage: question.targetLanguage ?? this.state.defaults.language,
      profession: question.profession ?? this.state.defaults.profession.key,
      multipleAnswers: question.multipleAnswers ?? this.state.defaults.multipleAnswers,
      checkPlausibility: question.checkPlausibility ?? this.state.defaults.checkPlausibility,
      addReferences: question.addReferences ?? this.state.defaults.addReferences,
    };

    this.state.conversation.push({
      role: 'USER',
      timestamp: dayjs().toISOString(),
      userId: authService.state.userId!,
      selectedQAIndex: 0,
      qa: [processedQuestion],
    });

    try {
      const requestId = uuidv4();
      this.state.requestId = requestId;

      const requestParams: API.Copilot.CopilotRequest = {
        requestId,
        historyId: this.state.historyId,
        conversationItems: this.getConversationHistory(),
        question: processedQuestion,
        model: this.state.defaults.model,
      };

      const response = await this.request(requestParams, playground);
      // avoid sync issues with multiple requests and delayed responses
      if (this.state.requestId !== response.requestId) {
        return;
      }

      let success = true;
      const errorMessage: Set<string> = new Set();
      for (const qa of response.conversationItems[response.conversationItems.length - 1].qa) {
        if ('success' in qa && !qa.success) {
          success = false;
          if (qa.errorMessage) {
            errorMessage.add(qa.errorMessage);
          }
        }
      }

      if (!success) {
        this.state.conversation.push({
          timestamp: dayjs().toISOString(),
          role: 'ASSISTANT',
          error: true,
          selectedQAIndex: 0,
          qa: [
            {
              text: $t('Copilot.sorryCannotAnswerThat'),
              title: '',
              success: false,
              errorMessage: Array.from(errorMessage).join(', '),
            },
          ],
        });
        $a.l($a.e.COPILOT_CHAT_ANSWER_FAIL, {
          prompt: processedQuestion.prompt,
        });
        return;
      }

      this.state.historyId = response.historyId;
      this.state.conversation = response.conversationItems;

      const fuq: API.Copilot.FollowUpQuestion[] = [];
      const lastConversationItem = response.conversationItems[response.conversationItems.length - 1];
      for (const cit of lastConversationItem.qa) {
        if ('followUpQuestions' in cit && cit.followUpQuestions) {
          for (const fuqAnswer of cit.followUpQuestions) {
            fuq.push(fuqAnswer);
          }
        }
      }
      this.state.followUpQuestions = fuq;

      if (!playground) {
        this.updatePlaygroundState(requestParams);
      }
    } catch (error) {
      // add error answer
      this.state.conversation.push({
        timestamp: dayjs().toISOString(),
        role: 'ASSISTANT',
        error: true,
        selectedQAIndex: 0,
        qa: [
          {
            text: $t('Copilot.sorryCannotAnswerThat'),
            title: '',
            success: false,
            errorMessage: 'Internal Server Error',
          },
        ],
      });
      logger.error(error);
      $a.l($a.e.COPILOT_CHAT_ANSWER_FAIL, {
        prompt,
      });
    } finally {
      this.state.isLoading = false;
    }
  }

  async chatPlayground() {
    await this.sendQuestion(this.state.playgroundState.question!, {}, false, true);
  }

  updatePlaygroundState(payload: CopilotRequest): void {
    this.state.playgroundState = {
      ...this.state.playgroundState,
      question: payload.question,
    };
  }

  async explain(text: string, context: string) {
    if (this.isLoading()) {
      return Promise.resolve();
    }
    const promptTemplate = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', 'EXPLAIN');
    if (!promptTemplate) return Promise.resolve();

    $a.l($a.e.COPILOT_QUESTION, {
      key: promptTemplate.key,
      prompt: promptTemplate.prompt,
      scope: this.getScope(),
    });

    const question = this.mapPromptToQuestion(promptTemplate!);
    question.quote = text;
    return this.sendQuestion(question, { text, context, profession: this.state.defaults.profession.promptDescription }, promptTemplate.resetHistory);
  }

  async explainDiagnosis(diagnosis: string) {
    if (this.isLoading()) {
      return Promise.resolve();
    }
    const promptTemplate = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', 'EXPLAIN_DIAGNOSIS');
    if (!promptTemplate) return Promise.resolve();

    $a.l($a.e.COPILOT_QUESTION, {
      key: promptTemplate.key,
      prompt: promptTemplate.prompt,
      scope: this.getScope(),
    });

    const question = this.mapPromptToQuestion(promptTemplate);
    question.quote = diagnosis;
    return this.sendQuestion(
      question,
      { context: diagnosis, profession: this.state.defaults.profession.promptDescription },
      promptTemplate.resetHistory,
    );
  }

  async summarize() {
    if (this.isLoading()) return Promise.resolve();
    const summarizePrompt = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', 'SUMMARIZE');
    if (!summarizePrompt) return Promise.resolve();

    const question = this.mapPromptToQuestion(summarizePrompt);
    return this.sendQuestion(question, {}, summarizePrompt.resetHistory);
  }

  async tailor(answer: string, professionKey: UserProfession) {
    if (this.isLoading()) {
      return Promise.resolve();
    }

    const profession = this.USER_PROFESSIONS[professionKey] ?? this.USER_PROFESSIONS.ADMIN;
    if (!profession) {
      return Promise.resolve();
    }

    const tailoredAnswerPrompt = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', 'TAILOR_ANSWER');
    if (!tailoredAnswerPrompt) return Promise.resolve();

    const promptToDisplay = tailoredAnswerPrompt.description.replace('{{profession}}', profession.title);
    const prompt = tailoredAnswerPrompt.prompt.replace('{{profession}}', profession.promptDescription);

    const question = this.mapPromptToQuestion(tailoredAnswerPrompt);
    question.prompt = prompt;
    question.displayPrompt = promptToDisplay;

    return this.sendQuestion(question, { context: answer }, tailoredAnswerPrompt.resetHistory);
  }

  async tweak(answer: string, tweakKey: string): Promise<string | void> {
    if (this.isLoading()) {
      return Promise.resolve();
    }

    const tweak = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', tweakKey);
    if (!tweak) {
      return Promise.resolve();
    }

    const question = this.mapPromptToQuestion(tweak);
    return this.sendQuestion(question, { context: answer }, tweak.resetHistory);
  }

  async translateAnswer(answer: string, language: string): Promise<string | void> {
    if (this.isLoading()) {
      return Promise.resolve();
    }

    const languageDescription = $t(`Common.Language.${language}`);
    const translateAnswerPrompt = copilotCatalogService.getCatalogQuestionLocalized('DOCUMENT', 'TRANSLATE_ANSWER');
    if (!translateAnswerPrompt) return Promise.resolve();

    const promptToDisplay = translateAnswerPrompt.description.replace('{{language}}', languageDescription);

    const question = this.mapPromptToQuestion(translateAnswerPrompt);
    question.targetLanguage = language;
    question.displayPrompt = promptToDisplay;

    return this.sendQuestion(question, { context: answer, language: languageDescription }, translateAnswerPrompt.resetHistory);
  }

  async getCatalogQuestion(question: Partial<CopilotQuestion>): Promise<CopilotCatalogQuestionHistoryResponse> {
    const requestParams: API.Copilot.CopilotRequest = {
      requestId: uuidv4(),
      conversationItems: [],
      question: question as CopilotQuestion,
      model: this.state.defaults.model,
    };

    const response = await this.request(requestParams);

    let success = true;
    const errorMessage: Set<string> = new Set();
    for (const qa of response.conversationItems[response.conversationItems.length - 1].qa) {
      if ('success' in qa && !qa.success) {
        success = false;
        if (qa.errorMessage) {
          errorMessage.add(qa.errorMessage);
        }
      }
    }

    return {
      copilotResponse: response,
      userId: '',
      timestamp: '',
      outdated: false,
      success,
      errorMessage: Array.from(errorMessage).join(', '),
    } as CopilotCatalogQuestionHistoryResponse;
  }

  mapPromptToQuestion(prompt: CopilotCatalogQuestionLocalized): Partial<CopilotQuestion> {
    const contextSourcePlaceholder = this.getContextSourcePlaceholder(prompt.contextStrategy!);

    let contextSource = '';
    if (contextSourcePlaceholder === '{documentId}') {
      contextSource = this.state.documentViewerState.documentId!;
    } else if (contextSourcePlaceholder === '{documentId}:{page}') {
      contextSource = `${this.state.documentViewerState.documentId}:${this.state.documentViewerState.page}`;
    }

    // only set catalogKey when conversation is empty
    const firstQuestion = this.state.conversation.length === 0;

    return {
      ...(firstQuestion && { catalogKey: String(prompt.key) }),
      prompt: prompt.prompt,
      displayPrompt: prompt.description,
      contextStrategy: prompt.contextStrategy,
      contextSource,
      agent: prompt.agent,
      checkPlausibility: !!prompt.checkPlausibility,
      addReferences: !!prompt.addReferences,
      multipleAnswers: prompt.multipleAnswers,
      tweaks: prompt.tweaks ?? [],
    };
  }

  // API
  async request(payload: CopilotRequest, playground: boolean = false): Promise<CopilotResponse> {
    let endpointUrl: string;
    if (playground) {
      endpointUrl = config.API.COPILOT.PLAYGROUND.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId());
    } else if (this.state.scope === 'LEGALCASE') {
      endpointUrl = config.API.COPILOT.LEGALCASE.BASE.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId());
    } else {
      endpointUrl = config.API.COPILOT.DOCUMENT.BASE.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()).replace(
        '{documentId}',
        this.state.documentViewerState.documentId!,
      );
    }

    return axios
      .post<CopilotResponse>(endpointUrl, payload, {
        timeout: 180_000, // NOTE(ndv): to be tweaked if requests are very slow (prevents requests from hanging if the backend times out)
      })
      .then((response) => response.data);
  }

  async listHistories(): Promise<CopilotHistoryResponse[]> {
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    const documentId = this.state.documentViewerState.documentId!;
    let endpointUrl = this.state.scope === 'LEGALCASE' ? config.API.COPILOT.LEGALCASE.HISTORY : config.API.COPILOT.DOCUMENT.HISTORY;
    endpointUrl = endpointUrl.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId);

    const response = await axios.get<CopilotHistoryResponse[]>(endpointUrl);
    return response.data;
  }

  async deleteHistory(historyId: string): Promise<void> {
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    const documentId = this.state.documentViewerState.documentId!;
    let endpointUrl = this.state.scope === 'LEGALCASE' ? config.API.COPILOT.LEGALCASE.HISTORY : config.API.COPILOT.DOCUMENT.HISTORY;
    endpointUrl = `${endpointUrl}/${historyId}`;
    endpointUrl = endpointUrl.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId);
    await axios.delete(endpointUrl);
    appService.info($t('Copilot.previousConversationSuccessfullyDeleted'));
  }

  async fetchHistory(historyId: string): Promise<void> {
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    const documentId = this.state.documentViewerState.documentId!;
    let endpointUrl = this.state.scope === 'LEGALCASE' ? config.API.COPILOT.LEGALCASE.HISTORY : config.API.COPILOT.DOCUMENT.HISTORY;
    endpointUrl = endpointUrl.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId);
    endpointUrl = `${endpointUrl}/${historyId}`;
    const response = await axios.get<CopilotResponse>(endpointUrl);
    this.state.historyId = response.data.historyId;
    this.state.conversation = response.data.conversationItems;
  }

  async fetchCatalogKeyHistory(catalogKey: string): Promise<AxiosResponse> {
    const legalCaseId = detailViewService.getCurrentLegalCaseId();

    return axios.get<CopilotCatalogQuestionHistoryResponse>(
      config.API.COPILOT.CATALOGKEY_HISTORY.replace('{legalCaseId}', legalCaseId).replace('{catalogKey}', catalogKey),
    );
  }

  async fetchContextStrategies(): Promise<void> {
    const response = await axios.get<ContextStrategyDescription[]>(config.API.COPILOT.CONTEXT_STRATEGIES);
    this.state.contextStrategies = response.data;
  }

  async clearCopilotAnswer(catalogKey: string): Promise<void> {
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    return axios.delete(config.API.INTERNAL.CLEAR_COPILOT_ANSWER.replace('{legalCaseId}', legalCaseId).replace('{catalogKey}', catalogKey));
  }

  async sendFeedback(category: string, message: string): Promise<void> {
    const payload = {
      historyId: this.state.historyId,
      category,
      message,
    };

    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    if (this.getScope() === 'DOCUMENT') {
      const documentId = this.state.documentViewerState.documentId!;
      return axios.post(config.API.COPILOT.DOCUMENT.FEEDBACK.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId), payload);
    }

    return axios.post(config.API.COPILOT.LEGALCASE.FEEDBACK.replace('{legalCaseId}', legalCaseId), payload);
  }

  processPrompt(prompt: string, params: Record<string, any>): string {
    for (const key of Object.keys(params)) {
      prompt = prompt.replace(`{{${key}}}`, params[key]);
    }

    return prompt;
  }

  handleDocumentChangedEvent(event: any): void {
    this.setDocumentViewerState(event.docId, event.page);
  }

  // Helpers
  clear(): void {
    this.state.conversation = [];
    this.state.requestId = null;
    this.state.historyId = undefined;
    this.state.followUpQuestions = [];
    this.state.isLoading = false;
  }

  destroy(): void {
    broadcastEventBus.unsubscribe('DOCUMENT_CHANGED_EVENT', this.handleDocumentChangedEvent.bind(this));
  }
}

export const CopilotServiceClass = CopilotService;
export const casePilotService = new CopilotService('LEGALCASE');
export const docPilotService = new CopilotService('DOCUMENT');
