import { reactive } from 'vue';

import { i18nService } from '@/app/i18n/i18n.service';
import entityService, { DIAGNOSE_QUALIFICATIONS } from '@/common/services/entity.service';

type IcdChapter = {
  codesRange: string; // used as chapter key
  title: string;
};

type IcdDiagnosis = {
  code: string;
  title: string;
  chapter: string; // chapter key — code range
};

class ICD10Service {
  tags = {} as DIAGNOSE_QUALIFICATIONS;

  chapters = {} as Record<string, IcdChapter>;

  diagnoses = {} as Record<string, IcdDiagnosis>;

  state: {
    tags: string[];
  };

  constructor() {
    this.state = reactive({
      tags: [],
    });

    i18nService.runAfterLocaleInit(() => {
      this.tags = entityService.DIAGNOSE_QUALIFICATIONS;

      const diagnosesConfig = entityService.DIAGNOSES;
      this.chapters = Object.fromEntries(
        diagnosesConfig.map((chapter) => [chapter.codeRange, { codesRange: chapter.codeRange, title: chapter.title }]),
      );
      this.diagnoses = Object.fromEntries(
        diagnosesConfig.map((chapter) => chapter.diagnoses.map((d) => [d.code, { ...d, chapter: chapter.codeRange }])).flat(),
      );
    });
  }

  isPresent(code: string) {
    return code in this.diagnoses;
  }

  getMatchedDiagnoses(query: string, otherDiagnosisCode?: string) {
    return Object.values(this.diagnoses).filter(
      (d) =>
        (!!otherDiagnosisCode && d.code === otherDiagnosisCode) ||
        d.title.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        d.code.toLowerCase().indexOf(query.toLowerCase()) > -1,
    );
  }

  getChapter(chapterCode: string) {
    return this.chapters[chapterCode] ?? null;
  }

  getDiagnosis(code: string) {
    return this.diagnoses[code] ?? null;
  }

  getDiagnosisTitle(code: string) {
    return this.getDiagnosis(code)?.title ?? null;
  }

  getTags() {
    return Object.values(this.tags);
  }

  getHiddenTags() {
    return Object.values(this.tags).filter((t) => !!t.hidden);
  }

  getTagsKeys() {
    return Object.keys(this.tags);
  }

  getTag(key: string) {
    return this.tags[key] ?? null;
  }

  translateTagToLong(tag: string) {
    return this.tags[tag]?.titleLong ?? null;
  }
}

export default new ICD10Service();
export const ICD10ServiceClass = ICD10Service;
