import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';

class FileService {
  async get(uri: string) {
    return axios
      .get(config.API.FILE_STORE_ENDPOINT + uri, {
        responseType: 'arraybuffer',
      })
      .then((response) => new Blob([response.data]))
      .catch((e) => {
        handleError($t('Common.File.loadError'), e);
        return null;
      });
  }

  async download(uri: string, filename: string) {
    return this.get(uri).then((blob) => {
      const url = window.URL.createObjectURL(blob!);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  // eslint-disable-next-line no-undef
  async computeSHA256Hash(fileBuffer: BufferSource) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  }

  async upload(file: Blob, progressReference: number | null = null) {
    const presignedUploadURIResponse = await this.getPresignedUploadURI();
    const fileBuffer = await this.readFileAsync(file);
    // eslint-disable-next-line no-undef
    const sha256Hash = await this.computeSHA256Hash(fileBuffer as BufferSource);

    const headers = {
      'Content-Type': file.type,
      'x-amz-content-sha256': sha256Hash,
    };

    return axios
      .put(presignedUploadURIResponse.presignedUploadURI, file, {
        headers,
        onUploadProgress: (progressEvent) => {
          if (progressReference !== null) {
            progressReference = Math.floor(progressEvent.loaded / progressEvent.total!);
          }
        },
      })
      .then(() => presignedUploadURIResponse);
  }

  getUrlSync(fileUri: string) {
    return config.API.HOST + config.API.FILE_STORE_ENDPOINT + fileUri;
  }

  async getPresignedUploadURI() {
    return axios
      .get(config.API.PRESIGNED_UPLOAD_ENDPOINT)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('Common.File.uploadError'), e);
        return null;
      });
  }

  readFileAsync(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
}
export default new FileService();
export const FileServiceClass = FileService;
