import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { cloneDeep, get } from 'lodash';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import { getSuggestionId, mapFullTextSugestions } from '@/case-detail/search/services/suggestions.helper';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentApiService from '@/case-detail/subviews/document/services/document.api.service';
import { processSearchResultsPerDocument } from '@/case-detail/subviews/document/services/document.helper';
import documentService, { Document, DocumentDiagnosis } from '@/case-detail/subviews/document/services/document.service';
import { DocumentFilterKey } from '@/case-detail/subviews/documents-list/services/filter/document.filters';
import { Label } from '@/case-detail/subviews/labels/services/label.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { formatToLocale } from '@/common/services/date.utils';
import { SearchFieldKey } from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export interface SearchSuggestion {
  id: string;
  suggestion: string;
  field: SearchFieldKey;
  documentIds?: UUID[];
  value?: string | DocumentDiagnosis; // for issue date (string) and diagnosis
  icon?: string;
  iconColor?: string;
  suggestionSubtitle?: string;
}

export interface DocumentSearchResult {
  count: number;
  pages: API.Document.SourceFileSearchResult[];
}

type Filters = Partial<Record<DocumentFilterKey, any>>;

type SearchResultsPerDocument = Record<UUID, DocumentSearchResult>;

interface ServiceState {
  searchTerm: string;
  searchResultsPerDocument: SearchResultsPerDocument;
  pendingSuggestionRequests: Set<CancelTokenSource>;
  filters: Filters;
}

const initialState: ServiceState = {
  searchTerm: '',
  searchResultsPerDocument: {},
  pendingSuggestionRequests: new Set(),
  filters: {},
};

class SearchService {
  state: ServiceState;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  // search term

  currentSearchTerm() {
    return this.state.searchTerm;
  }

  setFilters(data: { filters: Filters; searchResultsPerDocument: SearchResultsPerDocument }) {
    this.state.filters = data.filters;
    this.state.searchResultsPerDocument = data.searchResultsPerDocument;
  }

  async setSearchTerm(searchTerm: string) {
    let searchResultsPerDocument: SearchResultsPerDocument = {};

    // perform search request if
    if (searchTerm.trim().length !== 0) {
      detailViewService.openPanel('DocumentList');
      documentService.setProcessing(true);

      // if setting any filter, we assume it could need a badge
      if (documentService.getListView() === 'MINIMAL') {
        documentService.setListView('CARD');
      }

      const caseId = detailViewService.getCurrentLegalCaseId();
      const searchResults = await documentApiService.getDocuments(caseId, { searchTerm });

      searchResultsPerDocument = processSearchResultsPerDocument(searchResults, documentService.getDocumentsCache()) as SearchResultsPerDocument;
    }

    this.state.searchTerm = searchTerm;
    this.state.searchResultsPerDocument = searchResultsPerDocument;

    documentService.updateFilteredDocuments();
    documentService.setProcessing(false);
  }

  isDefaultSearchTerm() {
    return this.state.searchTerm.trim().length === 0;
  }

  documentIdsFromSearchResults() {
    return Object.keys(this.state.searchResultsPerDocument);
  }

  getSearchResultsPerDocument() {
    return this.state.searchResultsPerDocument;
  }

  // Clear methods

  clearPendingSuggestionRequests() {
    for (const pendingSuggestionRequest of this.state.pendingSuggestionRequests) {
      pendingSuggestionRequest.cancel();
    }
    this.state.pendingSuggestionRequests = new Set();
  }

  clear() {
    this.setSearchTerm('');
    this.clearPendingSuggestionRequests();
    broadcastEventBus.emit('SEARCH_RESET_EVENT', {});
  }

  // Other

  async getFullTextSuggestions(caseId: UUID, query: string, limit = 0): Promise<SearchSuggestion[]> {
    try {
      // NOTE: CancelToken 👎deprecated
      const source = axios.CancelToken.source();
      this.state.pendingSuggestionRequests.add(source);
      const response = await axios.post(
        config.API.SEARCH_ENDPOINT.SEARCH_SUGGESTIONS,
        {
          searchTerm: query,
          caseId,
        },
        { cancelToken: source.token },
      );
      if (this.state.pendingSuggestionRequests.has(source)) {
        this.state.pendingSuggestionRequests.delete(source);
        return mapFullTextSugestions(response.data, limit);
      }
      return [];
    } catch (e) {
      if (!axios.isCancel(e)) {
        handleError($t('App.Bar.InCaseSearch.suggestionsLoadError'), e);
      }
      return [];
    }
  }

  /* Note(ndv):
   * If speed becomes an issue, this method and its calls could be further optimized to minimize the number of ops, e.g.:
   * - Optimize for query type, e.g. if the query is a String, avoid calling this method for Numeric fields.
   * - Sorting: sorting based on the query type, e.g.: if query is a String, sort the docs by field value alphabetically
   *   (desc or asc depending on the starting letter of the query).
   */
  getFieldSuggestions(
    documents: Document[],
    fieldKey: SearchFieldKey,
    fieldPath: string,
    compareFn: (a: string, b: string) => boolean,
    query: string,
    limit = 0,
  ): SearchSuggestion[] {
    const m: Map<string, SearchSuggestion> = new Map();

    for (const d of documents) {
      let value = get(d, fieldPath);
      const match = compareFn(query, value);

      // HACK(ndv): This is a temporary solution to allow searching in duplicates
      let duplicateMatch = false;
      if (!match) {
        for (const duplicate of d.duplicates) {
          const duplicateValue = get(duplicate, fieldPath);
          if (compareFn(query, duplicateValue)) {
            duplicateMatch = true;
            value = duplicateValue;
            break;
          }
        }
      }

      if (match || duplicateMatch) {
        if (m.has(value)) {
          // Update exiting entry
          const curr = m.get(value)!;
          const updated = {
            ...curr,
            documentIds: [...(curr.documentIds ?? []), d.id],
          };
          m.set(value, updated);
        } else {
          // Add new
          m.set(value, {
            id: getSuggestionId(value, fieldKey),
            suggestion: value,
            documentIds: [d.id],
            field: fieldKey,
          });
        }
        // Note(ndv): Optimize for speed (interrupt asap)
        if (limit > 0 && m.size === limit) {
          break;
        }
      }
    }

    return [...m.values()];
  }

  getIssueDatesSuggestions(documents: Document[], query: string, limit = 0): SearchSuggestion[] {
    // NOTE(ndv): we only allow filtering by issue date when there are at least 2 documents
    if (documents.length < 2) return [];

    const m: Map<string, SearchSuggestion> = new Map();

    for (const d of documents) {
      const dateString = d.metadata.ISSUE_DATE.value;
      const date = dayjs(dateString);
      if (!date.isValid()) continue;

      const formattedDateString = formatToLocale(dateString);
      if (formattedDateString.indexOf(query) !== -1) {
        if (m.has(dateString)) {
          // Update exiting entry
          const curr = m.get(dateString)!;
          const updated = {
            ...curr,
            documentIds: [...(curr.documentIds ?? []), d.id],
          };
          m.set(dateString, updated);
        } else {
          // Add new
          m.set(dateString, {
            id: getSuggestionId(dateString, 'issueDate'),
            suggestion: formattedDateString,
            documentIds: [d.id],
            field: 'issueDate',
            value: dateString,
          });
        }

        // Note(ndv): Optimize for speed (interrupt asap)
        if (limit > 0 && m.size === limit) {
          break;
        }
      }
    }

    return [...m.values()];
  }

  getLabelsSuggestions(legalCaseLabels: Label[], query: string, limit = 0): SearchSuggestion[] {
    const filtered = legalCaseLabels
      .filter((l) => l.title.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      .map(
        (l): SearchSuggestion => ({
          id: l.id,
          field: 'labels',
          suggestion: l.title,
          icon: l.icon,
          iconColor: l.color,
        }),
      );
    if (limit > 0) {
      const end = limit > 0 ? Math.min(limit, filtered.length) : filtered.length;
      return filtered.slice(0, end);
    }
    return filtered;
  }

  getDiagnosisSuggestions(documents: Document[], query: string, limit = 0): SearchSuggestion[] {
    const m: Map<string, SearchSuggestion> = new Map();

    for (const d of documents) {
      for (const diagnosis of d.diagnoses) {
        if (
          !m.has(diagnosis.icd10Code) &&
          (diagnosis.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 || diagnosis.icd10Code.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        ) {
          m.set(diagnosis.icd10Code, {
            id: diagnosis.icd10Code,
            field: 'diagnosis',
            suggestion: diagnosis.title,
            value: {
              ...diagnosis,
            },
          });
          // Note(ndv): Optimize for speed (interrupt asap)
          if (limit > 0 && m.size === limit) {
            return [...m.values()];
          }
        }
      }
    }

    return [...m.values()];
  }
}

export default new SearchService();
export const SearchServiceClass = SearchService;
