import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';

export const pipelineOrchestratorService = {
  /**
   * triggers a supervisor run
   * @returns undefined
   */
  async runSupervisor() {
    return axios
      .get(config.API.PIPELINE.RUN_SUPERVISOR)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseList.supervisorStartError'), e);
      });
  },

  async requestTask(sourceFileId, status, forced) {
    return axios.post(`${config.API.PIPELINE.REQUEST_TASK}/${sourceFileId}/${status}/${forced}`).catch((e) => {
      handleError($t('CaseList.taskUpdateError'), e);
    });
  },

  async requestBulkTask(sourceFileIds, status, forced) {
    const bulkRequest = {
      sourceFileIds,
      requestedStatus: status,
    };
    return axios.post(`${config.API.PIPELINE.REQUEST_BULK_TASK}/${forced}`, bulkRequest).catch((e) => {
      handleError($t('CaseList.taskUpdateError'), e);
    });
  },

  async requestTaskRestart(legalCaseId = '') {
    return axios
      .post(`${config.API.PIPELINE.REQUEST_RESTART_TASK}${legalCaseId ? `?legalCaseId=${legalCaseId}` : ''}`)
      .catch((e) => handleError($t('CaseList.taskUpdateError'), e));
  },

  async cancelTask(sourceFileId) {
    return axios.post(`${config.API.PIPELINE.CANCEL_TASK}/${sourceFileId}`).catch((e) => {
      handleError($t('CaseList.taskCancelError'), e);
    });
  },

  async reviewTaskFinished(legalCaseId) {
    return axios.post(`${config.API.PIPELINE.REVIEW_TASK_FINISHED}/${legalCaseId}`).catch((e) => {
      handleError($t('CaseList.taskUpdateError'), e);
    });
  },

  async requestTasksOnTenant(newStatus, oldStatus) {
    return axios.post(`${config.API.PIPELINE.REQUEST_TENANT_TASKS}/${oldStatus}/${newStatus}`).catch((e) => {
      handleError($t('CaseList.taskStartError'), e);
    });
  },

  /**
   * fetch audit logs for a specific source field id
   * @param {*} sourceFileId
   * @returns Array with found logs
   */
  async getAuditLog(sourceFileId) {
    return axios
      .get(config.API.PIPELINE.AUDIT.replace('{sourceFileId}', sourceFileId))
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseList.auditLogLoadError'), e);
      });
  },
};
