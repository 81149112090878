import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';

const DocumentApiService = {
  async getDocuments(caseId, filters) {
    const localFilters = {
      ...filters,
      caseId,
    };

    return axios
      .post(config.API.SEARCH_ENDPOINT.SEARCH_DOCUMENTS, localFilters)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Document.documentsLoadError'), e, true);
        appService.appError('DOCUMENT_LOAD_ERROR');
        return {
          documents: [],
          count: 0,
          searchResults: {},
        };
      });
  },

  async getAllDocuments(legalCaseId) {
    try {
      const response = await axios.get(config.API.DOCUMENTS_ENDPOINT.replace('{legalCaseId}', legalCaseId), {
        headers: { 'Content-Type': 'text/plain' },
      });
      return response?.data?.length
        ? response?.data
        : (() => {
            throw new Error('DOCUMENT_LIST_EMPTY');
          })();
    } catch (e) {
      handleError($t('CaseDetail.Document.documentsLoadError'), e, true);
      appService.appError('DOCUMENT_LOAD_ERROR');
      return [];
    }
  },

  async updateStatus(legalCaseId, documentId, status) {
    return axios
      .patch(config.API.DOCUMENTS_ENDPOINT_STATUS.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId), { status })
      .catch((e) => {
        handleError($t('CaseDetail.Document.changesSaveError'), e);
        return null;
      });
  },

  /** * METADATA  ** */
  async updateDocumentMetadata(legalCaseId, documentId, meta) {
    return axios.post(config.API.DOCUMENTS_METADATA.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId), meta).catch((e) => {
      handleError($t('CaseDetail.Document.changesSaveError'), e);
      return null;
    });
  },

  async updateDocumentMetadataBulk(legalCaseId, bulkMetaData) {
    return axios.patch(config.API.DOCUMENTS_METADATA_BULK.replace('{legalCaseId}', legalCaseId), { bulkMetaData }).catch((e) => {
      handleError($t('CaseDetail.Document.changesSaveError'), e);
      return null;
    });
  },

  async getMetadataDetails(legalCaseId, documentId) {
    return axios
      .get(config.API.DOCUMENTS_METADATA_DETAILS.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId))
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Document.documentDataLoadError'), e);
        return null;
      });
  },

  async updateMetadataDetails(legalCaseId, documentId, key, serializedDetails) {
    return axios
      .post(config.API.DOCUMENTS_METADATA_DETAILS.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId), {
        key,
        serializedDetails,
      })
      .catch((e) => {
        handleError($t('CaseDetail.Document.changesSaveError'), e);
        return null;
      });
  },

  async changeRotation(legalCaseId, documentId, rotation) {
    await this.updateDocumentMetadata(legalCaseId, documentId, { PDF_ROTATION: rotation }, true);
  },

  /** * DUPLICATES ** */
  async updateDuplicatesOriginalDocument(legalCaseId, { formerOriginalDocumentId, newOriginalDocumentId }) {
    return axios
      .patch(config.API.DUPLICATES_ENDPOINT.BASE.replace('{legalCaseId}', legalCaseId), {
        formerOriginalDocumentId,
        newOriginalDocumentId,
      })
      .catch((e) => {
        handleError($t('CaseDetail.Document.duplicateChangeError'), e);
        return null;
      });
  },

  async removeDocumentDuplicate(legalCaseId, { originalDocumentId, duplicateDocumentId }) {
    return axios
      .delete(
        config.API.DUPLICATES_ENDPOINT.REMOVE.replace('{legalCaseId}', legalCaseId)
          .replace('{originalDocumentId}', originalDocumentId)
          .replace('{duplicateDocumentId}', duplicateDocumentId),
      )
      .catch((e) => {
        handleError($t('CaseDetail.Document.duplicateDeleteError'), e);
        return null;
      });
  },

  async addDocumentDuplicate(legalCaseId, { originalDocumentId, duplicateDocumentId }) {
    return axios
      .put(config.API.DUPLICATES_ENDPOINT.BASE.replace('{legalCaseId}', legalCaseId), {
        originalDocumentId,
        duplicateDocumentId,
      })
      .catch((e) => {
        handleError($t('CaseDetail.Document.duplicateDeleteError'), e);
        return null;
      });
  },

  async updateBulkDocumentDuplicates(legalCaseId, bulkRequest) {
    return axios.post(config.API.DUPLICATES_ENDPOINT.BASE.replace('{legalCaseId}', legalCaseId), bulkRequest).catch((e) => {
      handleError($t('CaseDetail.Document.duplicatesChangeError'), e);
      return null;
    });
  },
};

export default DocumentApiService;
