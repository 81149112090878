import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { Document } from '@/case-detail/subviews/document/services/document.service';
import { UUID } from '@/common/types/common.types';

interface ServiceState {
  originalDocument: null | Document;
  duplicateCandidates: Document[];
  // view
  mode: number;
  selectedId: null | UUID;
}

class DuplicateReviewService {
  state: ServiceState;

  constructor() {
    this.state = reactive({
      originalDocument: null,
      duplicateCandidates: [],
      // view
      mode: 0,
      selectedId: null,
    });
  }

  setOriginalDocument(originalDocument: Document) {
    this.state.originalDocument = originalDocument;
  }

  removeDuplicate(duplicateId: UUID) {
    if (!this.state.originalDocument) {
      return;
    }
    this.state.originalDocument.duplicates = this.state.originalDocument.duplicates.filter((d) => d.id !== duplicateId);
  }

  async fetchDuplicateCandidates(originalDocumentId: UUID) {
    return axios
      .get(
        config.API.DUPLICATES_ENDPOINT.CANDIDATES.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()).replace(
          '{originalDocumentId}',
          originalDocumentId,
        ),
      )
      .then((response) => {
        this.state.duplicateCandidates = response.data;
      })
      .catch((e) => {
        handleError($t('CaseDetail.Duplicates.duplicatesLoadError'), e);
      });
  }

  async downloadDuplicates(originalDocumentId: UUID, filename: string, exportType: string) {
    return axios
      .get(
        `${config.API.DUPLICATES_ENDPOINT.CANDIDATES}/zip?exportType=${exportType}`
          .replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId())
          .replace('{originalDocumentId}', originalDocumentId),
        {
          responseType: 'arraybuffer',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => {
        handleError($t('Common.File.loadError'), e);
        return null;
      });
  }

  setDuplicateCandidates(duplicateCandidates: Document[]) {
    this.state.duplicateCandidates = duplicateCandidates;
  }

  destroy() {
    this.state.originalDocument = null;
    this.state.duplicateCandidates = [];
    this.state.mode = 0;
    this.state.selectedId = null;
  }
}

export default new DuplicateReviewService();
export const DuplicatesReviewServiceClass = DuplicateReviewService;
