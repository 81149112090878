import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import documentApiService from '@/case-detail/subviews/document/services/document.api.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import { sourceFileClient } from '@/common/clients/sourcefile.client';
import { folderService } from '@/common/services/folder.service';
import { UUID } from '@/common/types/common.types';

export const OTHER_FOLDER_KEY = 'unknown';

export async function updateDocumentsFolder(legalCaseId: UUID, documentIds: UUID[], folder: string) {
  const bulkMetaData = documentIds.reduce(
    (acc, docId) => {
      acc[docId] = [{ FOLDER: folder }];
      return acc;
    },
    {} as Record<UUID, [{ FOLDER: string }]>,
  );

  await documentApiService.updateDocumentMetadataBulk(legalCaseId, bulkMetaData);
}

export async function confirmDocumentFolder(documentId: UUID, folder: string, legalCaseId: UUID) {
  const sourceFileId = documentService.getDocumentSourceFileId(documentId)!;
  const documentsInSf = documentService.getDocumentsInSameSourceFile(documentId);
  const documentIds = documentsInSf.map((doc) => doc.id);
  const sourceFile = await sourceFileClient.get(legalCaseId, sourceFileId);

  appService.confirm(
    $t('CaseDetail.DocumentsList.DocumentFolders.confirmTitle'),
    $t('CaseDetail.DocumentsList.DocumentFolders.confirmMessage', {
      count: documentsInSf.length,
      sourcefile: sourceFile.uploadFilename,
      folder: folderService.getFolderText(folder),
    }),
    $t('CaseDetail.DocumentsList.DocumentFolders.optionTwoLabel'),
    async () => {
      await updateDocumentsFolder(legalCaseId, documentIds, folder);
      await documentService.load(legalCaseId);
    },
    'primary',
    '',
    () => {},
    $t('CaseDetail.DocumentsList.DocumentFolders.optionOneLabel'),
    async () => {
      await documentApiService.updateDocumentMetadata(legalCaseId, documentId, { FOLDER: folder });
      await documentService.load(legalCaseId);
    },
    'primary',
  );
}
