/* eslint-disable no-console  */

import { config } from '@/app/config';

function log(message, level = 'info') {
  if (config.LOGGING) {
    // development
    switch (level) {
      case 'info':
        console.log(message);
        break;
      case 'warn':
        console.warn(message);
        break;
      case 'error':
        console.error(message);
        break;
      default:
        break;
    }
  } else {
    // production env
    switch (level) {
      case 'info':
        // skipping
        break;
      case 'warn':
        // skipping
        break;
      case 'error':
        console.error(message);
        break;
      default:
        break;
    }
  }
}

export default {
  info(message) {
    log(message);
  },
  warn(message) {
    log(message, 'warn');
  },
  error(message) {
    log(message, 'error');
  },
};
