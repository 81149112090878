import { reactive } from 'vue';

import router from '@/app/router';
import { PanelId, PanelMetadata, panels, SlotId } from '@/case-detail/services/detail.view.panels.meta';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import detachedWindowService from '@/common/services/detached-window.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export interface CurrentLegalCase extends API.LegalCase.Response {}

class DetailViewService {
  state: {
    panels: Record<PanelId, boolean>;
    currentLegalCase: CurrentLegalCase | null;
  };

  panels: Record<PanelId, PanelMetadata>;

  constructor() {
    const panelsState = Object.fromEntries(Object.keys(panels).map((panelId) => [panelId, false]));
    this.state = reactive({
      panels: panelsState as Record<PanelId, boolean>,
      currentLegalCase: null,
    });

    this.panels = panels;
  }

  // CURRENT LEGAL CASE

  getCurrentLegalCaseId() {
    // NOTE(dp): `undefined` could be returned as well
    // one example of this is going to big case and quickly navigating back to the list - init load functions will still run and call this method
    // for simplicity `string` type is left to not add stupid checks everywhere
    // BUT (!) if you use this method in init-load-type methods — adding manual check for undefined is good idea
    return this.state.currentLegalCase?.id ?? (router.currentRoute.value.params.caseId as string);
  }

  getCurrentLegalCase() {
    return this.state.currentLegalCase;
  }

  async setCurrentLegalCase(caseId: UUID) {
    if (this.state.currentLegalCase && caseId === this.state.currentLegalCase.id) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(caseId);
  }

  async refreshCurrentLegalCase() {
    if (!this.state.currentLegalCase) return;
    this.state.currentLegalCase = await legalCaseAPIClient.fetch(this.state.currentLegalCase.id);
  }

  async updateCurrentLegalCase(newData: API.LegalCase.CreateUpdateRequest) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.update(this.state.currentLegalCase.id, newData);
    this.refreshCurrentLegalCase();
  }

  async toggleCurrentLegalCaseArchive() {
    if (!this.state.currentLegalCase) return;
    const legalCase = this.state.currentLegalCase;
    const newStatus = legalCase.legalCaseStatus === 'OPEN' ? 'ARCHIVED' : 'OPEN';
    await legalCaseAPIClient.updateStatus(legalCase.id, newStatus);
    this.refreshCurrentLegalCase();
  }

  async updateCurrentLegalCaseMetadata(key: API.LegalCase.PiiKey, value: string) {
    if (!this.state.currentLegalCase) return;
    await legalCaseAPIClient.updateMetadata(this.state.currentLegalCase.id, key, value);
    this.refreshCurrentLegalCase();
  }

  // PANELS

  getPanelsState() {
    return this.state;
  }

  getOpenPanels(): PanelId[] {
    return Object.entries(this.state.panels)
      .filter(([, v]) => v)
      .map(([k]) => k) as PanelId[];
  }

  togglePanel(panelId: PanelId) {
    if (this.state.panels[panelId]) {
      this.closePanel(panelId);
    } else {
      this.openPanel(panelId);
    }
  }

  isPanelEnabled(panelId: PanelId) {
    return this.panels[panelId].enabled(authService);
  }

  isPanelOpened(panelId: PanelId) {
    return this.state.panels[panelId];
  }

  openPanel(panelId: PanelId) {
    if (this.state.panels[panelId]) {
      return;
    }

    const panel = panels[panelId];

    if (panel?.detachable) {
      const windowName = panelId.toLowerCase();
      const window = detachedWindowService.getWindowReference(windowName);
      if (window) {
        window.focus();
        return;
      }
    }

    if (panelId === 'CasePilot') {
      this.closeAllPanels();
    } else if (panelId !== 'DocumentList' && panelId !== 'WebViewer' && this.isPanelOpened('CasePilot')) {
      this.initDefaultArrangement();
    } else {
      this.closeSlot(panel.slot);
    }

    this.state.panels[panelId] = true;
    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
    $a.l(`CASE_PANEL_OPEN_${panelId.toUpperCase()}`);
  }

  closePanel(panelId: PanelId) {
    if (!this.state.panels[panelId]) {
      return;
    }

    this.state.panels[panelId] = false;

    if (panelId === 'CasePilot') {
      this.initDefaultArrangement();
    } else if (panelId === 'WebViewer' && !this.isPanelOpened('CasePilot') && this.isPanelEnabled('CasePilot')) {
      this.openPanel('CasePilot');
    } else if (panelId === 'DuplicatesReview') {
      this.openPanel('WebViewer');
    }

    detailViewRouteService.persistInQuery({ openPanels: this.getOpenPanels() });
    $a.l(`CASE_PANEL_CLOSE_${panelId.toUpperCase()}`);
  }

  closeSlot(slotId: SlotId) {
    const items = Object.entries(this.panels).filter(
      ([, panel]) => panel.slot === slotId || (Array.isArray(panel.slot) && panel.slot.includes(slotId)),
    ) as [PanelId, PanelMetadata][];
    for (const [panelId] of items) {
      if (this.state.panels[panelId]) {
        this.state.panels[panelId] = false;
        $a.l(`CASE_PANEL_CLOSE_${panelId.toUpperCase()}`);
      }
    }
  }

  closeAllPanels() {
    for (const panelId of Object.keys(this.state.panels)) {
      this.state.panels[panelId as PanelId] = false;
    }
  }

  initArrangement() {
    const openPanels = detailViewRouteService.getFromQuery('openPanels');
    if (openPanels) {
      for (const panelId of Object.keys(this.state.panels)) {
        this.state.panels[panelId as PanelId] = openPanels.includes(panelId as PanelId);
      }
    } else {
      this.initDefaultArrangement();
    }

    // check for internal params like `ticketId`
    if (detailViewRouteService.getFromQuery('ticketId')) {
      this.openPanel('Collaboration');
    }
  }

  initDefaultArrangement() {
    for (const panelId of Object.keys(this.state.panels)) {
      this.state.panels[panelId as PanelId] = panels[panelId as PanelId].initialState;
    }
  }

  clear() {
    this.state.currentLegalCase = null;
    this.initArrangement();
  }
}

export default new DetailViewService();
