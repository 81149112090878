import { reactive } from 'vue';

import { $t } from '@/app/i18n/i18n.service';
import icd10Service from '@/case-detail/subviews/diagnosis/services/icd10.service';
import pdftronAnnotationService from '@/case-detail/subviews/document/annotations/services/pdftron.annotation.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import viewerService from '@/case-detail/subviews/document/services/viewer.service';

class ExtractAnnotationService {
  constructor() {
    this.DATA_ELEMENTS = {
      ADD_DIAGNOSIS_BUTTON: 'addDiagnosisButton',
      DELETE_DIAGNOSIS_BUTTON: 'deleteDiagnosisButton',
      DIAGNOSIS_INFO_BUTTON: 'diagnosisInfoButton',
    };

    this.state = reactive({
      // diagnosis dialog
      showDiagnosisDialog: false,
      selectedDiagnosis: null,
    });
  }

  /** DIAGNOSIS DIALOG */

  // NOTE(ndv): diagnosis := DocumentDiagnosis (see API)
  showDiagnosisDialog(diagnosis) {
    if (diagnosis) {
      this.state.selectedDiagnosis = diagnosis;
    }
    this.state.showDiagnosisDialog = true;
  }

  hideDiagnosisDialog() {
    this.state.showDiagnosisDialog = false;
    this.state.selectedDiagnosis = null;
  }

  /** HELPERS */

  mapDiagnosisRenderAnnotations(diagnoses) {
    return diagnoses.map((diagnosis) => {
      const codeParts = diagnosis.icd10Code.split('.');

      if (codeParts.length === 1 || codeParts[1].length === 1) {
        return {
          ...diagnosis.renderAnnotation,
          contents: `${diagnosis.icd10Code} ${icd10Service.getDiagnosisTitle(diagnosis.icd10Code)}`,
        };
      }

      // handle specifications
      // e.g. S42.21 -> S42
      let contents = '';
      const baseCode = codeParts[0];
      // e.g. S42.21 -> 21
      const codeSpecification = codeParts[1];
      // e.g. S42.21 -> S42.2
      const firstLevelCode = `${baseCode}.${codeSpecification[0]}`;
      const firstLevelTitle = icd10Service.getDiagnosisTitle(firstLevelCode);
      contents += `${firstLevelCode} ${firstLevelTitle}`;

      // e.g. S42.21 -> S42.21 (actual code)
      const secondLevelCode = diagnosis.icd10Code;
      const secondLevelTitle = icd10Service.getDiagnosisTitle(secondLevelCode);
      contents += `\n - ${secondLevelCode} ${secondLevelTitle}`;

      return {
        ...diagnosis.renderAnnotation,
        contents,
      };
    });
  }

  /** UI */

  getButtonDataElements() {
    return [...Object.values(this.DATA_ELEMENTS)];
  }

  getAddDiagnosisButton() {
    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24" xml:space="preserve"> <defs> </defs> <g transform="matrix(0.9 0 0 0.9 14 11)" id="pKzr4rGSPS_455W8VTPC9"  > <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-12, -12)" d="M 19 8 C 19.56 8 20 8.43 20 9 C 20 9.552284749830793 19.552284749830793 10 19 10 C 18.43 10 18 9.55 18 9 C 18 8.43 18.43 8 19 8 M 2 2 L 2 11 C 2 13.96 4.19 16.5 7.14 16.91 C 7.76 19.92 10.42 22 13.5 22 C 17.089850873900158 22 20 19.089850873900158 20 15.5 L 20 11.81 C 21.16 11.39 22 10.29 22 9 C 22 7.343145750507619 20.65685424949238 6 19 6 C 17.34314575050762 6 16 7.343145750507619 16 9 C 16 10.29 16.84 11.4 18 11.81 L 18 15.41 C 18 17.91 16 19.91 13.5 19.91 C 11.5 19.91 9.82 18.7 9.22 16.9 C 12 16.3 14 13.8 14 11 L 14 2 L 10 2 L 10 5 L 12 5 L 12 11 C 12 13.209138999323173 10.209138999323173 15 8 15 C 5.790861000676826 15 4 13.209138999323173 4 11 L 4 5 L 6 5 L 6 2 L 2 2 Z" stroke-linecap="round" /> </g> <g transform="matrix(0.57 0 0 0.57 5 18)" id="hPDQzRde86P9wGER5ZglZ"  > <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-12, -12)" d="M 19 13 L 13 13 L 13 19 L 11 19 L 11 13 L 5 13 L 5 11 L 11 11 L 11 5 L 13 5 L 13 11 L 19 11 L 19 13 Z" stroke-linecap="round" /> </g> </svg>',
      onClick: () => this.showDiagnosisDialog(),
      title: $t('CaseDetail.Document.addDiagnosis'),
      dataElement: this.DATA_ELEMENTS.ADD_DIAGNOSIS_BUTTON,
    };
  }

  getDiagnosisDialogPopupButton() {
    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <title>information-outline</title> <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" /> </svg>',
      onClick: async () => {
        const diagnosisId = pdftronAnnotationService.getSelectedAnnotationId();
        const diagnosis = viewerService.getDocument().diagnoses.find((d) => d.id === diagnosisId);
        this.showDiagnosisDialog(diagnosis);
      },
      title: $t('Common.learnMore'),
      dataElement: this.DATA_ELEMENTS.DIAGNOSIS_INFO_BUTTON,
    };
  }

  getDeleteDiagnosisPopupButton() {
    return {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color="#B3251E"><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>',
      onClick: async () => {
        const diagnosisId = pdftronAnnotationService.getSelectedAnnotationId();
        documentService.deleteDiagnosisFromDocument({ diagnosisId });
      },
      title: $t('CaseDetail.Document.clearDiagnosis'),
      dataElement: this.DATA_ELEMENTS.DELETE_DIAGNOSIS_BUTTON,
    };
  }

  handleAnnotationsSelected(annotations, action) {
    if (action !== 'selected' || !annotations || annotations?.length !== 1) return;

    pdftronAnnotationService.hideElements([
      ...this.getButtonDataElements().filter((dataElement) => dataElement !== this.DATA_ELEMENTS.ADD_DIAGNOSIS_BUTTON),
    ]);
    const annotationKey = pdftronAnnotationService.getAnnotationKey(annotations[0]);
    if (!annotationKey.startsWith('MEDICAL_ICD10')) return;

    pdftronAnnotationService.showElements([this.DATA_ELEMENTS.DIAGNOSIS_INFO_BUTTON, this.DATA_ELEMENTS.DELETE_DIAGNOSIS_BUTTON]);
  }
}

export default new ExtractAnnotationService();
