import { Core } from '@pdftron/webviewer';
import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { docPilotService } from '@/case-detail/subviews/copilot/services/copilot.service';
import { AnnotationMapping } from '@/case-detail/subviews/document/annotations/services/annotation.types';
import pdftronAnnotationService from '@/case-detail/subviews/document/annotations/services/pdftron.annotation.service';
import { getAnnotationIcon } from '@/case-detail/subviews/document/annotations/services/user.annotation.helper';
import documentService from '@/case-detail/subviews/document/services/document.service';
import { pdftronUiHelper } from '@/case-detail/subviews/document/services/pdftron-ui.helper';
import viewerService from '@/case-detail/subviews/document/services/viewer.service';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import entityService from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';

type AnnotationsServiceState = {
  annotationMappings: AnnotationMapping[];
  // comments
  showComments: boolean;
  showCommentsMini: boolean;
  selectedCommentId: null | string;
};

class UserAnnotationService {
  NON_UPDATABLE_ANNOTATION_KEYS = ['USER_STRIKEOUT', 'USER_COMMENT'];

  NON_TEXT_SELECT_ANNOTATION_KEYS = ['USER_COMMENT_FREE'];

  TEMPORARY_COMMENT_ID = 'PLACEHOLDER_COMMENT';

  DATA_ELEMENTS = {
    DELETE_ANNOTATION_BUTTON: 'deleteUserAnnotationButton',
    COPILOT_EXPLAIN_BUTTON: 'copilotExplainAnnotationButton',
  };

  state: AnnotationsServiceState;

  constructor() {
    this.state = reactive({
      annotationMappings: [],
      // comments
      showComments: true,
      showCommentsMini: true,
      selectedCommentId: null,
    });
  }

  async init() {
    // load annnotation config mappings and define data elements + add icons

    const customAnnotationMappings: AnnotationMapping[] = [
      ...Object.values(entityService.DOCLIST_DEFAULT_ANNOTATION_MAPPINGS).map((m) => ({ ...m, annotationKey: m.key })),
      ...(authService.state.data?.tenant.tenantConfig.annotationConfig.mappings ?? []),
    ].map((m) => ({
      ...m,
      icon: getAnnotationIcon(m.annotationKey),
      iconSvg: getAnnotationIcon(m.annotationKey, true).replaceAll('{color}', m.color),
      addDataElement: `addUserAnnotationButton__${m.annotationKey}`,
      editDataElement: `editUserAnnotationButton__${m.annotationKey}`,
    }));

    this.state.annotationMappings.push(...customAnnotationMappings);
  }

  /** COMMENTS window */
  setSelectedCommentId(id: string | null) {
    this.state.selectedCommentId = id;
  }

  toggleShowComments() {
    this.state.showComments = !this.state.showComments;
  }

  setShowCommentsMini(value: boolean) {
    this.state.showCommentsMini = value;
    if (value) {
      this.removeTemporaryComment();
    }
  }

  addTemporaryComment(annotationKey: string) {
    // show comments if hidden
    if (!this.state.showComments) {
      this.toggleShowComments();
    }
    if (this.state.showCommentsMini) {
      this.setShowCommentsMini(false);
    }

    // verify if a temporary comment already exists
    // @ts-expect-error incompatibility with JS
    const { userAnnotations }: { userAnnotations: API.Document.Annotation[] } = viewerService.getDocument();
    const temporaryComment = userAnnotations.find((a) => a.id === this.TEMPORARY_COMMENT_ID);
    if (temporaryComment) {
      this.setSelectedCommentId(this.TEMPORARY_COMMENT_ID);
      return;
    }

    // add new temporary comment
    userAnnotations.push({
      id: this.TEMPORARY_COMMENT_ID,
      annotationKey,
      // @ts-expect-error here we violate types a bit to add temporary comment
      renderAnnotation: {
        page: pdftronAnnotationService.getCurrentPage() - 1,
      },
    });
    this.setSelectedCommentId(this.TEMPORARY_COMMENT_ID);
  }

  removeTemporaryComment() {
    // @ts-expect-error incompatibility with JS
    const { userAnnotations }: { userAnnotations: API.Document.Annotation[] } = viewerService.getDocument();
    const filtered = userAnnotations.filter((a) => a.id !== this.TEMPORARY_COMMENT_ID);
    if (filtered.length === userAnnotations.length) return;

    viewerService.setUserAnnotations(filtered);

    if (this.state.selectedCommentId === this.TEMPORARY_COMMENT_ID) {
      this.setSelectedCommentId(null);
    }
  }

  /** HELPERS */

  getAnnotationMapping(annotationKey: string) {
    return this.state.annotationMappings.find((mapping) => mapping.annotationKey === annotationKey);
  }

  sortByRect(a: API.Document.Annotation, b: API.Document.Annotation) {
    if (a.renderAnnotation.page !== b.renderAnnotation.page) {
      return a.renderAnnotation.page - b.renderAnnotation.page;
    }

    if (!a.renderAnnotation.rect || !b.renderAnnotation.rect) {
      return 0;
    }

    const aCoords = a.renderAnnotation.rect.split(',');
    const bCoords = b.renderAnnotation.rect.split(',');

    const aX = parseInt(aCoords[0], 10);
    const aY = parseInt(aCoords[1], 10);
    const bX = parseInt(bCoords[0], 10);
    const bY = parseInt(bCoords[1], 10);

    if (aY !== bY) {
      return bY - aY;
    }

    return aX - bX;
  }

  /** CRUD */

  async create(annotation: API.Annotation.CreateRequest) {
    if (!detailViewService.getCurrentLegalCase()) return false;
    const url = config.API.ANNOTATION_ENDPOINT.ROOT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId());
    return axios
      .put(url, annotation)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Document.commentsLoadError'), e);
        return [];
      });
  }

  async update(annotationId: string, annotationKey: string, wrappedXfdf: string) {
    if (!detailViewService.getCurrentLegalCase()) return false;
    const url = `${config.API.ANNOTATION_ENDPOINT.ROOT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId())}/${annotationId}`;
    return axios
      .patch(url, {
        annotationKey,
        wrappedXfdf,
      })
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Document.commentsUpdateError'), e);
        return [];
      });
  }

  async delete(annotationId: string) {
    if (!detailViewService.getCurrentLegalCase()) return false;
    const url = `${config.API.ANNOTATION_ENDPOINT.ROOT.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId())}/${annotationId}`;
    return axios
      .delete(url)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Document.commentsDeleteError'), e);
        return [];
      });
  }

  async handleAnnotationChanged(
    annotations: Core.Annotations.Annotation[],
    action: keyof typeof Core.AnnotationManager.AnnotationChangedActions,
    info: Core.AnnotationManager.AnnotationChangedInfoObject,
  ) {
    /* NOTE(ndv): see documentation:
     * https://docs.apryse.com/api/web/Core.AnnotationManager.html#.AnnotationChangedInfoObject
     * https://docs.apryse.com/api/web/Core.AnnotationManager.html#.AnnotationChangedSources
     */

    if (annotations.length !== 1) {
      return;
    }

    if (info.source !== 'resize' && !(info.source === 'move' && pdftronAnnotationService.getAnnotationKey(annotations[0]) === 'USER_COMMENT_FREE')) {
      return;
    }

    const annotation = annotations[0];
    const annotationId = pdftronAnnotationService.getAnnotationId(annotation)!;
    const xfdf = (await pdftronAnnotationService.getAnnotationXfdf(annotation))!;

    documentService.updateUserAnnotation({ documentId: viewerService.getDocumentId(), annotationId, xfdf });
  }

  /** UI */

  getButtons(update = false) {
    const deleteUserAnnotationButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color="#B3251E"><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>',
      onClick: async () => {
        const documentId = viewerService.getDocumentId();
        const annotationId = pdftronAnnotationService.getSelectedAnnotationId()!;

        documentService.deleteUserAnnotationFromDocument({ documentId, annotationId });
        $a.l($a.e.DOC_ANNOTATION_DELETE);
      },
      title: $t('Common.delete'),
      dataElement: this.DATA_ELEMENTS.DELETE_ANNOTATION_BUTTON,
    };

    const copilotExplainButton = {
      type: 'actionButton',
      img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 8H20V9H19V8M20 5H19V7H20V6H20.5C20.78 6 21 5.78 21 5.5V3.5C21 3.22 20.78 3 20.5 3H18V4H20V5M17 3H16V7H17V3M13.5 15.5C13.5 16.61 14.4 17.5 15.5 17.5S17.5 16.61 17.5 15.5 16.61 13.5 15.5 13.5 13.5 14.4 13.5 15.5M17 8H16V9H17V8M22 14H21C21 12.5 20.53 11.13 19.74 10H16.97C18.19 10.91 19 12.36 19 14V16H21V17H19V20H5V17H3V16H5V14C5 11.24 7.24 9 10 9H14C14.34 9 14.68 9.04 15 9.1V7.08C14.67 7.03 14.34 7 14 7H13V5.73C13.6 5.39 14 4.74 14 4C14 2.9 13.11 2 12 2S10 2.9 10 4C10 4.74 10.4 5.39 11 5.73V7H10C6.13 7 3 10.13 3 14H2C1.45 14 1 14.45 1 15V18C1 18.55 1.45 19 2 19H3V20C3 21.11 3.9 22 5 22H19C20.11 22 21 21.11 21 20V19H22C22.55 19 23 18.55 23 18V15C23 14.45 22.55 14 22 14M8.5 13.5C7.4 13.5 6.5 14.4 6.5 15.5S7.4 17.5 8.5 17.5 10.5 16.61 10.5 15.5 9.61 13.5 8.5 13.5Z" /></svg>',
      onClick: async () => {
        const pdftronAnnotation = pdftronAnnotationService.getSelectedAnnotation();
        const annotationKey = pdftronAnnotation ? pdftronAnnotationService.getAnnotationKey(pdftronAnnotation) : null;
        if (pdftronAnnotation && annotationKey?.startsWith('MEDICAL_ICD10')) {
          const text = pdftronAnnotation.getContents();
          if (!text) return;
          docPilotService.explainDiagnosis(text);
          detailViewService.openPanel('Copilot');
          return;
        }

        const text = await pdftronAnnotationService.getCurrentAnnotationText();
        if (!text) return;

        let context = text;
        if (pdftronUiHelper.docViewer) {
          // @ts-expect-error incompatibility with JS
          context = await pdftronUiHelper.getTextAround(pdftronUiHelper.docViewer.getCurrentPage(), text);
        }
        docPilotService.explain(text, context);
        detailViewService.openPanel('Copilot');
      },
      title: $t('CaseDetail.Document.letCopilotExplainDocument'),
      dataElement: this.DATA_ELEMENTS.COPILOT_EXPLAIN_BUTTON,
    };

    // filter out annotation keys that shouldn't be selected via the select menu
    let mappings = this.state.annotationMappings.filter((mapping) => !this.NON_TEXT_SELECT_ANNOTATION_KEYS.includes(mapping.annotationKey));

    if (update) {
      mappings = mappings.filter((mapping) => !this.NON_UPDATABLE_ANNOTATION_KEYS.includes(mapping.annotationKey));
    }

    const mappedButtons = mappings.map((mapping) => ({
      type: 'actionButton',
      img: mapping.iconSvg,
      onClick: async () => {
        if (mapping.annotationKey === 'USER_COMMENT') {
          $a.l($a.e.DOC_COMMENT);
          this.addTemporaryComment('USER_COMMENT');
          return;
        }

        if (update) {
          $a.l($a.e.DOC_ANNOTATION_EDIT);
          const annotationId = pdftronAnnotationService.getSelectedAnnotationId()!;
          const pdftronAnnotation = pdftronAnnotationService.getSelectedAnnotation()!;
          const xfdf = (await pdftronAnnotationService.getAnnotationXfdf(pdftronAnnotation))!;

          documentService.updateUserAnnotationKey({
            documentId: viewerService.getDocumentId(),
            annotationId,
            annotationKey: mapping.annotationKey,
            color: mapping.color,
            xfdf,
          });
        } else {
          $a.l($a.e.DOC_ANNOTATION_ADD);
          const selection = pdftronAnnotationService.getSelection()!;
          documentService.addUserAnnotationToDocument({
            documentId: viewerService.getDocumentId(),
            annotationKey: mapping.annotationKey,
            pageOneBased: selection.page,
            quads: selection?.quads,
            color: mapping.color,
            opacity: 0.5,
            contents: '',
          });
        }
      },
      title: mapping.text,
      dataElement: update ? mapping.editDataElement : mapping.addDataElement,
    }));

    if (update) {
      return authService.hasFeature('ENABLE_CASE_DETAIL_DOCUMENT_COPILOT')
        ? [...mappedButtons, copilotExplainButton, deleteUserAnnotationButton]
        : [...mappedButtons, deleteUserAnnotationButton];
    }

    return mappedButtons;
  }

  getAddButtonDataElements() {
    const buttonDataElements = [];
    for (const mapping of this.state.annotationMappings) {
      buttonDataElements.push(mapping.addDataElement);
    }
    return buttonDataElements;
  }

  getEditButtonDataElements() {
    const buttonDataElements = [];
    for (const mapping of this.state.annotationMappings) {
      buttonDataElements.push(mapping.editDataElement);
    }
    return buttonDataElements;
  }

  handleAnnotationsSelected(annotations: Core.Annotations.Annotation[], action: 'selected' | 'deselected') {
    // selected
    if (action !== 'selected' || !annotations || annotations?.length !== 1) return;

    pdftronAnnotationService.hideElements([...this.getEditButtonDataElements()]);

    const selectedAnnotation = annotations[0];
    // read-only
    if (selectedAnnotation.ReadOnly) {
      pdftronAnnotationService.hideElements([this.DATA_ELEMENTS.DELETE_ANNOTATION_BUTTON]);
      return;
    }

    // editable
    const annotationKey = pdftronAnnotationService.getAnnotationKey(selectedAnnotation);
    if (!annotationKey) return;

    // show/hide delete user annotation button
    if (annotationKey.startsWith('USER_COMMENT')) {
      // comments have can be deleted via their display dialog
      pdftronAnnotationService.hideElements([this.DATA_ELEMENTS.DELETE_ANNOTATION_BUTTON]);
    } else {
      pdftronAnnotationService.showElements([this.DATA_ELEMENTS.DELETE_ANNOTATION_BUTTON]);
    }

    // USER_HIGHLIGHT_*
    if (annotationKey.startsWith('USER_HIGHLIGHT')) {
      // enable all buttons
      pdftronAnnotationService.showElements([...this.state.annotationMappings.map((mapping) => mapping.editDataElement)]);

      pdftronAnnotationService.hideElements(['deleteDiagnosisButton', 'diagnosisTagsButton']);

      // disable button that represents the annotation's current annotation key (input)
      const buttonToDisable = this.getAnnotationMapping(annotationKey)?.editDataElement;
      if (!buttonToDisable) {
        return;
      }
      pdftronAnnotationService.hideElements([buttonToDisable]);

      return;
    }

    // USER_COMMENT
    if (annotationKey.startsWith('USER_COMMENT')) {
      const annotationId = pdftronAnnotationService.getAnnotationId(selectedAnnotation);
      if (this.state.selectedCommentId === annotationId) return;

      this.setSelectedCommentId(annotationId);
    }
  }

  destroy() {
    this.state.annotationMappings = [];
    this.state.selectedCommentId = null;
    this.state.showComments = true;
    this.state.showCommentsMini = true;
  }
}

export default new UserAnnotationService();
