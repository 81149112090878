<template>
  <v-btn
    v-if="showDatesInHumanReadableFormat"
    v-tippy="overrideTooltip ?? formattedDate"
    size="x-small"
    variant="plain"
    class="pa-0"
    :class="textClass"
    @click="toggle"
  >
    {{ humanReadableDate }}
  </v-btn>
  <v-btn v-else v-tippy="overrideTooltip" size="x-small" variant="plain" class="pa-0" :class="textClass" @click="toggle">
    {{ formattedDate }}
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { formatToHumanDuration, formatToLocale } from '@/common/services/date.utils';
import preferencesService from '@/common/services/preferences.service';

export default defineComponent({
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
    time: {
      type: Boolean,
      default: false,
    },
    textClass: {
      type: String,
      default: 'text-body-2',
    },
    overrideTooltip: {
      type: [Object, String],
      default: null,
    },
    handleClick: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    showDatesInHumanReadableFormat: {
      get() {
        return preferencesService.state.caseDetailViewPreferences.showDatesInHumanReadableFormat;
      },
      set(value: boolean) {
        preferencesService.updatePreferences({ caseDetailViewPreferences: { showDatesInHumanReadableFormat: value } });
      },
    },
    humanReadableDate() {
      return formatToHumanDuration(this.date);
    },
    formattedDate() {
      if (this.time) {
        return formatToLocale(this.date, 'lll');
      }
      return formatToLocale(this.date);
    },
  },
  methods: {
    toggle(event: MouseEvent) {
      if (!this.handleClick) {
        return;
      }
      event.stopPropagation();
      this.showDatesInHumanReadableFormat = !this.showDatesInHumanReadableFormat;
    },
  },
});
</script>

<style scoped></style>
