export function sortTenantsByEnvAndName(a, b) {
  if (a.organization !== b.organization) {
    return a.organization.localeCompare(b.organization);
  }
  if (a.environment !== b.environment) {
    if (a.environment === 'PRODUCTION') return -1;
    if (b.environment === 'PRODUCTION') return 1;
    if (a.environment === 'STAGING') return -1;
    if (b.environment === 'STAGING') return 1;
  }
  return a.name.localeCompare(b.name);
}
