import * as Sentry from '@sentry/vue';

import { config } from '@/app/config';
import appService from '@/app/services/app.service';
import logger from '@/common/services/logging';

const handleError = (message: string, error: any = null, silent = false) => {
  if (!silent) {
    appService.error(message);
  }
  if (error != null) {
    logger.error(error);
    if (config.SENTRY.ENABLED) {
      Sentry.captureException(error);
    }
  }
};

export { handleError };
