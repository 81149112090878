// import default locales
// https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/de';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/fr-ch';
import 'dayjs/locale/it-ch';

import dayjs from 'dayjs';
import enGB from 'dayjs/locale/en-gb';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

// extend dayjs
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(minMax);

// add custom locales

// for swiss english locale we take the en_GB and overwrite some date formats
const enCH = {
  ...enGB,
  name: 'en-ch',
  formats: {
    ...enGB.formats,
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY HH:mm',
    LLLL: 'dddd, D. MMMM YYYY HH:mm',
  },
};
dayjs.locale('en-ch', enCH);

// NOTE(dp): overwrite 'en' to `en-ch` because of vuetify datepicker, which seem to set dayjs locale to `en` on date selection
// when/if we need other than 'en-ch' date formats, this problem could potentially be solved with vue-i18n adapter in vuetify
dayjs.updateLocale('en', { ...enCH, name: 'en' });
