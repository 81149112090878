import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import exportService from '@/case-detail/subviews/export/services/export.service';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';

class ExportAccesService {
  constructor() {
    this.state = reactive({
      documentExport: null,

      emailSendingInProgress: false,
      shareFormValid: false,

      recipient: '',
      message: '',
      subject: '',
      bccToMyself: true,
      bccTo: '',
    });
  }

  async createExportAccess(exportId) {
    const response = await axios.post(
      config.API.EXPORTS_ENDPOINT.ACCESS.replace('{legalCaseId}', exportService.currentCase.id).replace('{exportId}', exportId),
    );

    $a.l($a.e.EXPORT_SHARE_LINK);

    return response.data;
  }

  async deleteExportAccess(exportId) {
    const response = await axios.delete(
      config.API.EXPORTS_ENDPOINT.ACCESS.replace('{legalCaseId}', exportService.currentCase.id).replace('{exportId}', exportId),
    );
    $a.l($a.e.EXPORT_SHARE_LINK_DELETE);
    return response.data;
  }

  async getExportAccess(exportId) {
    const response = await axios.get(
      config.API.EXPORTS_ENDPOINT.ACCESS.replace('{legalCaseId}', exportService.currentCase.id).replace('{exportId}', exportId),
    );
    return response.data;
  }

  parseSubjectLine(template) {
    if (template == null) {
      return '';
    }
    const patterns = {
      '{name}': authService.state.user.name,
      '{reference}': exportService.currentCase.reference,
      // HACK(ndv): we changed 'caseNumber' to 'reference' recently but never updated the default ExportConfig
      '{caseNumber}': exportService.currentCase.reference,
    };
    for (const [search, replace] of Object.entries(patterns)) {
      template = template.replace(search, replace);
    }
    return template;
  }

  /** Email Dialog */

  showSendEmailDialog(documentExport) {
    this.state.documentExport = documentExport;
    this.state.bccToMyself = documentExport.configuration.bccToMyself;
    this.state.bccTo = documentExport.configuration.bccDefault;
    this.state.subject = this.parseSubjectLine(this.state.documentExport?.configuration?.emailSubject);
  }

  resetAndCloseSendEmailDialog() {
    this.state.documentExport = null;
    this.state.recipient = '';
    this.state.message = '';
    this.state.subject = '';
    this.state.bccToMyself = true;
    this.state.bccTo = '';

    this.state.emailSendingInProgress = false;
    this.state.shareFormValid = false;
  }

  sendEmail(form) {
    this.state.emailSendingInProgress = true;
    axios
      .post(
        config.API.EXPORTS_ENDPOINT.MAIL.replace('{legalCaseId}', exportService.currentCase.id).replace('{exportId}', this.state.documentExport.id),
        {
          recipient: this.state.recipient,
          message: this.state.message,
          subject: this.state.subject,
          bccToSender: this.state.bccToMyself,
          bccTo: this.state.bccTo,
        },
      )
      .then(() => {
        appService.info($t('CaseDetail.Export.emailSentInfoMessage'));
        $a.l($a.e.EXPORT_SHARE_EMAIL);
      })
      .catch((e) => {
        handleError($t('CaseDetail.Export.emailSendErrorMessage'), e);
      })
      .finally(() => {
        this.resetAndCloseSendEmailDialog();
        form.resetValidation();
      });
  }
}

export default new ExportAccesService();
export const ExportAccesServiceClass = ExportAccesService;
