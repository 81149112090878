import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import { authService } from '@/common/services/auth/auth.service';
import { BroadcastEvent, BroadcastEventEmitParams, BroadcastEventName } from '@/common/services/broadcast.events';
import logger from '@/common/services/logging';

const CHANNEL_NAME: string = 'main_channel';

// event: BroadcastEvent (typing is too tricky here)
type GeneralEventHandler = (event: any) => void;

class BroadcastService {
  private sender: BroadcastChannel;

  private receiver: BroadcastChannel;

  private eventHandlers: Map<BroadcastEventName, { fn: GeneralEventHandler; once: boolean }[]> = new Map();

  constructor() {
    this.receiver = new BroadcastChannel(CHANNEL_NAME);
    this.sender = new BroadcastChannel(CHANNEL_NAME);
    this.receiver.onmessage = this.handleMessageEvent.bind(this);
  }

  private handleMessageEvent(messageEvent: MessageEvent): void {
    const { eventName, event } = JSON.parse(messageEvent.data);

    if (!eventName || !event) {
      logger.error(`Unhandled event type: ${eventName}`);
      return;
    }

    if (event.contextId !== appService.state.contextId) {
      return;
    }

    this.triggerHandlers(eventName as BroadcastEventName, event);
  }

  emit<E extends BroadcastEventName>(eventName: E, params: BroadcastEventEmitParams[E]) {
    const tenantId = authService.state.data?.tenant.id;
    const caseId = detailViewService.getCurrentLegalCaseId();
    const { contextId } = appService.state;

    logger.info(`Emitted: ${eventName}: ${JSON.stringify(params)}}`);

    try {
      // Serialize event object to a JSON string
      const serializedEvent = JSON.stringify({
        eventName,
        event: {
          ...params,
          tenantId,
          caseId,
          contextId,
        },
      });
      this.sender.postMessage(serializedEvent);
    } catch (error) {
      throw new Error(`Failed to serialize event: ${error}`);
    }
  }

  private triggerHandlers<E extends BroadcastEventName>(eventName: E, event: BroadcastEvent[E]) {
    if (this.eventHandlers.has(eventName)) {
      const handlers = this.eventHandlers.get(eventName)!;

      const toUnsubscribe: ((event: BroadcastEvent[E]) => void)[] = [];
      handlers.forEach((handler) => {
        handler.fn(event);
        if (handler.once) {
          toUnsubscribe.push(handler.fn);
        }
      });

      toUnsubscribe.forEach((fn) => {
        this.unsubscribe(eventName, fn);
      });
    }
  }

  subscribe<E extends BroadcastEventName>(eventName: E, handler: (event: BroadcastEvent[E]) => void) {
    if (!this.eventHandlers.has(eventName)) {
      this.eventHandlers.set(eventName, []);
    }
    this.eventHandlers.get(eventName)!.push({ fn: handler, once: false });
  }

  once<E extends BroadcastEventName>(eventName: E, handler: (event: BroadcastEvent[E]) => void) {
    if (!this.eventHandlers.has(eventName)) {
      this.eventHandlers.set(eventName, []);
    }
    this.eventHandlers.get(eventName)!.push({ fn: handler, once: true });
  }

  unsubscribe<E extends BroadcastEventName>(eventName: E, handlerFn?: (event: BroadcastEvent[E]) => void) {
    if (this.eventHandlers.has(eventName)) {
      const handlers = this.eventHandlers.get(eventName)!;
      if (handlerFn) {
        const index = handlers.findIndex((handler) => handler.fn === handlerFn);
        if (index !== -1) {
          handlers.splice(index, 1);
        }
        this.eventHandlers.set(eventName, handlers);
      } else {
        this.eventHandlers.delete(eventName);
      }
    }
  }

  closeChannel(): void {
    this.sender.close();
    this.receiver.close();
  }
}

export const broadcastEventBus = new BroadcastService();
