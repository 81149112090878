<template>
  <v-menu v-bind="$attrs" v-model="value">
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </v-menu>
</template>

<script>
import { VMenu } from 'vuetify/components/VMenu';

import { broadcastEventBus } from '@/common/services/broadcast.service';

export default {
  components: {
    VMenu,
  },
  props: {
    closeOnScroll: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue(val) {
      this.value = val;
    },
    value(val) {
      this.$emit('update:modelValue', val);
    },
  },
  mounted() {
    broadcastEventBus.subscribe('WEB_VIEWER_CLICKED_EVENT', this.handleWebviewerClickedEvent.bind(this));
    if (this.closeOnScroll) {
      broadcastEventBus.subscribe('NAVIGATION_SCROLLED_EVENT', this.handleNaviagtionscrolledEvent.bind(this));
    }
  },
  beforeUnmount() {
    broadcastEventBus.unsubscribe('WEB_VIEWER_CLICKED_EVENT', this.handleWebviewerClickedEvent.bind(this));
    if (this.closeOnScroll) {
      broadcastEventBus.unsubscribe('NAVIGATION_SCROLLED_EVENT', this.handleNaviagtionscrolledEvent.bind(this));
    }
  },
  methods: {
    handleWebviewerClickedEvent() {
      this.value = false;
    },
    handleNaviagtionscrolledEvent() {
      this.value = false;
    },
  },
};
</script>
