import axios from 'axios';
import { reactive } from 'vue';

import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import { authService } from '@/common/services/auth/auth.service';
import entityService, {
  COPILOT_LOCALIZED_PROMPTS,
  COPILOT_PROMPTS,
  CopilotCatalogQuestionLocalized,
  CopilotCatalogQuestionRaw,
} from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';
import {
  CopilotDashboard,
  CopilotDashboardHeading,
  CopilotDashboardHeadingLocalized,
  CopilotDashboardItem,
  CopilotDashboardLocalized,
} from '@/common/types/api-types/copilot.api.types';
import { EmptyObject, MultilingualText } from '@/common/types/common.types';

class CopilotCatalogService {
  DOCUMENT_CATALOG_QUESTIONS: COPILOT_PROMPTS | EmptyObject = {};

  LEGALCASE_CATALOG_QUESTIONS: COPILOT_PROMPTS | EmptyObject = {};

  state: {
    isInitialized: boolean;
    dashboards: CopilotDashboard[];
  };

  constructor() {
    this.state = reactive({
      isInitialized: false,
      dashboards: [] as CopilotDashboard[],
    });
  }

  async init(): Promise<void> {
    const catalogPromise = axios.get(config.API.COPILOT.CATALOG.LIST).then((response) => {
      this.DOCUMENT_CATALOG_QUESTIONS = reactive(response.data.DOCUMENT) as COPILOT_PROMPTS;
      this.LEGALCASE_CATALOG_QUESTIONS = reactive(response.data.LEGALCASE) as COPILOT_PROMPTS;
    });
    // NOTE(ndv): a bit ugly but tenants may have copilot enabled but not medinsights
    const dashboardsPromise = authService.hasFeature('ENABLE_MEDINSIGHTS')
      ? axios.get<CopilotDashboard[]>(config.API.COPILOT.DASHBOARD.LIST).then((response) => (this.state.dashboards = response.data))
      : Promise.resolve();

    await Promise.all([catalogPromise, dashboardsPromise]).then(() => {
      this.state.isInitialized = true;
    });
  }

  isInitialized(): boolean {
    return this.state.isInitialized;
  }

  // CATALOGS

  getCatalog(scope: API.Copilot.Scope): COPILOT_PROMPTS | EmptyObject {
    return (scope === 'LEGALCASE' ? this.LEGALCASE_CATALOG_QUESTIONS : this.DOCUMENT_CATALOG_QUESTIONS) as COPILOT_PROMPTS;
  }

  getCatalogLocalized(scope: API.Copilot.Scope): COPILOT_LOCALIZED_PROMPTS | EmptyObject {
    return entityService.localizeAndAddKey(this.getCatalog(scope), ['title', 'description']) as COPILOT_LOCALIZED_PROMPTS;
  }

  getCatalogQuestion(scope: API.Copilot.Scope, catalogKey: string): CopilotCatalogQuestionRaw | null {
    return this.getCatalog(scope)[catalogKey] || null;
  }

  getCatalogQuestionLocalized(scope: API.Copilot.Scope, catalogKey: string): CopilotCatalogQuestionLocalized | null {
    const rawCatalogQuestion = this.getCatalogQuestion(scope, catalogKey) as CopilotCatalogQuestionRaw;
    if (!rawCatalogQuestion) return null;

    const localized = entityService.localizeAndAddKey({ [catalogKey]: rawCatalogQuestion }, ['title', 'description']);
    return localized[catalogKey] as CopilotCatalogQuestionLocalized;
  }

  // DASHBOARD

  getDashboards(): CopilotDashboard[] {
    return this.state.dashboards;
  }

  getDashboard(dashboardKey: string): CopilotDashboard | null {
    return this.state.dashboards.find((d) => d.key === dashboardKey) || null;
  }

  getDashboardsLocalized(): CopilotDashboardLocalized[] {
    return this.getDashboards().map((dashboard) => ({
      ...dashboard,
      title: $t(dashboard.title),
      items: dashboard.items.map((item) => {
        if (Object.hasOwn(item, 'key')) {
          return {
            ...item,
            title: $t((item as CopilotCatalogQuestionRaw).title),
            description: $t((item as CopilotCatalogQuestionRaw).description),
          } as CopilotCatalogQuestionLocalized;
        }

        return { headingText: $t((item as CopilotDashboardHeading).headingText) } as CopilotDashboardHeadingLocalized;
      }),
    }));
  }

  getDashboardQuestion(dashboardKey: string, questionKey: string): CopilotCatalogQuestionRaw | null {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return null;

    return dashboard.items.find(
      (item) => Object.hasOwn(item, 'key') && (item as CopilotCatalogQuestionRaw).key === questionKey,
    ) as CopilotCatalogQuestionRaw;
  }

  getDashboardQuestionLocalized(dashboardKey: string, questionKey: string): CopilotCatalogQuestionLocalized | null {
    const rawQuestion = this.getDashboardQuestion(dashboardKey, questionKey);
    if (!rawQuestion) return null;

    return entityService.localizeAndAddKey({ [questionKey]: rawQuestion }, ['title', 'description'])[questionKey];
  }

  async createDashboardQuestion(dashboardKey: string, question: CopilotCatalogQuestionRaw, index: number = -1): Promise<void> {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    if (index === -1) {
      dashboard.items.push(question);
    } else {
      const insertIndex = this.getInsertIndex(dashboard.items, index);
      dashboard.items.splice(insertIndex, 0, question);
    }

    return this.updateDashboard(dashboard);
  }

  async updateDashboardQuestion(dashboardKey: string, updatedQuestion: CopilotCatalogQuestionRaw): Promise<void> {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();
    const question = dashboard.items.find((item) => (item as CopilotCatalogQuestionRaw).key === updatedQuestion.key);
    if (!question) return Promise.resolve();

    return this.updateDashboard(dashboard);
  }

  async deleteDashboardQuestion(dashboardKey: string, questionKey: string): Promise<void> {
    const dashboard = this.getDashboard(dashboardKey);
    if (!dashboard) return Promise.resolve();

    dashboard.items = dashboard.items.filter((item) => !Object.hasOwn(item, 'key') || (item as CopilotCatalogQuestionRaw).key !== questionKey);

    return this.updateDashboard(dashboard);
  }

  async moveDashboardItem(dashboardKey: string, fromIndex: number, direction: number): Promise<void> {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    const item = dashboard.items[fromIndex];
    if (!item) return Promise.resolve();

    const toIndex = fromIndex + direction;
    if (toIndex < 0 || toIndex >= dashboard.items.length) return Promise.resolve();

    // swap items
    [dashboard.items[fromIndex], dashboard.items[toIndex]] = [dashboard.items[toIndex], dashboard.items[fromIndex]];

    return this.updateDashboard(dashboard);
  }

  async createDashboardHeading(dashboardKey: string, subheaderTitle: MultilingualText, index: number = -1): Promise<void> {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    const newSubheader = { headingText: subheaderTitle } as CopilotDashboardHeading;
    if (index === -1) {
      dashboard.items.push(newSubheader);
    } else {
      const insertIndex = this.getInsertIndex(dashboard.items, index);
      dashboard.items.splice(insertIndex, 0, newSubheader);
    }

    return this.updateDashboard(dashboard);
  }

  async deleteDashboardHeading(dashboardKey: string, index: number): Promise<void> {
    const dashboard = this.getDashboards().find((d) => d.key === dashboardKey);
    if (!dashboard) return Promise.resolve();

    dashboard.items = dashboard.items.filter((item, i) => Object.hasOwn(item, 'key') || i !== index);

    return this.updateDashboard(dashboard);
  }

  // API

  async fetchDashboardKeys(): Promise<string[]> {
    return axios.get<string[]>(config.API.COPILOT.DASHBOARD.LIST_KEYS).then((response) => response.data);
  }

  async updateCatalog(scope: API.Copilot.Scope, catalog: COPILOT_PROMPTS): Promise<void> {
    return axios.post(config.API.COPILOT.CATALOG.UPDATE.replace('{scope}', scope), catalog);
  }

  async updateDashboard(dashboard: CopilotDashboard): Promise<void> {
    return axios.patch(config.API.COPILOT.DASHBOARD.UPDATE.replace('{dashboardKey}', dashboard.key), dashboard);
  }

  async translateText(text: string): Promise<Record<string, string>> {
    return axios.post<Record<string, string>>(config.API.COPILOT.DASHBOARD.TRANSLATE, { text }).then((response) => {
      const data = response.data as Record<string, any>;
      return data.text as Record<string, string>;
    });
  }

  // PRIVATE
  getInsertIndex(items: CopilotDashboardItem[], startIndex: number): number {
    for (let i = startIndex + 1; i < items.length; i++) {
      if (!Object.hasOwn(items[i], 'key')) {
        return i;
      }
    }

    return items.length - 1;
  }
}

export default new CopilotCatalogService();
export const CopilotCatalogServiceClass = CopilotCatalogService;
