import logger from '@/common/services/logging';

export const mergeIntoReactive = (target: any, source: any) => {
  for (const key in source) {
    if (Object.hasOwn(source, key)) {
      target[key] = source[key];
    } else {
      logger.warn(`Key ${key} doesn't exist in reactive object.`);
    }
  }
};

// helper for convenient Promise unwrap
export const safeUnwrap = async <T>(promise: Promise<T>): Promise<[Error | null, T | null]> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (error: any) {
    return [error, null];
  }
};

// clean object from false-ish prop values
export const withoutInvalidProps = (obj: Record<string, any>) => Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v));
