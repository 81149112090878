<template>
  <v-dialog v-model="appService.state.confirmDialog" max-width="500">
    <v-card class="d-flex flex-column" data-testid="modal_dialog">
      <v-card-item class="pl-5 py-2 pr-2">
        <v-card-title class="d-flex align-center" data-testid="lbl_title">
          <span>{{ appService.state.confirmQuestion }}</span>
          <v-spacer />
          <v-btn v-blur icon="mdi-close" density="comfortable" variant="text" @click="cancel()" />
        </v-card-title>
      </v-card-item>
      <v-divider />
      <v-card-text data-testid="lbl_body">
        <div v-dompurify-html="appService.state.confirmMessage" />
        <template v-if="appService.state.confirmValidation !== ''">
          <br />
          <i18n-t keypath="App.ConfirmationDialog.title" scope="global">
            <b>{{ appService.state.confirmValidation }}</b>
          </i18n-t>
          <br />
          <v-text-field
            v-model="input"
            data-testid="input_validation"
            density="compact"
            :rules="[validate]"
            :placeholder="appService.state.confirmValidation"
            variant="outlined"
            autofocus
            @keydown.stop=""
            @keydown.enter.stop="confirm()"
          />
        </template>
      </v-card-text>
      <v-divider class="my-2" />
      <v-card-actions class="justify-end">
        <v-btn color="primary" variant="text" data-testid="btn_cancel" @click="cancel()">
          {{ $t('Common.cancel') }}
        </v-btn>
        <v-spacer v-if="appService.state.altBtnTxt" />
        <v-btn v-if="appService.state.altBtnTxt" :color="appService.state.altBtnColor" variant="text" data-testid="btn_alt" @click="altConfirm()">
          {{ appService.state.altBtnTxt }}
        </v-btn>
        <v-btn
          :color="appService.state.confirmBtnColor"
          variant="flat"
          :disabled="appService.state.confirmValidation === '' ? false : !confirmationValid"
          data-testid="btn_confirm"
          @click="confirm()"
        >
          {{ appService.state.confirmBtnTxt }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appService from '@/app/services/app.service';

export default {
  components: {},
  data() {
    return {
      appService,
      input: '',
      confirmationValid: true,
    };
  },
  methods: {
    resetState() {
      this.input = '';
      this.confirmationValid = true;
    },
    confirm() {
      this.validate(this.input);
      if (!this.confirmationValid) {
        appService.info(this.$t('App.ConfirmationDialog.confirmationFailed'));
        return;
      }
      appService.state.confirmBtnFn();
      appService.state.confirmDialog = false;
      this.resetState();
    },
    altConfirm() {
      this.validate(this.input);
      if (!this.confirmationValid) {
        appService.info(this.$t('App.ConfirmationDialog.confirmationFailed'));
        return;
      }
      appService.state.altBtnFn();
      appService.state.confirmDialog = false;
      this.resetState();
    },
    cancel() {
      appService.state.confirmCancelBtnFn();
      appService.state.confirmDialog = false;
      this.resetState();
    },
    validate(value) {
      this.confirmationValid = value.toLowerCase().trim() === appService.state.confirmValidation.toLowerCase().trim();
      return this.confirmationValid;
    },
  },
};
</script>

<style scoped></style>
