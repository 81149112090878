import dayjs from 'dayjs';

import { Document } from '@/case-detail/subviews/document/services/document.service';
import { MetadataKey } from '@/common/types/api-types/document.api.types';

const sortByMetadataKey = (metadataKey: MetadataKey, a: Document, b: Document, asc = true) => {
  const aValue = a.metadata[metadataKey].value;
  const bValue = b.metadata[metadataKey].value;

  if (aValue === '' && bValue === '') return 0;
  if (aValue === '') return 1;
  if (bValue === '') return -1;

  const comparison = asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);

  if (comparison !== 0) return comparison;

  // make sure docs from same source file are near each other
  if (a.sourceFileId !== b.sourceFileId) return 1;

  return a.sourceFilePage - b.sourceFilePage;
};

const sortByIssueDateWithTime = (a: Document, b: Document, asc = true) => {
  const aValue = a.metadata.ISSUE_DATE.value;
  const bValue = b.metadata.ISSUE_DATE.value;

  if (aValue === '' && bValue === '') return 0;
  if (aValue === '') return 1;
  if (bValue === '') return -1;

  const aDateTimeStr = `${aValue}${a.metadata.ISSUE_DATE_TIME ? ` ${a.metadata.ISSUE_DATE_TIME.value}` : ''}`;
  const bDateTimeStr = `${bValue}${b.metadata.ISSUE_DATE_TIME ? ` ${b.metadata.ISSUE_DATE_TIME.value}` : ''}`;

  const aDateTime = dayjs(aDateTimeStr);
  const bDateTime = dayjs(bDateTimeStr);

  const comparison = asc ? aDateTime.diff(bDateTime) : bDateTime.diff(aDateTime);

  if (comparison !== 0) return comparison < 0 ? -1 : 1;

  // make sure docs from same source file are near each other
  if (a.sourceFileId !== b.sourceFileId) return 1;

  return a.sourceFilePage - b.sourceFilePage;
};

const sortByReceiptDate = (a: Document, b: Document, asc = true) => {
  const aValue = a.metadata.RECEIPT_DATE.value;
  const bValue = b.metadata.RECEIPT_DATE.value;

  if (aValue === '' && bValue === '') return 0;
  if (aValue === '') return 1;
  if (bValue === '') return -1;

  const aDate = dayjs(aValue);
  const bDate = dayjs(bValue);

  const comparison = asc ? aDate.diff(bDate) : bDate.diff(aDate);

  if (comparison !== 0) return comparison < 0 ? -1 : 1;

  // make sure docs from same source file are near each other
  if (a.sourceFileId !== b.sourceFileId) return 1;

  return a.sourceFilePage - b.sourceFilePage;
};

interface SortModeMetadata {
  icon?: string;
  iconSvg?: string;
  titleKey: string;
  sortFn: (a: Document, b: Document) => number;
}

const sortModesMetadata = {
  ISSUE_DATE_ASC: {
    icon: 'mdi-sort-calendar-descending',
    titleKey: 'CaseDetail.DocumentSort.issueDateAsc',
    sortFn: (a: Document, b: Document) => sortByIssueDateWithTime(a, b, true),
  },
  ISSUE_DATE_DESC: {
    icon: 'mdi-sort-calendar-ascending',
    titleKey: 'CaseDetail.DocumentSort.issueDateDesc',
    sortFn: (a: Document, b: Document) => sortByIssueDateWithTime(a, b, false),
  },

  // NOTE(dp): mdi icon set doesn't have needed icons for RECEIPT_DATE so we use custom svg
  RECEIPT_DATE_ASC: {
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 7h3v14h2V7h3l-4-4-4 4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
    titleKey: 'CaseDetail.DocumentSort.receiptDateAsc',
    sortFn: (a: Document, b: Document) => sortByReceiptDate(a, b, true),
  },
  RECEIPT_DATE_DESC: {
    iconSvg:
      '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none"><path fill="currentColor" d="M16 17h3V3h2v14h3l-4 4-4-4ZM2.4 9.463l5.6 4.39 5.6-4.39L8 5.073l-5.6 4.39Zm12.6 0v8.78c0 .467-.148.913-.41 1.243-.263.329-.619.514-.99.514H2.4c-.371 0-.727-.185-.99-.514A2.005 2.005 0 0 1 1 18.244V9.463c0-.641.273-1.194.679-1.502L8 3l6.321 4.961c.406.308.679.86.679 1.502Z"/></svg>',
    titleKey: 'CaseDetail.DocumentSort.receiptDateDesc',
    sortFn: (a: Document, b: Document) => sortByReceiptDate(a, b, false),
  },

  TITLE_ASC: {
    icon: 'mdi-sort-alphabetical-ascending',
    titleKey: 'CaseDetail.DocumentSort.titleAsc',
    sortFn: (a: Document, b: Document) => sortByMetadataKey('TITLE', a, b, true),
  },
  TITLE_DESC: {
    icon: 'mdi-sort-alphabetical-descending',
    titleKey: 'CaseDetail.DocumentSort.titleDesc',
    sortFn: (a: Document, b: Document) => sortByMetadataKey('TITLE', a, b, false),
  },
};

export type DocumentSortMode = keyof typeof sortModesMetadata;
export const sortModes = sortModesMetadata as Record<DocumentSortMode, SortModeMetadata>;
