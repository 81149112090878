import annotationIcons from '@/case-detail/subviews/document/annotations/services/user_annotation_icons.json';

export const getAnnotationIcon = (annotationKey: string, svg = false): string => {
  let entry = annotationIcons[annotationKey as keyof typeof annotationIcons];
  if (!entry) {
    // fallback to default: HIGHLIGHTS
    entry = annotationIcons.HIGHLIGHT;
  }

  if (svg) {
    return entry.iconSVG;
  }

  return entry.icon;
};
