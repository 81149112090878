/**
 * NOTE(dp):
 *
 * Basic color usage as documented my vuetify:
 * "bg-<color>" class for background color
 * "text-<color>" class for text color
 *
 * on-<smth> colors are special though:
 * 1. they applied automatically to corresponding background (i.e. "bg-primary" applies "on-primary" color to text)
 * 2. they can't be used with bg- or text- prefix, instead use them as they are (i.e. "on-accent" class to make text color "on-accent")
 * 3. don't pass this kind of colors to `color` prop in vuetify components (use class attr instead)
 *    (i.e. <v-icon color="on-primary"> will not work, it will be derived to class "text-on-primary" wich currently does nothing)
 */

import { ThemeDefinition } from 'vuetify/lib/framework.mjs';

export const light: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#190082', // could be overriden in branding settings
    'on-primary': '#fff',
    secondary: '#6546E8', // could be overriden in branding settings
    'on-secondary': '#fff',
    accent: '#ff8c42',
    'on-accent': '#fff',
    background: '#f1f3f5',
    'on-background': '#191c1d',
    'background-variant': '#f1f3f5',
    'on-background-variant': '#191c1d',
    'on-surface': '#191c1d',
    surfaceVariant: '#f7f7f7', // use camelCase to not coflict with vuetify, where this color is too high-contrast - rgb(66, 66, 66)
    outline: '#cfd4da',
    button: '#7b838b',
    'on-button': '#191c1d',
    'bg-hover': '#F8F8F8',
    'bg-hover-dark': '#ddd',
    placeholder: '#A5A7A8',

    casePilotGradientFrom: '#e233ff',
    casePilotGradientTo: '#ff6b00',

    docPilotGradientFrom: '#3384ED',
    docPilotGradientTo: '#33CC99',

    // states
    error: '#b3261e',
    'on-error': '#fff',
    info: '#191c1d',
    'on-info': '#fff',
    anchor: '#6546E8',
    success: '#43A047',
    'on-success': '#fff',

    // warning / danger
    warning: '#cd4842',
    'on-warning': '#fff',

    // categories
    medical: '#0288D1',
  },
};

export const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#190082',
    'on-primary': '#fff',
    secondary: '#6546E8',
    'on-secondary': '#fff',
    accent: '#ff8c42',
    'on-accent': '#fff',
    background: '#121212',
    'on-background': '#fff',
    'background-variant': '#f1f3f5',
    'on-background-variant': '#191c1d',
    surface: '#1e1e1e',
    'on-surface': '#fff',
    surfaceVariant: '#1d192b',
    outline: '#cfd4da',
    button: '#7b838b',
    'on-button': '#191c1d',
    'bg-hover': '#F8F8F8',
    'bg-hover-dark': '#ddd',
    placeholder: '#A5A7A8',

    casePilotGradientFrom: '#e233ff',
    casePilotGradientTo: '#ff6b00',

    docPilotGradientFrom: '#3384ED',
    docPilotGradientTo: '#33CC99',

    // states
    error: '#b3261e',
    'on-error': '#fff',
    info: '#191c1d',
    'on-info': '#fff',
    anchor: '#6546E8',

    // warning / danger
    warning: '#cd4842',
    'on-warning': '#fff',

    // categories
    medical: '#0288D1',
  },
};
