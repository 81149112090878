<template>
  <div :class="dropzoneClass" @mouseleave="showDropZone(false)" @drop.prevent="dropFile" @dragover.prevent="showDropZone(true)">
    <v-progress-linear v-if="isLoading" indeterminate rounded />
    <v-progress-linear v-else-if="isUploading" :indeterminate="uploadPercentage === 100" :model-value="uploadPercentage" rounded />
    <v-file-input
      v-else
      ref="fileInput"
      v-model="localFiles"
      accept=".pdf"
      counter
      :hint="$t('CaseList.AddEditCase.placeFilesHere')"
      :label="$t('CaseList.AddEditCase.documentsRequirement')"
      data-testid="input_file"
      multiple
      :error-messages="fileInputErrorMessages"
      @change="onInputChange"
      @click:clear="
        newSourceFile.originalFileUris = [];
        newSourceFile.uploadFilenames = [];
      "
    >
      <template #selection="{ fileNames }">
        <div class="d-flex align-center flex-wrap ga-1">
          <template v-for="(fileName, index) in fileNames" :key="fileName">
            <v-chip v-if="index < 3" class="me-2" color="primary" label size="small">
              {{ fileName }}
            </v-chip>
            <span v-else-if="index === 3" class="text-body-2 mx-2">{{ $t('CaseList.AddEditCase.restOfFiles', [localFiles.length - 3]) }}</span>
          </template>
        </div>
      </template>
    </v-file-input>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import appService from '@/app/services/app.service';
import fileService from '@/common/services/file.service';

export default defineComponent({
  props: {
    newSourceFile: {
      type: Object,
      required: true,
    },
  },

  emits: ['uploading'],

  data() {
    return {
      isLoading: false as boolean,
      isUploading: false as boolean,
      localFiles: [] as File[],
      uploadPercentage: 0 as number,
      dropzoneClass: '' as string,
      fileInputErrorMessages: [] as string[],
    };
  },

  watch: {
    newSourceFile(to) {
      // NOTE: no event is trigged on dialog close, therefore watch prop change.
      if (to.uploadFilenames.length === 0) {
        this.localFiles = [];
        this.isUploading = false;
      }
    },
  },

  methods: {
    async uploadFile(files: File[]) {
      if (!files) return;

      this.fileInputErrorMessages = [];

      if (files.length > 100) {
        appService.error(this.$t('CaseList.AddEditCase.documentsRequirement'));
        this.newSourceFile.originalFileUris = [];
        this.newSourceFile.uploadFilenames = [];
        this.localFiles = [];
        return;
      }
      // init arrays if necessary
      if (!this.newSourceFile.originalFileUris) {
        this.newSourceFile.originalFileUris = [];
      }
      if (!this.newSourceFile.uploadFilenames) {
        this.newSourceFile.uploadFilenames = [];
      }
      if (!files) return;

      this.isLoading = true;

      // check if pdfs have passwords
      const invalidFiles = await this.checkForPasswordProtectedPDFs(files);

      if (invalidFiles.length > 0) {
        this.isLoading = false;
        this.$nextTick(() => {
          this.fileInputErrorMessages = [this.$t('CaseList.AddEditCase.protectedFilesErrorDescription', [invalidFiles.join(', ')])];
          this.localFiles = [];
        });
        return;
      }

      this.isLoading = false;
      this.isUploading = true;
      this.$emit('uploading', this.isUploading);
      for (let i = 0; i < files.length; i++) {
        try {
          const presignedUploadURIResponse = await fileService.upload(files[i]);
          this.newSourceFile.originalFileUris.push(presignedUploadURIResponse.tempFilename);
          this.newSourceFile.uploadFilenames.push(files[i].name);
        } catch (e) {
          this.newSourceFile.originalFileUris = [];
          this.newSourceFile.uploadFilenames = [];
          this.localFiles = [];

          this.uploadPercentage = 100;
          this.isUploading = false;
          this.$emit('uploading', this.isUploading);

          handleError(this.$t('CaseList.AddEditCase.filesCanNotBeUploaded', [files[i].name]), e);
          return;
        }
      }

      this.isUploading = false;
      this.$emit('uploading', this.isUploading);

      this.uploadPercentage = 100;
    },
    async checkIfEncrypted(file: File): Promise<boolean> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], { type: 'application/pdf' });

          blob
            .text()
            .then((text: string) => {
              // check if the text contains encryption markers
              const isEncrypted = text.includes('Encrypt') || text.substring(text.lastIndexOf('<<'), text.lastIndexOf('>>')).includes('/Encrypt');
              resolve(isEncrypted);
            })
            .catch(reject);
        };

        reader.onerror = (e) => {
          reject(handleError(this.$t('CaseList.AddEditCase.filesCanNotBeUploaded', [file.name]), e));
        };

        reader.readAsArrayBuffer(file);
      });
    },

    async checkForPasswordProtectedPDFs(files: File[]): Promise<string[]> {
      const invalidFiles: string[] = [];

      for (const file of files) {
        try {
          const isEncrypted = await this.checkIfEncrypted(file);

          if (isEncrypted) {
            invalidFiles.push(file.name);
          }
        } catch (error) {
          // nothing to do
        }
      }

      return invalidFiles;
    },
    onInputChange(event: Event) {
      const { files } = event.target as HTMLInputElement;
      if (files) {
        this.uploadFile([...files]);
      }
    },
    showDropZone(e: boolean) {
      this.dropzoneClass = e ? 'dropzone' : '';
    },
    dropFile(e: DragEvent) {
      this.showDropZone(false);
      const droppedFiles = e.dataTransfer?.files;

      if (!droppedFiles || droppedFiles.length === 0) return;
      const files: File[] = [];
      for (let i = 0; i < droppedFiles.length; i++) {
        if (droppedFiles[i].type === 'application/pdf') {
          files.push(droppedFiles[i]);
        } else {
          appService.info(this.$t('CaseList.AddEditCase.onlyPDFsAreSupported'));
        }
      }
      if (files.length === 0) return;
      if (files.length > 100) {
        appService.error(this.$t('CaseList.AddEditCase.documentsRequirement'));
        this.localFiles = [];
        this.newSourceFile.originalFileUris = [];
        this.newSourceFile.uploadFilenames = [];
        return;
      }
      this.localFiles = [...this.localFiles, ...files];
      this.uploadFile(files);
    },
  },
});
</script>

<style scoped>
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.dropzone {
  background-color: rgb(var(--v-theme-surfaceVariant));
  border: 1px solid rgb(var(--v-theme-primary));
}
</style>
