// NOTE(mba): deprecated since DEMO only

import { $t } from '@/app/i18n/i18n.service';
import entityService from '@/common/services/entity.service';

const { LEGALCASE_METADATA } = entityService;

export const GENDERS = () => ({
  F: $t('Common.female'),
  M: $t('Common.male'),
  OTHER: $t('Common.other'),
});

export const JOB_POSITIONS = () =>
  Object.fromEntries(Object.entries(LEGALCASE_METADATA.JOB_POSITION.options).map(([key, option]) => [key, option.title]));
export const JOB_COMPETENCE_LEVELS = () =>
  Object.fromEntries(Object.entries(LEGALCASE_METADATA.JOB_COMPETENCE_LEVEL.options).map(([key, option]) => [key, option.title]));
export const JOB_PHYSICAL_LOADS = () =>
  Object.fromEntries(Object.entries(LEGALCASE_METADATA.JOB_PHYSICAL_LOAD.options).map(([key, option]) => [key, option.title]));

export const PERSONAL_FIELDS = () => ({
  gender: $t('Common.gender'),
  yearOfBirth: $t('Common.yearOfBirth'),
  jobPosition: $t('Common.professionalPosition'),
  jobCompetenceLevel: $t('Common.levelOfCompetence'),
  jobPhysicalLoad: $t('Common.physicalLoad'),
});

export const DAMAGE_FIELDS = () => ({
  earnings: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.earnings'),
  home: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.home'),
  care: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.care'),
  costs: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.costs'),
  other: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.other'),
  property: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.property'),
  compensation: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.compensation'),
  lawyerCost: $t('Common.PII_OPTIONS.DAMAGE_FIELDS.lawyerCost'),
});

export const FIELD_STATES = { NORMAL: 0, REQUIRED: 1, IGNORED: 2 };

export const resolveLabel = (key, defaultOverride) => {
  const defaultReturn = defaultOverride || key;

  const allLabels = {
    ...PERSONAL_FIELDS(),
    ...GENDERS(),
    ...JOB_POSITIONS(),
    ...JOB_COMPETENCE_LEVELS(),
    ...JOB_PHYSICAL_LOADS(),
    ...DAMAGE_FIELDS(),
  };
  return allLabels[key] || defaultReturn;
};
