class PollingService {
  constructor() {
    this.polls = new Map();
    this.isPageActive = true;
    this.initPageVisibility();
  }

  initPageVisibility() {
    // Set the name of the hidden property and the change event for visibility
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const onVisibilityChange = () => (this.isPageActive = !document[hidden]);
    document.addEventListener(visibilityChange, onVisibilityChange, {
      passive: true,
    });
  }

  add(name, callback, intervalMillis) {
    const callbackWrapper = () => {
      if (this.isPageActive) {
        callback();
      }
    };
    this.polls.set(name, {
      call: setInterval(callbackWrapper, intervalMillis),
      updated: Date.now(),
    });
  }

  remove(name) {
    const poll = this.polls.get(name);
    if (poll) {
      clearInterval(poll.call);
      this.polls.delete(name);
    }
  }

  destroy() {
    for (const poll of Object.values(this.polls)) {
      clearInterval(poll.call);
    }
  }
}

export default new PollingService();
