import { cloneDeep, endsWith } from 'lodash';
import { reactive } from 'vue';

import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import labelApiService from '@/case-detail/subviews/labels/services/label.api.service';
import { mergeIntoReactive } from '@/common/services/common.utils';
import entityService, { DOC_DOCTYPE_LABELS } from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export interface Label {
  id: string;
  parentId: string | null;
  tenantId: string | null;
  created: string | null;
  updated: string | null;

  title: string;
  color: string;
  icon: string;

  key: string | null;
  doctype: string | null;
  sorting: string | null;
}

export interface DoctypeLabel extends Label {
  tenantId: null;
  key: string; // previously `naturalKey`; value without "type_"
  doctype: string;
  sorting: string;
}

export interface CustomLabel extends Label {
  key: null;
  doctype: null;
  sorting: null;
}

const initialState = {
  labels: new Map() as Map<string, Label>,
  doctypeLabels: new Map() as Map<string, DoctypeLabel>,
  customLabels: new Map() as Map<string, CustomLabel>,
  legalCaseLabelIds: [] as string[],
  labelsLoaded: false,
};

export class LabelService {
  state: typeof initialState;

  constructor() {
    this.state = reactive(cloneDeep(initialState));
  }

  // GETTERS
  areLabelsLoaded() {
    return this.state.labelsLoaded;
  }

  getLabels() {
    return this.state.labels;
  }

  getLabel(labelId: string) {
    const isTypeOther = endsWith(labelId, '_other');
    const realLabelId = isTypeOther ? labelId.replace('_other', '') : labelId;
    const label = this.state.labels.get(realLabelId);
    return isTypeOther && label ? this.getTypeOtherSublabel(label) : label;
  }

  getTypeOtherSublabel(label: Label) {
    return {
      ...label,
      id: `${label.id}_other`,
      parentId: label.id,
      title: label.doctype === 'type_other' ? $t('CaseDetail.Labels.rest') : $t('CaseDetail.Labels.otherLabel', [label.title]),
    };
  }

  doctypeLabels() {
    return [...this.state.labels.values()].filter((l) => !!l.doctype);
  }

  getLegalCaseLabelIds() {
    return this.state.legalCaseLabelIds;
  }

  legalCaseLabels() {
    const labels = [];
    for (const id of this.state.legalCaseLabelIds) {
      labels.push(this.state.labels.get(id) as Label);
    }
    const lcl = [...this.doctypeLabels(), ...labels];
    return lcl.sort(this.labelSortFn);
  }

  // NOTE(ndv): only returns direct children
  getSublabels(labelId: string, includeTypeOther = false) {
    const label = this.getLabel(labelId);
    if (!label) {
      return [];
    }

    const directChildren = this.legalCaseLabels().filter((l) => l.parentId === labelId);
    if (includeTypeOther && label.doctype && directChildren.length) {
      directChildren.push(this.getTypeOtherSublabel(label));
    }
    return directChildren;
  }

  // NOTE(ndv): returns all children (2 levels deep)
  getAllSublabels(labelId: string, includeTypeOther = false) {
    const sublabels = this.getSublabels(labelId, includeTypeOther);

    let deepSublabels = [] as Label[];
    for (const sublabel of sublabels) {
      const children = this.getSublabels(sublabel.id, includeTypeOther);
      if (children.length === 0) continue;
      deepSublabels = [...deepSublabels, ...children];
    }

    return [...sublabels, ...deepSublabels];
  }

  labelSortFn(a: Label, b: Label) {
    if (!!a.sorting && !!b.sorting) {
      // at this point both have format: <0+>[.<0+>[.<0+>]]
      const partsA = a.sorting.split('.').map(Number);
      const partsB = b.sorting.split('.').map(Number);
      const comparisons = Array.from({ length: 3 }).map((v, i) => [partsA[i] ?? -1, partsB[i] ?? -1]);
      for (const [aPos, bPos] of comparisons) {
        if (aPos !== bPos) return aPos - bPos;
      }
    }
    if (a.doctype && !b.doctype) {
      return -1;
    }
    if (!a.doctype && b.doctype) {
      return 1;
    }
    return a.title.localeCompare(b.title);
  }

  getLabelFamily(id: string) {
    if (id.endsWith('-other')) {
      const labelId = id.replace('-other', '');
      return [labelId];
    }

    const result = [id];
    result.push(...this.getAllSublabels(id, true).map((l) => l.id));
    return result;
  }

  // SETTERS & ACTIONS

  setLabelsLoaded(labelsLoaded: boolean) {
    this.state.labelsLoaded = labelsLoaded;
  }

  clear() {
    mergeIntoReactive(this.state, cloneDeep(initialState));
  }

  private standartizeLabelObject(labelEO: API.Label.Response, doctypeLabels?: DOC_DOCTYPE_LABELS) {
    const label: Label = {
      ...labelEO,
      key: null,
      sorting: null,
      title: labelEO.title,
      icon: 'mdi-label',
      doctype: null,
    };

    const doctypeLabelMetadata = doctypeLabels && doctypeLabels.metadata.byId[label.id];
    if (doctypeLabelMetadata) {
      // this kind of labels are completely empty in DB because defined in config
      label.key = doctypeLabelMetadata.key;
      label.doctype = doctypeLabelMetadata.doctype;
      label.title = doctypeLabelMetadata.title;
      label.color = doctypeLabelMetadata.color;
      label.icon = doctypeLabelMetadata.icon;

      // travel through tree and populate sorting + parentId
      for (const [i, rootLabel] of Object.entries(doctypeLabels.structure)) {
        if (label.key === rootLabel.labelKey) {
          label.sorting = `${Number(i) + 1}`;
          break;
        }

        for (const [j, subLabel] of Object.entries(rootLabel.children ?? [])) {
          if (label.key === subLabel.labelKey) {
            label.sorting = `${Number(i) + 1}.${Number(j) + 1}`;
            label.parentId = doctypeLabels.metadata.byKey[rootLabel.labelKey].id;
            break;
          }

          for (const [k, subSubLabel] of Object.entries(subLabel.children ?? [])) {
            if (label.key === subSubLabel.labelKey) {
              label.sorting = `${Number(i) + 1}.${Number(j) + 1}.${Number(k) + 1}`;
              label.parentId = doctypeLabels.metadata.byKey[subLabel.labelKey].id;
              break;
            }
          }
          if (label.sorting) break;
        }
        if (label.sorting) break;
      }
    }

    return label;
  }

  async fetch(): Promise<Label[]> {
    const labels = await labelApiService.load();
    const doctypeLabels = entityService.DOC_DOCTYPE_LABELS;
    return labels.map((v: any) => this.standartizeLabelObject(v, doctypeLabels));
  }

  async load() {
    const labels = await this.fetch();
    const labelsMap = new Map(labels.map((l) => [l.id, l]));
    this.state.labels = labelsMap;

    for (const [labelId, labelData] of labelsMap) {
      if (labelData.doctype) {
        this.state.doctypeLabels.set(labelId, labelData as DoctypeLabel);
      } else {
        this.state.customLabels.set(labelId, labelData as CustomLabel);
      }
    }

    let legalCaseLabels: string[] = detailViewService.getCurrentLegalCase()?.labels ?? [];

    // sanity check & avoid duplicates
    const doctypeLabelsIds = this.doctypeLabels().map((l) => l.id);
    legalCaseLabels = legalCaseLabels.filter((labelId) => this.state.labels.has(labelId) && !doctypeLabelsIds.includes(labelId));

    this.state.legalCaseLabelIds = legalCaseLabels;
    this.setLabelsLoaded(true);
  }

  async create(title: string, color: string, parentId: string) {
    const label = await labelApiService.create(title, color, parentId);
    this.state.labels.set(label.id, this.standartizeLabelObject(label));

    // update legal case labels
    this.addLabelToLegalCase(label.id);
    appService.info($t('CaseDetail.Labels.labelAdded'));
  }

  addExistingLabel(labelId: string) {
    if (!this.state.labels.has(labelId)) return;

    // update legal case labels
    this.addLabelToLegalCase(labelId);
    appService.info($t('CaseDetail.Labels.labelAdded'));
  }

  async deleteLabel(labelId: string) {
    documentService.deleteLabelFromAllDocument(labelId);

    this.state.legalCaseLabelIds = this.state.legalCaseLabelIds.filter((id) => id !== labelId);
    const legalCaseId = detailViewService.getCurrentLegalCaseId();
    await labelApiService.delete(labelId, legalCaseId);
    await detailViewService.updateCurrentLegalCaseMetadata('INTERNAL_LABELS', JSON.stringify(this.state.legalCaseLabelIds));
    appService.info($t('CaseDetail.Labels.labelDeleted'));
    documentService.setOutOfSync(true);
  }

  async addLabelToDocument({ documentId, labelId }: { documentId: string; labelId: string }) {
    const document = documentService.getDocumentsCache().get(documentId)!;
    const currentLabels = [...document.labels];
    const undoFn = async () => {
      document.labels = currentLabels;
      labelApiService.updateDocumentLabels(document.caseId, document.id, currentLabels);
    };

    if (document.labels.length === 0) {
      document.labels.push(labelId);
    } else {
      if (document.labels.find((id: string) => id === labelId)) {
        return;
      }

      // Handle mutual exclusivity
      let documentLabels: string[] = document.labels;
      const newLabelDoctype = this.state.labels.get(labelId)?.doctype;
      if (newLabelDoctype) {
        // Remove existing conflicting labels
        for (const id of document.labels) {
          const labelObj = this.state.labels.get(id);
          if (labelObj?.doctype) {
            documentLabels = documentLabels.filter((x) => x !== id);
            await labelApiService.deleteLabelFromDocument(document.caseId, id, documentId);
            break;
          }
        }
      }
      document.labels = [...documentLabels, labelId];
    }

    await labelApiService.addLabelToDocument(document.caseId, labelId, documentId);
    appService.info($t('CaseDetail.Labels.labelChanged'), undoFn);

    documentService.setOutOfSync(true);
  }

  addLabelToLegalCase(labelId: string) {
    if (!this.state.labels.has(labelId) || this.state.legalCaseLabelIds.includes(labelId)) return;

    const label = this.state.labels.get(labelId);
    let newLegalCaseLabels = [...this.state.legalCaseLabelIds, labelId];

    if (label?.parentId) {
      const parentLabel = this.state.labels.get(label.parentId);
      // automatically add parent if needed, but only if parent isn't doctype label
      if (parentLabel && !parentLabel.doctype && !newLegalCaseLabels.includes(parentLabel.id)) {
        newLegalCaseLabels = [...newLegalCaseLabels, label.parentId];
      }
    }

    this.updateLegalCaseLabels(newLegalCaseLabels);
  }

  async updateLegalCaseLabels(legalCaseLabelIds: UUID[]) {
    this.state.legalCaseLabelIds = legalCaseLabelIds;
    await detailViewService.updateCurrentLegalCaseMetadata('INTERNAL_LABELS', JSON.stringify(legalCaseLabelIds));
  }
}

export default new LabelService();
