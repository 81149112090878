<template>
  <div v-if="authService.state.isLoggedIn" v-tippy="$t('App.Bar.TenantMenu.tooltip')">
    <l-menu v-model="menu" location="bottom end" :close-on-content-click="false" transition="scroll-y-transition" @update:model-value="onMenuInput">
      <template #activator="{ props }">
        <v-btn v-ripple size="large" variant="text" max-width="270px" class="px-2" data-testid="tenant_menu_button" v-bind="props">
          <div class="d-flex flex-column align-start">
            <span class="text-subtitle-2 text-none">{{ currentTenant.name }}</span>
            <v-chip
              v-if="$hasPermission('ACCESS_EXTENDED_TENANT_MENU')"
              size="small"
              label
              link
              density="compact"
              :color="tenantEnvironmentsConfig[currentTenant.environment].color"
              class="text-caption text-uppercase py-2 text-white"
              @click.prevent
            >
              {{ tenantEnvironmentsConfig[currentTenant.environment].name }}
            </v-chip>
          </div>
          <v-icon end> mdi-chevron-down </v-icon>
        </v-btn>
      </template>

      <v-card flat max-height="420px" width="290px" class="overflow-hidden">
        <div v-show="!showTenantsList">
          <v-list>
            <v-list-item>
              <v-list-item-title>
                {{ currentTenant.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="d-flex align-center ga-1">
                  <CopyableText :text="currentTenant.canonicalName" tooltip="Canonical Name" /> –
                  {{ tenantEnvironmentsConfig[currentTenant.environment].name }}
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <CopyableText v-if="$hasPermission('ACCESS_EXTENDED_TENANT_MENU')" :text="currentTenant.id" tooltip="Workspace Id" />
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="my-2" />
            <v-list-item
              v-if="$hasPermission('ACCESS_REPORTING')"
              density="compact"
              link
              data-testid="workspace_reporting"
              @click="goToPage('reporting')"
            >
              <v-list-item-title>
                <v-icon size="small" start> mdi-chart-timeline-variant-shimmer </v-icon> {{ $t('App.Bar.TenantMenu.reporting') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasPermission('ACCESS_BILLING') && $hasFeature('ENABLE_BILLING')"
              density="compact"
              link
              data-testid="workspace_BILLING"
              @click="goToPage('billing')"
            >
              <v-list-item-title> <v-icon size="small" start> mdi-cash-multiple </v-icon> {{ $t('App.Bar.TenantMenu.billing') }} </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasPermission('ACCESS_AUDIT_LOG')"
              density="compact"
              link
              data-testid="workspace_audit_log"
              @click="goToPage('audit-log')"
            >
              <v-list-item-title>
                <v-icon size="small" start> mdi-clipboard-text-clock-outline </v-icon> {{ $t('App.Bar.TenantMenu.auditLog') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasPermission('ACCESS_WORKSPACE_SETTINGS')"
              density="compact"
              link
              data-testid="workspace_settings"
              @click="goToPage('settings')"
            >
              <v-list-item-title> <v-icon size="small" start> mdi-cog </v-icon> {{ $t('Common.settings') }} </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$hasPermission('ACCESS_WORKSPACE_SETTINGS')"
              density="compact"
              link
              data-testid="organization_settings"
              @click="goToPage('organization')"
            >
              <v-list-item-title> <v-icon size="small" start> mdi-domain </v-icon> {{ $t('App.Bar.TenantMenu.organization') }} </v-list-item-title>
            </v-list-item>
            <v-divider v-if="$isLegaliAdmin()" class="my-2" />
            <v-list-item v-if="authorizedTenants.length > 1" density="compact" link @click.stop="displayTenantsList()">
              <v-list-item-title> <v-icon size="small" start> mdi-sync </v-icon> {{ $t('App.Bar.TenantMenu.switchWorkspace') }} </v-list-item-title>
              <template #append>
                <v-icon size="small"> mdi-arrow-right </v-icon>
              </template>
            </v-list-item>
          </v-list>
        </div>

        <div v-show="showTenantsList" class="overflow-y-hidden">
          <v-toolbar density="compact" color="surface">
            <template #prepend>
              <v-btn icon="mdi-arrow-left" size="small" @click.stop="showTenantsList = false" />
            </template>
            <v-toolbar-title class="text-body-1 pa-0 ml-1">
              {{ $t('App.Bar.TenantMenu.workspaces') }}
            </v-toolbar-title>
          </v-toolbar>

          <div class="px-3 pb-2">
            <v-text-field
              ref="search"
              v-model="searchQuery"
              density="compact"
              hide-details
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('App.Bar.TenantMenu.searchWorkspaces')"
              @keydown.stop
            />
          </div>

          <v-list-item v-if="authorizedTenantsItems.length === 0" density="compact">
            <v-list-item-subtitle>
              {{ $t('Common.noResults') }}
            </v-list-item-subtitle>
          </v-list-item>
          <perfect-scrollbar v-else>
            <v-list class="pt-0">
              <template v-for="(item, i) of authorizedTenantsItems">
                <v-list-subheader v-if="item?.header" :key="i" class="mt-4 mb-1 font-weight-bold organization-subheader">
                  <v-icon start size="small"> mdi-domain </v-icon>
                  {{ item.header }}
                </v-list-subheader>

                <v-list-item v-else :key="item.id" density="compact" @click="changeTenant(item.canonicalName)">
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="$hasPermission('ACCESS_EXTENDED_TENANT_MENU')">
                    <div class="d-flex align-center ga-1">
                      <CopyableText :text="item.canonicalName" tooltip="Canonical Name" /> – {{ tenantEnvironmentsConfig[item.environment].name }}
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="$hasPermission('ACCESS_EXTENDED_TENANT_MENU')">
                    <CopyableText :text="item.id" tooltip="Workspace Id" />
                  </v-list-item-subtitle>

                  <template #append>
                    <v-list-item-action>
                      <v-icon v-if="item.id === currentTenant.id" size="small" color="primary"> mdi-check </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list>
          </perfect-scrollbar>
        </div>
      </v-card>
    </l-menu>
  </div>
</template>

<script>
import CopyableText from '@/common/components/CopyableText.vue';
import { authService } from '@/common/services/auth/auth.service';
import entityService from '@/common/services/entity.service';
import { sortTenantsByEnvAndName } from '@/workspace-admin/settings/services/settings.helper';

export default {
  components: {
    CopyableText,
  },
  data() {
    return {
      authService,
      tenantEnvironmentsConfig: entityService.TENANT_ENVIRONMENTS,
      menu: null,
      showTenantsList: false,
      searchQuery: null,
    };
  },
  computed: {
    currentTenant() {
      return authService.state.data?.tenant;
    },
    authorizedTenants() {
      if (!authService.state.data) return [];
      return authService.state.data.tenants;
    },
    authorizedTenantsItems() {
      let tenants = [...this.authorizedTenants];
      tenants.sort(sortTenantsByEnvAndName);

      // filter by query, if any
      if (this.searchQuery?.trim()) {
        const query = this.searchQuery.trim().toLowerCase();
        tenants = tenants.filter(
          (t) =>
            t.name.toLowerCase().includes(query) ||
            t.organization.toLowerCase().includes(query) ||
            t.canonicalName.toLowerCase().includes(query) ||
            t.id.toLowerCase().includes(query),
        );
      }

      if (!this.$isLegaliAdmin()) return tenants;

      // group by organization for legal-i admin users
      const items = [];
      let currentOrg = null;
      for (const tenant of tenants) {
        if (currentOrg !== tenant.organization) {
          currentOrg = tenant.organization;
          items.push({ header: currentOrg || this.$t('Common.other') });
        }
        items.push(tenant);
      }
      return items;
    },
  },
  methods: {
    changeTenant(tenantCanonicalName) {
      if (this.currentTenant.canonicalName === tenantCanonicalName) return;

      this.$a.l(this.$a.e.APP_WORKSPACE_SWITCH);
      window.location.replace(`/c/${tenantCanonicalName}`);
    },
    goToPage(pageName) {
      this.$router.push({ name: pageName, params: { tenant: this.currentTenant.canonicalName } });
      this.menu = null;
    },
    displayTenantsList() {
      this.showTenantsList = true;
      setTimeout(() => {
        this.$refs.search.focus();
      }, 500);
    },
    onMenuInput(value) {
      if (!value) {
        this.showTenantsList = false;
        this.searchQuery = null;
      }
    },
  },
};
</script>

<style scoped>
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.ps {
  /* NOTE(dp): here we calculate max available height */
  /* wrapper card max-height: 420 (set in props), elements at the top: 48+48 (calculated manually) */
  height: calc(420px - 48px - 48px);
}
.organization-subheader {
  height: 16px;
}
</style>
