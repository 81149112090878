import { SearchSuggestion } from '@/case-detail/search/services/search.service';
import { Document, DocumentDiagnosis } from '@/case-detail/subviews/document/services/document.service';
import { LabelService } from '@/case-detail/subviews/labels/services/label.service';
import { SearchFieldKey } from '@/common/services/entity.service';
import { UUID } from '@/common/types/common.types';

export function getSuggestionId(value: string | number, fieldKey: SearchFieldKey) {
  value = value.toString().replace(/\s+/g, '_');

  return `${fieldKey}__${value}`;
}

export function mapFullTextSugestions(suggestions: string[], limit = 0): SearchSuggestion[] {
  const fieldKey: SearchFieldKey = 'fulltext';
  const end = limit > 0 ? Math.min(limit, suggestions.length) : suggestions.length;
  return suggestions
    .map((s) => ({
      suggestion: s,
      field: fieldKey,
      id: getSuggestionId(s, fieldKey),
    }))
    .slice(0, end);
}

export function mapCaseSearchSuggestions(suggestions: string[], limit = 0): SearchSuggestion[] {
  const fieldKey: SearchFieldKey = 'fulltext';
  const end = limit > 0 ? Math.min(limit, suggestions.length) : suggestions.length;
  return suggestions
    .map((s) => ({
      suggestion: s,
      field: fieldKey,
      id: getSuggestionId(s, fieldKey),
    }))
    .slice(0, end);
}

export function findDocumentIds(documents: Document[], diagnosis: DocumentDiagnosis) {
  return documents
    .filter((d) => d.diagnoses.map((dd) => dd.icd10Code).includes(diagnosis.icd10Code))
    .map((d) => ({
      id: d.id,
      page: 1,
    }));
}

export function getLabel(labelService: LabelService, labelIds: UUID[]) {
  if (labelIds.length === 0) {
    return null;
  }

  if (labelIds.length === 1) {
    return labelService.getLabel(labelIds[0])!;
  }

  // get top-most label
  // try via parentId
  const labels = labelIds.map((id) => labelService.getLabel(id)!);
  const parentLabel = labels.find((l) => !l.parentId);
  if (parentLabel) {
    return parentLabel;
  }
  // fallback to using sublabels as no top-most label has been found
  for (const label of labels) {
    const sublabels = labelService.getSublabels(label.id);
    if (sublabels.length > 0) {
      return label;
    }
  }

  return null;
}
