<template>
  <v-form class="fill-width">
    <div class="py-0 d-flex align-end">
      <FileInput class="flex-grow-1 mr-2" :new-source-file="newSourceFile" @uploading="handleUploading" />
      <div style="width: 308px">
        <v-autocomplete
          v-model="newSourceFile.folder"
          class="px-2"
          :items="folders"
          :label="$t('Common.docFolder')"
          data-testid="input_fileType"
          :no-data-text="$t('Common.notFound')"
          @update:focused="onFolderInputFocusUpdate"
        />
      </div>
      <v-btn
        v-if="showAddButton"
        :disabled="newSourceFile.originalFileUris.length === 0 || uploading"
        class="mr-auto ml-2 mb-5"
        color="primary"
        data-testid="btn_upload"
        variant="flat"
        @click="$emit('save')"
      >
        <v-icon class="mr-1"> mdi-cloud-upload-outline </v-icon>
        {{ $t('Common.add') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import FileInput from '@/case-list/components/FileInput.vue';
import { folderService } from '@/common/services/folder.service';
import { OTHER_FOLDER_KEY } from '@/common/services/folder.utils';

interface NewSourceFile {
  originalFileUris: string[];
  uploadFilenames: string[];
  folder: string;
  settings: Record<string, unknown>;
}

export default defineComponent({
  components: {
    FileInput,
  },

  props: {
    showAddButton: {
      type: Boolean,
      default: true,
    },
    newSourceFile: {
      type: Object as PropType<NewSourceFile>,
      required: true,
    },
  },

  emits: ['save', 'uploading'],

  data() {
    return {
      uploading: false,
    };
  },

  computed: {
    folders() {
      return folderService.getLocalizedFolders();
    },
  },

  methods: {
    handleUploading(flag: boolean) {
      this.uploading = flag;
      this.$emit('uploading', this.uploading);
    },
    onFolderInputFocusUpdate(focused: boolean) {
      if (focused) {
        this.newSourceFile.folder = '';
      } else if (!this.newSourceFile.folder) {
        this.newSourceFile.folder = OTHER_FOLDER_KEY;
      }
    },
  },
});
</script>

<style scoped></style>
