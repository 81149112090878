import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import labelService from '@/case-detail/subviews/labels/services/label.service';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import $a from '@/common/services/analytics/analytics';

const LABELS_LEGALCASE_METADATA_KEY = 'INTERNAL_LABELS';

class LabelService {
  static async load() {
    return axios
      .get(config.API.LABELS_ENDPOINT.ROOT)
      .then((response) => response.data)
      .catch((e) => {
        handleError($t('CaseDetail.Labels.labelslLoadError'), e);
        return [];
      });
  }

  static async create(title: string, color: string, parentId: string) {
    const request = {
      title,
      color,
      parentId,
    };
    return axios
      .put(config.API.LABELS_ENDPOINT.LEGAL_CASE.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()), request)
      .then((response) => response.data);
  }

  static async update(labelId: string, text: string, color: string, parentId: string) {
    const request = {
      text,
      color,
      parentId,
    };
    return axios.patch(
      config.API.LABELS_ENDPOINT.LEGAL_CASE_LABEL.replace('{legalCaseId}', detailViewService.getCurrentLegalCaseId()).replace('{labelId}', labelId),
      request,
    );
  }

  static async delete(labelId: string, legalCaseId: string) {
    return axios.delete(`${config.API.LABELS_ENDPOINT.LEGAL_CASE_LABEL.replace('{legalCaseId}', legalCaseId).replace('{labelId}', labelId)}`);
  }

  static async addLabelToDocument(legalCaseId: string, labelId: string, documentId: string) {
    const newDoctype = labelService.getLabel(labelId)?.doctype;
    const selectedDocDoctype = documentService.getSelected()?.metadata?.DOCTYPE?.value;

    if (newDoctype !== undefined && newDoctype !== selectedDocDoctype) {
      $a.l('DOC_META_EDIT_DOCTYPE');
    }

    return axios.put(
      `${config.API.LABELS_ENDPOINT.LEGAL_CASE_DOCUMENT_LABEL.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId).replace('/{labelId}', '')}`,
      {
        labelId,
        documentId,
      },
    );
  }

  static async updateDocumentLabels(legalCaseId: string, documentId: string, labelIds: string[]) {
    const labels = labelIds.map((labelId) => ({
      documentId,
      labelId,
    }));

    return axios.patch(
      `${config.API.LABELS_ENDPOINT.LEGAL_CASE_DOCUMENT_LABEL.replace('{legalCaseId}', legalCaseId).replace('{documentId}', documentId).replace('/{labelId}', '')}`,
      labels,
    );
  }

  static async deleteLabelFromDocument(legalCaseId: string, labelId: string, documentId: string) {
    return axios.delete(
      `${config.API.LABELS_ENDPOINT.LEGAL_CASE_DOCUMENT_LABEL.replace('{legalCaseId}', legalCaseId)
        .replace('{documentId}', documentId)
        .replace('{labelId}', labelId)}`,
    );
  }

  static async getLegalCaseLabels(legalCaseId: string) {
    const response = await legalCaseAPIClient.fetchMetadata(legalCaseId, LABELS_LEGALCASE_METADATA_KEY);
    /* Note (ndv): this is done because in the case where we only have a single label id,
     * only a single string is returned instead of an array with 1 element.
     * I don't know the actual reason, so this will take care of it for now.
     */
    return Array.isArray(response) ? response : [response];
  }
}

export default LabelService;
