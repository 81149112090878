import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import { authService } from '@/common/services/auth/auth.service';

class DataLabelingService {
  async freezeMetadata(entity, legalCaseId, key) {
    try {
      const tenantId = authService.state.data?.tenant.id;
      let url;
      if (entity === 'DOCUMENT') {
        url = config.API.DATA_LABELING.FREEZE_DM.replace('{tenantId}', tenantId);
      } else if (entity === 'LEGALCASE') {
        url = config.API.DATA_LABELING.FREEZE_LCM.replace('{tenantId}', tenantId);
      } else if (entity === 'OTHER') {
        url = config.API.DATA_LABELING.FREEZE_OTH.replace('{tenantId}', tenantId);
      } else {
        handleError($t('Settings.metadataFreezeErrorUnkown'), null);
        return;
      }

      const queryParams = new URLSearchParams();
      if (legalCaseId) {
        queryParams.append('legalCaseId', legalCaseId);
      }
      if (key) {
        let paramName = '';
        switch (entity) {
          case 'DOCUMENT':
            paramName = 'dmKey';
            break;
          case 'LEGALCASE':
            paramName = 'lcmKey';
            break;
          case 'OTHER':
            paramName = 'key';
            break;
          default:
            paramName = '';
        }
        if (paramName) queryParams.append(paramName, key);
      }
      url += queryParams.toString() ? `?${queryParams.toString()}` : '';

      await axios.post(url);
    } catch (error) {
      handleError($t('Settings.metadataFreezeError'), error);
    }
  }

  async unfreezeMetadata(entity, key, includeUserLabeled) {
    try {
      const tenantId = authService.state.data?.tenant.id;
      let urlKey = '';
      let urlBase = '';

      switch (entity) {
        case 'DOCUMENT':
          urlKey = 'DM';
          break;
        case 'LEGALCASE':
          urlKey = 'LCM';
          break;
        case 'OTHER':
          urlKey = 'OTH';
          break;
        default:
          urlKey = '';
      }

      urlBase = includeUserLabeled ? `UNFREEZE_${urlKey}_INCLUDING_LABELED` : `UNFREEZE_${urlKey}_EXCLUDING_LABELED`;
      const url = config.API.DATA_LABELING[urlBase].replace('{tenantId}', tenantId).replace(`{${urlKey.toLowerCase()}Key}`, key);

      await axios.post(url);
    } catch (error) {
      handleError($t('Settings.failedToUnfreezeWorkspaceMetadata'), error);
    }
  }
}

export default new DataLabelingService();
