<template>
  <div v-if="!!appService.state.brandingLogo" class="mr-3 mt-1 poweredby-logo">
    <v-img data-testid="header_logo" height="23" width="180" :src="appService.state.brandingLogo" />
    <div class="d-flex align-end justify-center ml-1" style="margin-top: 1px">
      <div class="text-grey text">powered by</div>
      <v-img max-height="13" max-width="50" src="/assets/logo.svg" />
    </div>
  </div>
  <div v-else style="margin-top: 2px">
    <v-img data-testid="header_logo" height="30" width="120" src="/assets/logo.svg" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import appService from '@/app/services/app.service';

export default defineComponent({
  data() {
    return {
      appService,
    };
  },
});
</script>

<style scoped lang="scss">
.poweredby-logo {
  white-space: nowrap;
}

.poweredby-logo .text {
  font-size: 10px !important;
}
</style>
