import axios from 'axios';
import ColorHash from 'color-hash';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import { Locale } from '@/app/i18n/locale';
import { authService } from '@/common/services/auth/auth.service';
import { UserRoleId } from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';
import { TenantAuthorizationRequest, UserUpdateRequest } from '@/common/types/api-types/user.api.types';
import { ISODateTimeString, UUID } from '@/common/types/common.types';

type SpecialUserId = 'system' | 'pipeline' | 'support' | 'anonymous' | 'guest' | 'unknown';

interface UserAvatarData {
  type: 'initials' | 'icon';
  content: string;
  color: string;
}

export interface User {
  user_id: string | SpecialUserId;
  name: string;
  avatar: UserAvatarData;

  // copy rest of API.User.Response fields, but optional since they will be undefined for virtual users
  email?: string;
  role?: UserRoleId;
  provider?: string;
  lastLogin?: ISODateTimeString;
  accessGroups?: string[];
  homeTenantId?: UUID;
  authorizedTenants?: API.User.AuthorizedTenant[];
  userDetails?: API.User.AppMetadataUserDetails;
  auth0InternalUserId?: string;
  legaliAdmin?: boolean;
  sso?: boolean;
}

interface ServiceState {
  users: API.User.Response[];
  supportUserIds: string[];
}

export class UserService {
  state: ServiceState;

  colorHash: ColorHash;

  constructor() {
    this.state = reactive({
      users: [],
      supportUserIds: [],
    });

    this.colorHash = new ColorHash({ lightness: 0.4 });
  }

  async init() {
    if (this.state.users.length > 0) {
      return Promise.resolve();
    }

    return Promise.all([this.loadSupportUsers(), this.loadUsers()]);
  }

  /** * CRUD ** */

  async loadUsers() {
    return axios
      .get(config.API.USERS.BASE)
      .then((response) => {
        this.state.users = response.data;
      })
      .catch((error) => {
        handleError($t('Common.User.loadError'), error);
      });
  }

  async loadSupportUsers() {
    return axios
      .get(config.API.USERS.SUPPORT)
      .then((response) => {
        this.state.supportUserIds = response.data;
      })
      .catch((error) => {
        handleError($t('Common.User.loadError'), error);
      });
  }

  async createUser(user: API.User.UserCreateRequest) {
    try {
      const result = await axios.post(config.API.USER.BASE, user);
      return result?.data;
    } catch (e) {
      handleError($t('Common.User.createError'), e);
      return null;
    }
  }

  async updateUser(userId: string, updatedUserData: UserUpdateRequest): Promise<void> {
    try {
      // find object in state and update it
      const user = this.state.users.find((f) => f.user_id === userId)!;
      const tenantId = authService.state.data?.tenant.id;

      user.role = updatedUserData.role ?? user.role;
      if (updatedUserData.profession && tenantId) {
        user.userDetails.professions[tenantId] = updatedUserData.profession;
      }
      user.userDetails.billing_status = updatedUserData.billingStatus ?? user.userDetails.billing_status;
      user.userDetails.locale = updatedUserData.locale ?? user.userDetails.locale;
      // access groups are divided by comma
      user.accessGroups = updatedUserData.accessGroups ?? user.accessGroups;
      user.name = updatedUserData.username ?? user.name;

      await axios.patch(`${config.API.USERS.BASE}/${userId}`, updatedUserData);
    } catch (e) {
      handleError($t('Common.User.updateError'), e);
    }
  }

  async updateAuthorizedWorkspace(userId: string, authorizedTenant: TenantAuthorizationRequest) {
    try {
      const user = this.state.users.find((f) => f.user_id === userId)!;
      user.role = authorizedTenant.role ?? user.role;

      await axios.patch(`${config.API.USERS.AUTHORIZE}`.replace('{id}', userId), authorizedTenant);
      return null;
    } catch (e) {
      handleError($t('Common.User.updateError'), e);
      return null;
    }
  }

  async updateUserLocale(userId: string, locale: Locale) {
    try {
      await axios.post(`${config.API.USERS.BASE}/${userId}/locale/${locale.toString()}`);
    } catch (e) {
      handleError($t('Common.changesSaveError'), e);
    }
  }

  async deleteUser(userId: string) {
    return axios
      .delete(`${config.API.USERS.BASE}/${userId}`)
      .then((response) => {
        this.state.users = response.data;
      })
      .catch((error) => {
        handleError($t('Common.User.deleteError'), error);
      });
  }

  async unauthorizeUser(userId: string, tenantId: string) {
    return axios
      .patch(config.API.USERS.UNAUTHORIZE.replace('{id}', userId).replace('{tenantId}', tenantId))
      .then((response) => {
        this.state.users = response.data;
      })
      .catch((error) => {
        handleError($t('Common.User.unauthorizeError'), error);
      });
  }

  async resetMFA(userId: string) {
    return axios.patch(config.API.USERS.RESET_MFA.replace('{id}', userId)).catch((error) => {
      handleError($t('Common.User.MFAResetError'), error);
    });
  }

  async evictCache() {
    return axios.delete(config.API.USERS.CACHE).catch((error) => {
      handleError($t('Common.User.loadError'), error);
    });
  }

  /** * HELPERS ** */

  getUser(userId: string | SpecialUserId | undefined | null): User | null {
    if (!userId) {
      return null;
    }

    // 1 - worksapce user from user directory
    const foundInDirectory = this.state.users.find((u) => u.user_id === userId);
    if (foundInDirectory) {
      return {
        ...foundInDirectory,
        avatar: {
          type: 'initials',
          content: this.extractInitials(foundInDirectory.name),
          color: this.getAvatarColor(userId),
        },
      };
    }

    // 2 - legal-i support
    if (this.isSupport(userId) || userId === 'support') {
      return {
        user_id: userId,
        name: 'legal-i Support',
        role: 'legali:admin',
        avatar: {
          type: 'initials',
          content: 'LS',
          color: this.getAvatarColor(userId),
        },
      };
    }

    // 3 - special cases

    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'system') {
      return {
        user_id: userId,
        name: 'System',
        avatar: {
          type: 'icon',
          content: 'mdi-chip',
          color: this.getAvatarColor(userId),
        },
      };
    }

    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'anonymous') {
      return {
        user_id: userId,
        name: 'Anonymous',
        avatar: {
          type: 'icon',
          content: 'mdi-incognito',
          color: this.getAvatarColor(userId),
        },
      };
    }

    if (userId === 'pipeline') {
      return {
        user_id: userId,
        name: 'Pipeline',
        avatar: {
          type: 'icon',
          content: 'mdi-pipe',
          color: this.getAvatarColor(userId),
        },
      };
    }

    // NOTE(dp): no proof of usage found, remove?
    if (userId === 'guest') {
      return {
        user_id: userId,
        name: 'Guest',
        avatar: {
          type: 'icon',
          content: 'mdi-account-circle-outline',
          color: this.getAvatarColor(userId),
        },
      };
    }

    // agent
    // example id: wHYknhCM17BG3xaRtbrekBWFQbSifeLU
    if (userId.length === 32) {
      return {
        user_id: userId,
        name: 'Agent',
        avatar: {
          type: 'icon',
          content: 'mdi-hat-fedora',
          color: this.getAvatarColor(userId),
        },
      };
    }

    return {
      user_id: 'unknown',
      name: $t('Common.User.deletedUser') + (authService.hasPermission('LEGALI_ADMIN') && !!userId ? ` (${userId})` : ''),
      avatar: {
        type: 'icon',
        content: 'mdi-account-circle-outline',
        color: this.getAvatarColor(userId),
      },
    };
  }

  getFullName(userId?: string | SpecialUserId | null, manualFallbackForUnknownUser?: string): string {
    if (!userId) {
      return manualFallbackForUnknownUser ?? $t('Common.User.deletedUser');
    }

    const user = this.getUser(userId)!;
    return user.user_id !== 'unknown' || !manualFallbackForUnknownUser ? user.name : manualFallbackForUnknownUser;
  }

  getOrganisationUserId(homeTenantId: UUID) {
    return this.state.users.find((user) => user.homeTenantId === homeTenantId && user.role === 'external:organisation')?.user_id;
  }

  getUsers() {
    return this.state.users;
  }

  getSupports() {
    return this.state.supportUserIds;
  }

  getMyself(): User {
    return this.getUser(authService.state.userId!)!;
  }

  getAvatarColor(userId: string | SpecialUserId) {
    if (!userId) {
      return 'primary';
    }
    // NOTE(mba): we need to add this magic token, otherwise Kusi's color looks like shit, literally.
    return this.colorHash.hex(`${userId} 62h`);
  }

  extractInitials(name: string) {
    const words = name.match(/(?<=\s|^)[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+/g) || [];

    if (words.length === 0) {
      return '';
    }

    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }

    const firstInitial = words[0]![0].toUpperCase();
    const lastInitial = words[words.length - 1][0].toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  isSupport(userId?: string | null) {
    return userId && this.getSupports().some((adminId) => adminId === userId);
  }
}

export default new UserService();
export const UserServiceClass = UserService;
