import { cloneDeep } from 'lodash';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';
import { reactive } from 'vue';

import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import { docPilotService } from '@/case-detail/subviews/copilot/services/copilot.service';
import extractAnnotationService from '@/case-detail/subviews/document/annotations/services/extract.annotation.service.js';
import pdftronAnnotationService from '@/case-detail/subviews/document/annotations/services/pdftron.annotation.service';
import userAnnotationService from '@/case-detail/subviews/document/annotations/services/user.annotation.service';
import { isDiagnosisCodeMatchesFilter } from '@/case-detail/subviews/document/services/document.helper';
import documentService from '@/case-detail/subviews/document/services/document.service';
import { pdftronHelper, ViewMode } from '@/case-detail/subviews/document/services/pdftron.helper';
import { pdftronUiHelper } from '@/case-detail/subviews/document/services/pdftron-ui.helper';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import { mergeIntoReactive } from '@/common/services/common.utils';
import logger from '@/common/services/logging';

const initialState = {
  document: { id: '' },

  // annotations
  annotationKeyPrefix: 'USER_',
  annotationIndex: -1,
  annotationFilter: {
    userAnnotationKeys: [],
    diagnosis: {
      code: null,
      tags: [],
    },
  },

  // search
  searchKeywords: [],
  searchResults: [],
  searchResultIndex: -1,

  // iteration
  iterationTarget: '',

  ready: false,
  queuedDocument: { id: '' },

  // extract
  showDiagnosisAddDialog: false,
  showDiagnosisEditDialog: false,
  selectedExtract: null,
};

class ViewerService {
  constructor() {
    this.state = reactive(cloneDeep(initialState));
    this.tempAnnotationOptions = [];
  }

  // GETTERS

  getSearchResults() {
    return this.state.searchResults;
  }

  getIterationTarget() {
    return this.state.iterationTarget;
  }

  getDocument() {
    return this.state.document;
  }

  getDocumentId() {
    return this.state.document.id;
  }

  getQueuedDocument() {
    return this.state.queuedDocument;
  }

  // SETTERS & ACTIONS

  setDocument(document) {
    // partially reset state
    const originalState = {
      ...cloneDeep(initialState),
      ready: true,
      annotationFilter: this.state.annotationFilter,
    };
    mergeIntoReactive(this.state, originalState);
    this.state.document = document;
  }

  clearQueuedDocument() {
    Object.assign(this.state.queuedDocument, cloneDeep(initialState.queuedDocument));
  }

  clear() {
    mergeIntoReactive(this.state, cloneDeep(initialState));
  }

  setUserAnnotations(userAnnotations) {
    this.state.document.userAnnotations = userAnnotations;
  }

  setReady(ready) {
    this.state.ready = ready;
  }

  setQueuedDocument(document) {
    this.state.queuedDocument = document;
  }

  // Search
  setSearchResults({ searchResults, pageKeywords }) {
    this.state.pageKeywords = pageKeywords;
    this.state.searchResults = searchResults;
    this.state.searchResultIndex = -1;
  }

  addSearchResults(searchResults) {
    this.state.searchResults.push(...searchResults);
  }

  setSearchResultIndex(newIndex) {
    this.state.searchResultIndex = newIndex;
  }

  // Annotations
  setAnnotationKeyPrefix(prefix) {
    this.state.annotationKeyPrefix = prefix;
  }

  setAnnotationIndex(index) {
    this.state.annotationIndex = index;
  }

  setAnnotationFilter(filter) {
    this.state.annotationFilter = filter;
  }

  // Iteration
  setIterationTarget(iterationTarget) {
    this.state.iterationTarget = iterationTarget;
  }

  // LOAD
  async loadDocument({ document, page, coords, forceShow }) {
    this.tempAnnotationOptions = [];
    if (!this.state.ready) {
      logger.info('SET_DOCUMENT: web viewer is not ready, storing document in the queue.');
      document.tempInitialPage = page;
      this.setQueuedDocument(document);
      return;
    }
    if (
      this.state.document.id === document.id &&
      this.state.queuedDocument.id.length === 0 &&
      page &&
      this.state.document.page === page &&
      !forceShow
    ) {
      logger.info('SET_DOCUMENT: document already shown, ignoring');
      return;
    }

    pdftronAnnotationService.deselectAllAnnotations();

    if (coords) {
      const id = uuidv4();
      const options = {
        annotationKey: 'TEMPORARY_HIGHLIGHT',
        parentId: id,
        page,
        coords,
        opacity: 0.4,
        color: '#ffff00',
      };
      this.tempAnnotationOptions.push(options);
    }
    // Clear highlight parameter from URL
    detailViewRouteService.cleanFromQuery('highlight');

    if (pdftronHelper.viewMode === ViewMode.DuplicateReview) {
      if (page === 1) {
        pdftronUiHelper.hideSplitButton();
      } else {
        pdftronUiHelper.showSplitButton();
      }
    } else {
      pdftronUiHelper.hideSplitButton();
    }

    docPilotService.setDocumentViewerState(document.id, page);
    detailViewRouteService.persistInQuery({ initialDocId: document.id, initialPage: page });

    if (this.state.document.id !== document.id) {
      await pdftronHelper.loadDocument(document, page);
      this.setDocument(document);
      if (this.state.queuedDocument.id.length > 0) {
        this.clearQueuedDocument();
      }
    } else {
      pdftronHelper.goToPage(page);
    }
    this.renderAnnotations({ forceRender: true });
  }

  async search({ pageKeywords, iterate }) {
    let doFirstIterate = iterate;
    if (this.state.document.id.length === 0) {
      logger.info('SEARCH: document loaded, ignoring');
      return;
    }
    if (isEqual(pageKeywords, this.state.pageKeywords)) {
      logger.info('SEARCH: already processed, ignoring');
      return;
    }

    this.setSearchResults({
      pageKeywords,
      searchResults: [],
    });

    await pdftronHelper.search(pageKeywords, (searchResults) => {
      for (const searchResult of searchResults) {
        if (!searchResult.page) {
          searchResult.highlightedString = `${searchResult.ambient_str.slice(
            0,
            searchResult.result_str_start,
          )}<strong>${searchResult.result_str}</strong>${searchResult.ambient_str.slice(searchResult.result_str_end)}`;
        } else {
          searchResult.highlightedString = `<strong>${searchResult.keywords.join(' ')}</strong>`;
        }
      }
      this.addSearchResults(searchResults);
      this.setIterationTarget('SEARCH_RESULTS');

      if (doFirstIterate) {
        broadcastEventBus.emit('ITERATE_EVENT', { direction: 1, wrap: true });
        doFirstIterate = false;
      }
    });
  }

  // direction: +/- 1
  async searchIterate({ direction, wrap }) {
    if (this.state.document.id.length === 0) {
      logger.info('SEARCH_ITERATE: document not loaded, ignoring');
      return;
    }
    if (this.state.searchResults.length === 0) {
      logger.info('SEARCH_ITERATE: search results not loaded, ignoring');
      return;
    }

    let newIndex = this.state.searchResultIndex + direction;
    if (newIndex < 0) {
      if (!wrap) {
        // Select previous document
        await documentService.prevDocument();
        return;
      }
      // Wrap
      newIndex = this.state.searchResults.length - 1;
    } else if (newIndex === this.state.searchResults.length) {
      if (!wrap) {
        // Select next document
        await documentService.nextDocument();
        return;
      }
      // Wrap
      newIndex = 0;
    }
    pdftronHelper.jumpToSearchResult(this.state.searchResults[newIndex]);
    this.setSearchResultIndex(newIndex);
  }

  goToSearchResult({ document, index }) {
    if (this.state.searchResults.length === 0) {
      logger.info('goToSearchResult: search results not loaded, ignoring');
      return;
    }
    if (this.state.document.id !== document.id) {
      logger.info('goToSearchResult: mismatch in document id, ignoring');
      return;
    }
    if (!this.state.searchResults[index]) {
      logger.info('goToSearchResult: index not found, ignoring');
      return;
    }

    const searchResult = this.state.searchResults[index];
    pdftronHelper.jumpToSearchResult(searchResult);
    this.setSearchResultIndex(index);
  }

  /* ANNOTATIONS */

  /**
   * Renders annotations based on the current state, which can be overridden by the parameters.
   * @param {string} annotationKeyPrefix
   * @param {Object} annotationFilter
   * @param {boolean} forceRender
   */
  async renderAnnotations({ annotationKeyPrefix, annotationFilter, forceRender = false }) {
    logger.info(`renderAnnotations ACTION: ${annotationKeyPrefix}, ${JSON.stringify(annotationFilter)}, ${forceRender}`);

    if (this.state.document.id.length === 0) {
      logger.info('renderAnnotations: document not loaded, ignoring');
      return;
    }

    // do not re-render if not necessary
    if (!forceRender && (!annotationKeyPrefix || (!!annotationKeyPrefix && this.state.annotationKeyPrefix === annotationKeyPrefix))) {
      logger.info('renderAnnotations: no change in annotationKeyPrefix, ignoring');
      return;
    }

    // define state
    if (annotationKeyPrefix && annotationKeyPrefix !== this.state.annotationKeyPrefix) {
      this.setAnnotationKeyPrefix(annotationKeyPrefix);
    }
    if (annotationFilter && !isEqual(annotationFilter, this.state.annotationFilter)) {
      this.setAnnotationFilter(annotationFilter);
    }

    // define annotations to display
    let userAnnotations = [...this.state.document.userAnnotations];
    let diagnoses = [];
    // user annotations
    if (this.state.annotationFilter.userAnnotationKeys.length > 0) {
      userAnnotations = userAnnotations.filter((annotation) => this.state.annotationFilter.userAnnotationKeys.includes(annotation.annotationKey));
    }

    // diagnoses
    if (this.state.annotationKeyPrefix.startsWith('MEDICAL_ICD10')) {
      diagnoses = [...this.state.document.diagnoses];
      if (this.state.annotationFilter.diagnosis.code) {
        diagnoses = diagnoses.filter((diagnosis) => isDiagnosisCodeMatchesFilter(diagnosis.icd10Code, this.state.annotationFilter.diagnosis.code));
      }
      if (this.state.annotationFilter.diagnosis.tags.length > 0) {
        diagnoses = diagnoses.filter((diagnosis) => diagnosis.tags.some((tag) => this.state.annotationFilter.diagnosis.tags.includes(tag)));
      }
    }

    // map diagnoses render annotations
    const diagnosisRenderAnnotations = extractAnnotationService.mapDiagnosisRenderAnnotations(diagnoses);

    const tempAnnotations = await this.convertToAnnotation(this.tempAnnotationOptions);

    const annotations = [
      ...tempAnnotations.map((annotation) => annotation.renderAnnotation),
      ...userAnnotations.map((annotation) => annotation.renderAnnotation),
      ...diagnosisRenderAnnotations,
    ];

    // render user annotations & integration annotations
    await pdftronAnnotationService.render(annotations);

    const { annotationManager } = pdftronAnnotationService.webViewer.Core;
    const selectAnnotations = annotationManager
      .getAnnotationsList()
      .filter((annotation) => annotation.getCustomData('annotation-key') === 'TEMPORARY_HIGHLIGHT');
    annotationManager.selectAnnotations(selectAnnotations);

    if (this.state.document.integrationUserAnnotationsXfdf.length > 0) {
      pdftronAnnotationService.importAnnotationsXfdf(this.state.document.integrationUserAnnotationsXfdf);
    }
  }

  annotationsIterate({ annotationKeyPrefix, annotationQualifications }) {
    const allAnnotations = pdftronAnnotationService.getAnnotations();

    const newAbsoluteIndex = pdftronAnnotationService.getNextAnnotationIndexByPrefix(
      this.state.annotationIndex,
      annotationKeyPrefix,
      annotationQualifications,
    );

    if (newAbsoluteIndex === -1) return;

    pdftronAnnotationService.jumpToAnnotation(allAnnotations[newAbsoluteIndex]);

    this.setAnnotationIndex(newAbsoluteIndex);
  }

  // handle temporary highlights when selected
  handleTemporaryHighlightSelected(annotations, action) {
    // selected
    if (action !== 'selected' || !annotations) return;

    const selectedAnnotation = annotations[0];
    const annotationKey = pdftronAnnotationService.getAnnotationKey(selectedAnnotation);
    if (!annotationKey) return;

    if (annotationKey === 'TEMPORARY_HIGHLIGHT') {
      const elementsToHide = [
        ...userAnnotationService.state.annotationMappings.map((mapping) => mapping.editDataElement),
        ...Object.values(userAnnotationService.DATA_ELEMENTS),
        ...Object.values(extractAnnotationService.DATA_ELEMENTS),
      ];

      pdftronAnnotationService.hideElements(elementsToHide);
    } else {
      const elementsToShow = [...userAnnotationService.DATA_ELEMENTS.COPILOT_EXPLAIN_BUTTON];

      pdftronAnnotationService.showElements(elementsToShow);
    }
  }

  annotationsGoTo({ annotationId }) {
    if (this.state.document.id.length === 0) {
      logger.info('annotationsGoTo: document not loaded, ignoring');
      return;
    }

    const newAbsoluteIndex = pdftronAnnotationService.getAnnotationIndexById(annotationId);
    if (newAbsoluteIndex === -1) {
      logger.info('annotationsGoTo: annotation not found, ignoring');
      return;
    }

    pdftronAnnotationService.jumpToAnnotationById(annotationId);
    this.setAnnotationIndex(newAbsoluteIndex);
  }

  prevPage() {
    pdftronHelper.prevPage();
  }

  nextPage() {
    pdftronHelper.nextPage();
  }

  resetWebViewer({ document, scroll, page } = { document: null, scroll: false, page: 0 }) {
    this.clear();
    this.setReady(true);
    const doc = document ?? this.state.document;
    const forceShow = true;
    broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', { docId: doc.id, page, scroll, forceShow, iterate: false });
  }

  async convertToAnnotation(options) {
    const annotations = [];
    for (const opt of options) {
      const wrappedXfdf = await pdftronAnnotationService.create(opt.annotationKey, opt);
      const annotation = {
        id: opt.parentId,
        annotationKey: opt.annotationKey,
        renderAnnotation: {
          parentId: opt.parentId,
          annotationKey: opt.annotationKey,
          page: opt.page - 1,
          rect: pdftronAnnotationService.getAnnotationRect(wrappedXfdf),
          coords: pdftronAnnotationService.getAnnotationCoords(wrappedXfdf),
          opacity: opt.opacity,
          color: opt.color,
        },
      };
      annotations.push(annotation);
    }
    return annotations;
  }
}

export default new ViewerService();
