import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import searchService, { DocumentSearchResult } from '@/case-detail/search/services/search.service';
import { detailViewRouteService } from '@/case-detail/services/detail.view.route.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import pageReadService from '@/case-detail/subviews/document/services/pageread.service';
import documentFilterService from '@/case-detail/subviews/documents-list/services/filter/document.filter.service';
import { documentFilters } from '@/case-detail/subviews/documents-list/services/filter/document.filters';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import logger from '@/common/services/logging';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export function isToReview(document: Document) {
  return document.metadata.TITLE.value.length === 0 || !document.metadata?.ISSUE_DATE.value;
}

export function isBadOcrQuality(quality: number) {
  return quality >= 0.0 && quality < 0.5;
}

export function isBadOcr(document: Document) {
  if (document?.metadata?.QUALITY.value) {
    const quality = parseFloat(document.metadata.QUALITY.value);
    return isBadOcrQuality(quality);
  }
  return false;
}

// FILTERING & SORTING
export function filterByBackendFilters(document: Document) {
  if (searchService.isDefaultSearchTerm()) {
    return true;
  }

  const ids = searchService.documentIdsFromSearchResults();
  return ids.includes(document.id);
}

export function checkUnseenDocuments() {
  if (!detailViewService.getCurrentLegalCase()) {
    return;
  }

  const docs = Array.from(documentService.getDocuments().values());
  const newDocs = docs.filter((d) => documentFilters.newSinceLastVisit.filterFn(d, true));
  if (newDocs.length) {
    documentFilterService.setFilter('newSinceLastVisit', true);
  }
}

export function isDuplicate(document: Document, documents: Document[]) {
  if (document.duplicates.length > 0) return false;
  for (const d of documents) {
    if (d.id === document.id) return false;
    const found = d.duplicates.find((duplicate) => duplicate.id === document.id);
    if (found) return true;
  }
  return false;
}

// MISC

export async function setActiveDocument(documents: Document[], documentsCache: Map<UUID, Document>) {
  let activeDocument = null;
  let initialPage = 1;

  // 1. priority: document and page provided via url (if present)
  if (detailViewRouteService.getFromQuery('initialDocId')) {
    logger.info('INITIAL DOC: using params');
    activeDocument = documentsCache.get(detailViewRouteService.getFromQuery('initialDocId') as string);
    if (!activeDocument) {
      activeDocument = documents
        .flatMap((document) => document.duplicates)
        .find((document) => document.id === detailViewRouteService.getFromQuery('initialDocId'));
    }
    initialPage = Number(detailViewRouteService.getFromQuery('initialPage')) || 1;

    if (!activeDocument) {
      appService.info($t('CaseDetail.Document.requestedDocumentNotFound'));
    }
  }

  // 2. priority: persisted state from the server (DocumentRead)
  if (!activeDocument && pageReadService.lastVisited) {
    logger.info('INITIAL DOC: using documentread from server');
    activeDocument = documentsCache.get(pageReadService.lastVisited.documentId);
    initialPage = 1; // always jump to first page
  }

  // 3rd priority: use the first doc in the list
  const validDocuments = documents.filter((d) => d.status === 'ACTIVE');
  if (!activeDocument && validDocuments.length !== 0) {
    logger.info('INITIAL DOC: using first document in the list');
    [activeDocument] = validDocuments;
  }

  if (!activeDocument) {
    return;
  }

  broadcastEventBus.emit('DOCUMENT_SELECTED_EVENT', {
    docId: activeDocument.id,
    page: initialPage,
    scroll: true,
    forceShow: false,
    iterate: false,
  });
}

export function processSearchResultsPerDocument(searchResults: Map<UUID, API.Document.SourceFileSearchResult>, documentsCache: Map<UUID, Document>) {
  const searchResultsPerDocument: Record<UUID, DocumentSearchResult> = {};
  for (const [sourceFileId, pageSearchResults] of Object.entries(searchResults)) {
    const allDocumentsForSourceFile = [];
    for (const doc of documentsCache.values()) {
      if (doc.sourceFileId === sourceFileId) {
        allDocumentsForSourceFile.push(doc);
      }
    }

    if (allDocumentsForSourceFile.length > 0) {
      for (const pageSearchResult of pageSearchResults) {
        const document = allDocumentsForSourceFile.find(
          (doc) => doc.sourceFilePage <= pageSearchResult.sourceFilePage && pageSearchResult.sourceFilePage <= doc.sourceFileEndPage,
        );
        if (!document) {
          continue; // we index duplicates, but they are not in documentsCache
        }
        pageSearchResult.page = pageSearchResult.sourceFilePage - document.sourceFilePage + 1;

        if (!searchResultsPerDocument[document.id]) {
          searchResultsPerDocument[document.id] = {
            count: pageSearchResult.count,
            pages: [pageSearchResult],
          };
        } else {
          searchResultsPerDocument[document.id].count += pageSearchResult.count;
          searchResultsPerDocument[document.id].pages.push(pageSearchResult);
        }
      }
    }

    for (const searchResult of Object.values(searchResultsPerDocument)) {
      searchResult.pages.sort((a, b) => a.sourceFilePage - b.sourceFilePage);
    }
  }
  return searchResultsPerDocument;
}

export const isDiagnosisCodeMatchesFilter = (codeToVerify: string, filterCode: string) => {
  if (filterCode.includes('-')) {
    // chapter in key: S00-T98
    const [chapterFrom, chapterTo] = filterCode.split('-');
    return codeToVerify >= chapterFrom && codeToVerify <= chapterTo;
  }
  if (filterCode.includes('.')) {
    // F32.1, strip dot and everything that follows
    const keyWithoutMinor = filterCode.substring(0, filterCode.indexOf('.'));
    return codeToVerify === keyWithoutMinor || codeToVerify === filterCode;
  }
  // simple key: G32
  return codeToVerify === filterCode;
};
