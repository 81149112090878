import axios from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import { API } from '@/common/types/api.types';
import { EmptyObject, UUID } from '@/common/types/common.types';

export const sourceFileClient = {
  async get(legalCaseId: UUID, sourceFileId: UUID) {
    return axios
      .get(config.API.SOURCEFILE_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{sourceFileId}', sourceFileId))
      .then((response) => response.data as API.SourceFile.Response)
      .catch((e) => {
        handleError($t('CaseList.fileLoadError'), e);
        return {} as EmptyObject;
      });
  },

  async getByCaseId(legalCaseId: UUID) {
    return axios
      .get(config.API.SOURCEFILE_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('/{sourceFileId}', ''))
      .then((response) => response.data as API.SourceFile.Response[])
      .catch((e) => {
        handleError($t('CaseList.filesLoadError'), e);
        return [];
      });
  },

  async getDocuments(legalCaseId: UUID, sourceFileId: UUID) {
    return axios
      .get(`${config.API.DOCUMENTS_ENDPOINT.replace('{legalCaseId}', legalCaseId)}/sourcefiles/${sourceFileId}`)
      .then((response) => response.data as API.Document.Response[])
      .catch((e) => {
        handleError($t('CaseDetail.Document.documentsLoadError'), e);
        return [];
      });
  },

  async post(legalCaseId: UUID, sourceFile: API.SourceFile.CreateUpdateRequest) {
    return axios
      .post(config.API.SOURCEFILE_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('/{sourceFileId}', ''), sourceFile)
      .then((response) => response.data as API.SourceFile.Response)
      .catch((e) => {
        handleError($t('CaseList.fileSaveError'), e);
        return {} as EmptyObject;
      });
  },

  async delete(legalCaseId: UUID, sourceFileId: UUID) {
    return axios
      .delete(config.API.SOURCEFILE_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{sourceFileId}', sourceFileId))
      .then(() => {})
      .catch((e) => {
        handleError($t('CaseList.fileDeleteError'), e);
      });
  },

  async patch(legalCaseId: UUID, sourceFileId: UUID, sourceFile: API.SourceFile.CreateUpdateRequest) {
    return axios
      .patch(config.API.SOURCEFILE_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{sourceFileId}', sourceFileId), sourceFile)
      .catch((e) => {
        handleError($t('CaseList.fileUpdateError'), e);
        return {};
      });
  },

  async saveSegmentation(legalCaseId: UUID, sourceFileId: UUID, segmentationMap: Record<number, boolean>, finishTask = false) {
    const extract: API.SourceFile.SegmentationRequest = {
      sourceFileId,
      segmentation: segmentationMap,
      finishTask,
    };

    return axios.put(
      `${config.API.SOURCEFILE_SEGMENTATION_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{sourceFileId}', sourceFileId)}`,
      extract,
    );
  },

  async saveWorkbenchSegmentation(sourceFileId: UUID, segmentationMap: Record<number, boolean>, finishTask = false) {
    const extract = {
      sourceFileId,
      segmentation: segmentationMap,
      finishTask,
    };

    return axios.put(`${config.API.WORKBENCH_SEGMENTATION_ENDPOINT}`, extract);
  },
};
