import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { reactive } from 'vue';
import { createVuetify } from 'vuetify';

import $a from '@/common/services/analytics/analytics';
import { AppErrorKey } from '@/common/services/entity.service';

const SNACKBAR_TIMEOUT_MS = 3000;

type VuetifyInstance = ReturnType<typeof createVuetify>;

interface ServiceState {
  isLoading: boolean;
  errorSnackbarMessage: string | null;
  errorSnackbarTrigger: boolean;
  snackbarMessage: string | null;
  snackbarTrigger: boolean;
  snackbarTimeout: number;
  snackbarUndo: boolean;
  outdated: boolean;
  appError: AppErrorKey | null;
  confirmDialog: boolean;
  confirmQuestion: string | null;
  confirmMessage: string | null;
  confirmBtnTxt: string;
  confirmBtnColor: string;
  confirmValidation: string | null;
  confirmBtnFn: (() => void) | null;
  confirmCancelBtnFn: (() => void) | null;
  altBtnTxt: string | null;
  altBtnFn: (() => void) | null;
  altBtnColor: string;
  contextId: string | null;

  // branding
  brandingLogo: string;

  dynamicTitle: {
    currentRouteName: string;
    currentTitleParts: string[];
  };
}

class AppService {
  state: ServiceState;

  undoFn: (() => void) | null;

  vuetifyInstance: VuetifyInstance | null;

  delayedAction: {
    fn: () => void;
    timeout: ReturnType<typeof setTimeout>;
  } | null;

  constructor() {
    this.state = reactive({
      isLoading: false,
      errorSnackbarMessage: null,
      errorSnackbarTrigger: false,
      snackbarMessage: null,
      snackbarTrigger: false,
      snackbarTimeout: SNACKBAR_TIMEOUT_MS,
      snackbarUndo: false,
      outdated: false,
      appError: null,
      confirmDialog: false,
      confirmQuestion: null,
      confirmMessage: null,
      confirmBtnTxt: '',
      confirmBtnColor: 'primary',
      confirmValidation: null,
      confirmBtnFn: null,
      confirmCancelBtnFn: null,
      altBtnTxt: null,
      altBtnFn: null,
      altBtnColor: 'primary',
      contextId: null,

      // branding
      brandingLogo: '',

      dynamicTitle: {
        currentRouteName: '',
        currentTitleParts: ['legal-i'],
      },
    });

    this.undoFn = null;
    this.vuetifyInstance = null;
    this.delayedAction = null;
  }

  setTitle(routeName: string, titleParts: string[]) {
    // only updat eon new route or more detailed title after some fetches
    if (this.state.dynamicTitle.currentRouteName !== routeName || this.state.dynamicTitle.currentTitleParts.length - 1 < titleParts.length) {
      this.state.dynamicTitle.currentRouteName = routeName;
      this.state.dynamicTitle.currentTitleParts = [...titleParts, 'legal-i'];
      document.title = this.state.dynamicTitle.currentTitleParts.join(' - ');
    }
  }

  setVuetifyInstance(instance: VuetifyInstance) {
    this.vuetifyInstance = instance;
  }

  setBranding(logoUrl: string, primaryColor: string, secondaryColor: string) {
    if (this.vuetifyInstance && !!primaryColor) {
      // @ts-expect-error Ref (themes) is accessible outside without .value
      this.vuetifyInstance.theme.themes.light.colors.primary = primaryColor;
      // @ts-expect-error same
      this.vuetifyInstance.theme.themes.light.colors.secondary = secondaryColor;
      // @ts-expect-error same
      this.vuetifyInstance.theme.themes.light.colors.anchor = primaryColor;
    }
    this.state.brandingLogo = logoUrl;
  }

  setContextId(contextId: string) {
    this.state.contextId = contextId;
  }

  isLoading() {
    return this.state.isLoading;
  }

  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  error(message: string) {
    this.state.errorSnackbarTrigger = true;
    this.state.errorSnackbarMessage = message;
    $a.l($a.e.APP_ERROR, { message });
  }

  info(message: string, undoFn: (() => void) | null = null) {
    if (undoFn) {
      this.undoFn = undoFn;
      this.state.snackbarUndo = true;
      this.state.snackbarTimeout = SNACKBAR_TIMEOUT_MS;
    } else {
      this.undoFn = null;
      this.state.snackbarUndo = false;
      this.state.snackbarTimeout = SNACKBAR_TIMEOUT_MS;
    }
    this.state.snackbarTrigger = true;
    this.state.snackbarMessage = message;
  }

  infoWithDelayedAction(message: string, fn: () => void, undoFn: () => void) {
    if (this.delayedAction) {
      clearTimeout(this.delayedAction.timeout);
      this.delayedAction = null;
    }

    this.delayedAction = {
      fn,
      timeout: setTimeout(() => {
        if (this.delayedAction) {
          this.delayedAction.fn();
        }
      }, SNACKBAR_TIMEOUT_MS),
    };

    this.info(message, undoFn);
  }

  undo() {
    this.state.snackbarTrigger = false;
    if (this.undoFn) {
      this.undoFn();
    }
    this.undoFn = null;

    if (this.delayedAction) {
      clearTimeout(this.delayedAction.timeout);
      this.delayedAction = null;
    }
  }

  close() {
    this.state.snackbarTrigger = false;
  }

  outdated() {
    this.state.outdated = true;
    this.setLoading(false);
  }

  offline() {
    this.appError('FATAL');
  }

  fatal() {
    this.appError('FATAL');
  }

  appError(errorType: AppErrorKey | null) {
    if (errorType) {
      this.state.appError = errorType;
      this.setLoading(false);
    }
  }

  clearAppError() {
    this.state.appError = null;
  }

  accessDenied(response?: AxiosResponse) {
    if (response && response.data.validUntil && dayjs(response.data.validUntil).isBefore(dayjs())) {
      this.appError('ACCESS_EXPIRED');
      return;
    }
    this.appError('ACCESS_DENIED');
  }

  confirm(
    question: string,
    message: string,
    confirmBtnTxt: string,
    confirmBtnFn: (() => void) | null = null,
    confirmBtnColor = 'primary',
    confirmValidation = '',
    confirmCancelBtnFn = () => {},
    altBtnTxt = '',
    altBtnFn: (() => void) | null = null,
    altBtnColor = 'secondary',
  ) {
    this.state.confirmDialog = true;
    this.state.confirmQuestion = question;
    this.state.confirmMessage = message;
    this.state.confirmBtnTxt = confirmBtnTxt;
    this.state.confirmBtnFn = confirmBtnFn;
    this.state.confirmBtnColor = confirmBtnColor;
    this.state.confirmCancelBtnFn = confirmCancelBtnFn;
    this.state.confirmValidation = confirmValidation;
    this.state.altBtnTxt = altBtnTxt;
    this.state.altBtnFn = altBtnFn;
    this.state.altBtnColor = altBtnColor;
  }
}

export default new AppService();
