<template>
  <v-chip
    v-if="!!value"
    v-tippy="$t('App.Bar.CaseDetailIndicator.copyTooltip', [label])"
    class="mr-1"
    color="gray"
    variant="outlined"
    size="small"
    label
    @click="copy()"
  >
    <div class="text-truncate chip-quick-access">
      {{ value }}
    </div>
    <v-icon size="small" end> mdi-content-copy </v-icon>
  </v-chip>
</template>

<script>
import appService from '@/app/services/app.service';

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    copy() {
      this.$copyText(this.value);
      appService.info(this.$t('Common.CopyableText.copiedToClipboard'));
      this.$a.l(this.$a.e.CASE_PIIQUICKACCESS_COPY, { label: this.label });
    },
  },
};
</script>

<style scoped>
.chip-quick-access {
  max-width: 200px;
}
</style>
