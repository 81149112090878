/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { reactive, watch } from 'vue';
import { createI18n } from 'vue-i18n';

import { apexChartLocales, setupApexChart } from '@/app/apexchart';
import { config } from '@/app/config';
import { Locale, SUPPORTED_LOCALES } from '@/app/i18n/locale';
import de from '@/app/i18n/translations/de.json';
import en from '@/app/i18n/translations/en.json';
import fr from '@/app/i18n/translations/fr.json';
import it from '@/app/i18n/translations/it.json';
import logger from '@/common/services/logging';

class I18nService {
  state: {
    locale: Locale;
    fallbackLocale: Locale;
    initialized: boolean; // becomes `true` on first setLocale() call (means, UI received target user locale)
  };

  vueI18n: ReturnType<typeof createI18n>;

  constructor() {
    this.state = reactive({
      locale: Locale.default,
      fallbackLocale: Locale.default,
      initialized: false,
    });

    setupApexChart(this.selectFromLocalizedObject(apexChartLocales));

    this.vueI18n = createI18n({
      locale: this.state.locale.toString(),
      fallbackLocale: this.state.fallbackLocale.toString(),
      warnHtmlInMessage: 'off', // NOTE(dp): change it to `warnHtmlMessage: false` if move Vue Composition API
      messages: {
        de,
        en,
        fr,
        it,
      },
      // fallbacking 'de-CH' locale to 'de' messages is expected behavior for us, suppress the warnings
      silentTranslationWarn: true,
      silentFallbackWarn: true,
    });

    this.runAfterLocaleInit(() => {
      // @ts-expect-error we know it's there
      if (config.INTERCOM.ENABLED && window.Intercom) {
        // @ts-expect-error we know it's there
        window.Intercom('update', { language_override: this.state.locale.language });
        // List of supported languages: https://www.intercom.com/help/en/articles/180-localize-intercom-to-work-with-multiple-languages#:~:text=a%20week%20ago-,Supported%20languages,-Arabic%2C%20Bengali%2C%20Bosnian
      }
    });
  }

  setLocale(locale: Locale, vuetify: unknown) {
    if (!this.state.initialized) {
      this.state.initialized = true;
    }

    locale = locale.closestSupported();
    this.state.locale = locale; // service internal
    this.vueI18n.global.locale = locale.toString(); // vue app
    setupApexChart(this.selectFromLocalizedObject(apexChartLocales));

    // Vuetify
    // https://vuetifyjs.com/en/features/internationalization/#supported-languages
    // @ts-expect-error: vuetify doesn't seem to have types for ts
    vuetify.locale.current = locale.language; // de, en, fr, it - are supported

    // Dayjs
    dayjs.locale(locale.toString());
  }

  runAfterLocaleInit(fn: () => void) {
    if (this.state.initialized) {
      fn();
      return;
    }

    const unwatch = watch(
      () => this.state.initialized,
      (to) => {
        if (to) {
          fn();
          unwatch();
        }
      },
    );
  }

  supportedLocales(): Locale[] {
    return SUPPORTED_LOCALES.map((localeString) => Locale.fromString(localeString));
  }

  availableLocales(): Locale[] {
    return SUPPORTED_LOCALES.map((l) => Locale.fromString(l));
  }

  selectFromLocalizedObject(messages: Record<string, any>) {
    const messageKeys = Object.keys(messages);

    const targetLocaleKey = this.state.locale.toString();
    const targetLanguageKey = this.state.locale.language;

    const fallbackLocaleKey = this.state.fallbackLocale.toString();
    const fallbackLanguageKey = this.state.fallbackLocale.language;

    if (messageKeys.includes(targetLocaleKey)) {
      return messages[targetLocaleKey];
    }
    if (messageKeys.includes(targetLanguageKey)) {
      return messages[targetLanguageKey];
    }
    if (messageKeys.includes(fallbackLocaleKey)) {
      return messages[fallbackLocaleKey];
    }
    if (messageKeys.includes(fallbackLanguageKey)) {
      return messages[fallbackLanguageKey];
    }

    logger.error(
      `No translation found for the message object. Target locale: ${targetLocaleKey}. Fallback locale: ${fallbackLocaleKey}. Messages: ${JSON.stringify(messages)}`,
    );
    return Object.values(messages)[0];
  }
}

export const i18nService = new I18nService();

export const $t = (...params: any[]) => {
  if (typeof params[0] === 'string') {
    // regular vue-i18n translation, pass through
    // @ts-expect-error: i18n typing is tricky
    return i18nService.vueI18n.global.t(...params);
  }

  // custom messages object
  const messages = params[0];
  return i18nService.selectFromLocalizedObject(messages);
};

// @ts-expect-error: i18n typing is tricky
export const $te = (...params: any[]) => i18nService.vueI18n.global.te(...params);
