import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import detailViewService from '@/case-detail/services/detail.view.service';
import documentService, { Document } from '@/case-detail/subviews/document/services/document.service';
import { UUID } from '@/common/types/common.types';

const seenAfterSeconds = 2 * 1000;

const FULLY_READ = 'fully_read';
const PARTIALLY_READ = 'partially_read';
const UN_READ = 'un_read';

interface DocumentReadInfo {
  read: boolean;
  pages: number[];
}

interface LastDocumentReadInfo extends DocumentReadInfo {
  last: true;
  ts: number;
  documentId: UUID;
}

interface ServiceState {
  documentReadChangeTracker: number;
  documentRead: Map<UUID, DocumentReadInfo>;
}

class PageReadService {
  state: ServiceState;

  timeout: ReturnType<typeof setTimeout> | null;

  readLoaded: boolean;

  queue: { document: Document; page: number }[];

  lastVisited: null | LastDocumentReadInfo;

  constructor() {
    this.state = reactive({
      documentReadChangeTracker: 1,
      documentRead: new Map(),
    });

    this.timeout = null;
    this.readLoaded = false;
    this.queue = [];
    this.lastVisited = null;
  }

  getDocumentRead(document: Document) {
    return this.state.documentReadChangeTracker ? this.state.documentRead.get(document.id) : null;
  }

  logRead(document: Document, page: number) {
    // this is called from the initial document after opening the case
    // at this point the read data might not be loaded from the server yet
    // queue the request and try again after the data is loaded
    if (!this.readLoaded) {
      this.queue.push({ document, page });
      return;
    }

    // ignore invalid requests
    if (!document?.id) {
      return;
    }

    // cancel previous request
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // ignore if document is already read
    const documentRead = this.getDocumentRead(document);
    if (documentRead?.read) {
      return;
    }

    // if last page report read
    if (document.pageCount === page) {
      this.reportToServer([document], { read: true }, false);
    } else if (!documentRead?.pages?.includes(page)) {
      this.timeout = setTimeout(() => this.reportToServer([document], { page }, false), seenAfterSeconds);
    }
  }

  async readRead(caseId: UUID) {
    this.readLoaded = false;

    const response = await axios.get(config.API.DOCUMENTS_READ_READ.replace('{legalCaseId}', caseId)).catch((e) => {
      handleError($t('CaseDetail.Document.documentReadLoadError'), e, true);
      return null;
    });

    this.lastVisited = null;
    this.state.documentRead.clear();
    if (response?.data) {
      for (const r of response.data) {
        if (!r.last) {
          this.state.documentRead.set(r.documentId, {
            read: r.read,
            pages: r.pages ?? [],
          });
        } else {
          this.lastVisited = r;
        }
      }
    }
    this.state.documentReadChangeTracker += 1;
    this.readLoaded = true;

    for (const q of this.queue) {
      this.logRead(q.document, q.page);
    }
    this.queue = [];
  }

  async reportToServer(documents: Document[], body: { read?: boolean; page?: number }, showSnackbar = true) {
    this.timeout = null;

    if (documents.length > 1) {
      // report several documents at once
      const caseId = detailViewService.getCurrentLegalCaseId();

      await axios
        .patch(config.API.DOCUMENTS_UPDATE_ALL_READ.replace('{legalCaseId}', caseId), { ...body, documentIds: documents.map((d) => d.id) })
        .catch((e) => {
          handleError($t('CaseDetail.Document.documentsMarkReadUnreadError'), e, true);
          return null;
        });

      documents.forEach((document) => {
        const documentRead = this.getDocumentRead(document);

        if (documentRead) {
          documentRead.read = body.read ?? false;
          if (body.read === true) {
            documentRead.pages = [];
          }
        } else {
          this.state.documentRead.set(document.id, {
            read: body.read ?? false,
            pages: [],
          });
        }
      });
    } else {
      const document = documents[0];
      // report single document
      await axios
        .patch(config.API.DOCUMENTS_UPDATE_READ.replace('{legalCaseId}', document.caseId).replace('{documentId}', document.id), body)
        .catch((e) => {
          handleError($t('CaseDetail.Document.documentMarkReadUnreadError'), e, true);
          return null;
        });

      const documentRead = this.getDocumentRead(document);
      if (documentRead) {
        if (body.page) {
          const currentReadPages = documentRead.pages ?? [];
          documentRead.pages = [...new Set([...currentReadPages, body.page])];
        } else {
          documentRead.read = body.read ?? false;
          if (body.read === true) {
            documentRead.pages = [];
          }
        }
      } else {
        this.state.documentRead.set(document.id, {
          read: body.read ?? false,
          pages: body.page ? [body.page] : [],
        });
      }
    }

    this.state.documentReadChangeTracker += 1;

    if (showSnackbar) {
      if (body.read === true) {
        appService.info($t('CaseDetail.Document.documentMarkedRead'));
      } else if (body.read === false) {
        appService.info($t('CaseDetail.Document.documentMarkedUnread'));
      }
    }
  }

  /* if FULLY_READ -> UN_READ */
  /* if PARTIALLY_READ or UN_READ ->  FULLY_READ */
  toggleRead(document: Document) {
    // cancel any auto-read requests
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.readState(document) === FULLY_READ) {
      this.reportToServer([document], { read: false });
    } else {
      this.reportToServer([document], { read: true });
    }
  }

  toggleAllRead() {
    // cancel any auto-read requests
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const readStatus = this.allReadState();
    const filteredDocuments = documentService.getFilteredDocuments();

    if (readStatus === FULLY_READ) {
      this.reportToServer(filteredDocuments, { read: false });
    } else {
      this.reportToServer(filteredDocuments, { read: true });
    }
  }

  markAllRead() {
    // cancel any auto-read requests
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const filteredDocuments = documentService.getFilteredDocuments();
    this.reportToServer(filteredDocuments, { read: true });
  }

  readState(document: Document) {
    const documentRead = this.getDocumentRead(document);
    if (documentRead?.read) {
      return FULLY_READ;
    }

    if (documentRead && documentRead.pages.length > 0) {
      return PARTIALLY_READ;
    }

    return UN_READ;
  }

  allReadState() {
    const filteredDocuments = documentService.getFilteredDocuments();
    const fullyReadCount = filteredDocuments.filter((d) => this.readState(d) === FULLY_READ).length;
    const unReadCount = filteredDocuments.filter((d) => this.readState(d) === UN_READ).length;

    if (fullyReadCount === filteredDocuments.length) {
      return FULLY_READ;
    }
    if (unReadCount === filteredDocuments.length) {
      return UN_READ;
    }
    return PARTIALLY_READ;
  }

  getPagesReadCount(document: Document) {
    const documentRead = this.getDocumentRead(document);
    if (documentRead && !documentRead.read) {
      const numberOfReadPages = documentRead.pages?.length;
      return numberOfReadPages ?? 0;
    }
    return null;
  }

  getFirstUnreadPage(document: Document) {
    const documentRead = this.getDocumentRead(document);
    if (documentRead && documentRead.pages.length > 0) {
      for (let i = 1; i <= document.pageCount; i++) {
        if (!documentRead.pages.includes(i)) {
          return i;
        }
      }
    }
    return -1;
  }

  isUnread(document: Document) {
    return this.readState(document) === UN_READ;
  }

  isAllUnread() {
    return this.allReadState() === UN_READ;
  }

  isFullyRead(document: Document) {
    return this.readState(document) === FULLY_READ;
  }

  isAllRead() {
    return this.allReadState() === FULLY_READ;
  }

  isPartiallyRead(document: Document) {
    return this.readState(document) === PARTIALLY_READ;
  }

  noOfUnread(documents: Document[]) {
    return documents.filter((d) => this.isUnread(d)).length;
  }
}

export default new PageReadService();
