import de from 'apexcharts/dist/locales/de.json';
import en from 'apexcharts/dist/locales/en.json';
import fr from 'apexcharts/dist/locales/fr.json';
import it from 'apexcharts/dist/locales/it.json';
import { cloneDeep } from 'lodash';

const deCH = {
  ...cloneDeep(de),
  name: 'de', // in our app 'de-CH' considered as default 'de'
};
// localize ß to Swiss variant
deCH.options.toolbar.selectionZoom = 'Auswahl vergrössern';
deCH.options.toolbar.zoomIn = 'Vergrössern';

const deDE = {
  ...cloneDeep(de),
  name: 'de-DE', // in our app classic german considered as 'de-DE'
};

export const apexChartLocales = { de: deCH, en, fr, it, 'de-DE': deDE };

export const setupApexChart = (locale: typeof de) => {
  // @ts-expect-error the way to do it regarding to docs
  // eslint-disable-next-line no-undef
  if (typeof Apex !== 'undefined') {
    // @ts-expect-error the way to do it regarding to docs
    // eslint-disable-next-line no-undef
    Apex.chart = {
      locales: Object.values(apexChartLocales),
      defaultLocale: locale.name,
    };
  }
};
