import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import router from '@/app/router';
import appService from '@/app/services/app.service';
import $a from '@/common/services/analytics/analytics';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import logger from '@/common/services/logging';
import preferencesService from '@/common/services/preferences.service';

interface NotificationResponse {
  userId: string;
  requestorUserId: string;
  notificationId: string;
  status: 'UNREAD' | 'READ';
  /** @format int64 */
  ttl: number;
  message: string;
  actionButtonLink: string;
  actionButtonText: string;
  actionEvent: 'SHOW_OWN_INACTIVE_CASES';
  /** @format int64 */
  created: number;
}

class NotificationService {
  public state: { notifications: NotificationResponse[] };

  constructor() {
    this.state = reactive({
      notifications: [],
    });
  }

  noOfUnreadNotifications() {
    return this.state.notifications.filter((n) => n.status === 'UNREAD').length;
  }

  async loadNotifications() {
    try {
      const response = await axios.get(`${config.API.NOTIFICATIONS_ENDPOINT.ROOT}/`);
      const { data } = response;

      if (data.length !== this.state.notifications.length) {
        // only update internal state if there are new notifications
        this.state.notifications = data;
        this.sort();
      }
    } catch (e) {
      this.state.notifications = [];
      handleError($t('App.Bar.Notifications.notificationsLoadError'), e as Error);
    }
  }

  getShowOnlyUnread() {
    return preferencesService.state.notificationPreferences.showOnlyUnread;
  }

  setShowOnlyUnread(flag: boolean) {
    preferencesService.updatePreferences({ notificationPreferences: { showOnlyUnread: flag } });
  }

  visibleNotifications() {
    const onlyUnread = preferencesService.state.notificationPreferences.showOnlyUnread;
    return onlyUnread ? this.state.notifications.filter((n) => n.status === 'UNREAD') : this.state.notifications;
  }

  sort() {
    this.state.notifications.sort((a, b) => b.created - a.created);
  }

  async handleClick(notification: NotificationResponse) {
    $a.l($a.e.APP_NOTIFICATION_OPEN);
    const href = notification.actionButtonLink;

    if (notification.status === 'UNREAD') {
      await this.toggleRead(notification);
    }

    const tenantPos = href.indexOf('/c/');

    if (!tenantPos) {
      // something external
      window.open(href, '_blank');
    }

    const substringFromTenant = href.substring(tenantPos + 3);
    const tenantCanonicalNameMatch = substringFromTenant.match(/[a-z-]+/);

    if (!tenantCanonicalNameMatch) {
      // something strange
      logger.warn(`Could not extract tenantCanonicalName from href: ${href}`);
      window.open(href, '_blank');
      return;
    }

    const tenantCanonicalId = tenantCanonicalNameMatch[0];

    const tenant = authService.state.data?.tenant;
    if (tenantCanonicalId !== tenant?.canonicalName) {
      // different tenant
      window.open(href, '_blank');
    }
    const path = href.substring(href.indexOf('/c/'));
    router.push(path);
    if (notification.actionEvent) {
      if (notification.actionEvent === 'SHOW_OWN_INACTIVE_CASES') {
        broadcastEventBus.emit('SHOW_OWN_INACTIVE_CASES_EVENT', {});
      }
    }
  }

  async markAllAsRead() {
    $a.l($a.e.APP_NOTIFICATION_READ_ALL);
    axios
      .patch(`${config.API.NOTIFICATIONS_ENDPOINT.ROOT}/all/READ`)
      .then(() => {
        appService.info($t('App.Bar.Notifications.allMarkedAsRead'));
        this.state.notifications.forEach((n) => (n.status = 'READ'));
      })
      .catch((e) => {
        handleError($t('App.Bar.Notifications.notificationsUpdateError'), e);
      });
  }

  async toggleRead(notification: NotificationResponse) {
    const newStatus = notification.status === 'READ' ? 'UNREAD' : 'READ';
    if (newStatus === 'READ') {
      $a.l($a.e.APP_NOTIFICATION_READ);
    }

    axios
      .patch(`${config.API.NOTIFICATIONS_ENDPOINT.ROOT}/${notification.notificationId}/${newStatus}`)
      .then(() => {
        notification.status = newStatus;
        appService.info(newStatus === 'READ' ? $t('App.Bar.Notifications.markedAsRead') : $t('App.Bar.Notifications.markedAsUnread'));

        if (preferencesService.state.notificationPreferences.showOnlyUnread) {
          this.state.notifications = [...this.state.notifications.filter((n) => n.notificationId !== notification.notificationId)];
        }
      })
      .catch((e) => {
        handleError($t('App.Bar.Notifications.notificationUpdateError'), e);
      });
  }
}

export default new NotificationService();
export const NotificationServiceClass = NotificationService;
