<template>
  <l-menu v-model="menu" activator="#current-case" origin="overlap" transition="slide-x-transition">
    <v-card class="bg-surface d-flex card pa-2 elevation-1">
      <v-btn v-blur v-tippy="$t('App.Bar.CaseDetailIndicator.closeTooltip')" icon="mdi-close" density="compact" @click="menu = false" />

      <div class="flex-grow-1 ml-2">
        <PiiQuickAccessChip
          :value="currentCasePii.PII_FIRSTNAME.value + ' ' + currentCasePii.PII_LASTNAME.value"
          :label="$t('App.Bar.CaseDetailIndicator.nameFieldLabel')"
        />
        <PiiQuickAccessChip :value="currentCasePii.PII_COMPANY.value" :label="$t('App.Bar.CaseDetailIndicator.customerCompanyFieldLabel')" />
        <PiiQuickAccessChip :value="currentCase.reference" :label="$t('App.Bar.CaseDetailIndicator.referenceFieldLabel')" />
        <PiiQuickAccessChip :value="currentCasePii.CUSTOM_1.value" :label="$t('App.Bar.CaseDetailIndicator.customFieldLabel')" />
        <PiiQuickAccessChip :value="currentCasePii.CUSTOM_2.value" :label="$t('App.Bar.CaseDetailIndicator.customFieldLabel')" />
        <PiiQuickAccessChip :value="currentCasePii.CUSTOM_3.value" :label="$t('App.Bar.CaseDetailIndicator.customFieldLabel')" />
        <PiiQuickAccessChip :value="piiFormattedBirthDate" :label="$t('App.Bar.CaseDetailIndicator.birthdateFieldLabel')" />
        <PiiQuickAccessChip :value="currentCasePii.PII_AHV_NR.value" :label="$t('App.Bar.CaseDetailIndicator.ahvNumberFieldLabel')" />
        <PiiQuickAccessChip :value="currentCasePii.PII_ADDRESS.value" :label="$t('App.Bar.CaseDetailIndicator.addressFieldLabel')" />
      </div>
    </v-card>
  </l-menu>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import PiiQuickAccessChip from '@/case-detail/app-bar/PiiQuickAccessChip.vue';
import detailViewService from '@/case-detail/services/detail.view.service';
import { formatToLocale } from '@/common/services/date.utils';

export default defineComponent({
  components: { PiiQuickAccessChip },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    currentCase() {
      return detailViewService.getCurrentLegalCase()!;
    },
    currentCasePii() {
      return this.currentCase.pii;
    },
    piiFormattedBirthDate() {
      if (this.currentCasePii?.PII_BIRTHDATE.value === '') {
        return '';
      }
      return formatToLocale(dayjs(this.currentCasePii?.PII_BIRTHDATE.value), 'LL');
    },
  },

  watch: {
    menu(to) {
      if (to) {
        this.$a.l(this.$a.e.CASE_PIIQUICKACCESS_OPEN);
      }
    },
  },
});
</script>

<style scoped>
/* NOTE(mba): placed by hand */
.card {
  margin-top: -9px;
  height: 40px !important;
  min-width: 25rem;
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.2) !important;
  overflow-y: hidden !important;
}
</style>
