import axios from 'axios';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t, i18nService } from '@/app/i18n/i18n.service';
import entityService, { DOC_FOLDER_ITEM } from '@/common/services/entity.service';

export type Folder = {
  value: string;
  title: string;
};

class FolderService {
  DOC_FOLDERS = [] as DOC_FOLDER_ITEM[];

  private state: {
    folders: string[];
  };

  constructor() {
    this.state = reactive({
      folders: [],
    });

    i18nService.runAfterLocaleInit(() => {
      this.DOC_FOLDERS = entityService.DOC_FOLDERS;
    });
  }

  public async init() {
    if (this.state.folders.length > 0) {
      return;
    }

    try {
      const response = await axios.get(config.API.FOLDERS_ENDPOINT);
      this.state.folders = response.data;
    } catch (e) {
      handleError($t('Common.DocFolder.loadError'), e as Error);
    }
  }

  public getLocalizedFolders(): Folder[] {
    return this.state.folders.map((value) => ({ value, title: this.getFolderText(value) }));
  }

  public getFolderText(value: string): string {
    const found = this.DOC_FOLDERS.find((f) => f.value === value);
    return found ? $t(found.title) : $t('Common.DocFolder.allFolders');
  }

  public getFolderValue(title: string) {
    const found = this.DOC_FOLDERS.find((f) => f.title === title);
    return found ? found.value : $t('Common.DocFolder.allFolders');
  }
}

export const folderService = new FolderService();
export const FolderServiceClass = FolderService;
