import { Client, Frame, IMessage, StompSubscription } from '@stomp/stompjs';
import SockJS from 'sockjs-client/dist/sockjs';

import { config } from '@/app/config';
import { authService } from '@/common/services/auth/auth.service';
import logger from '@/common/services/logging';

class WebSocketService {
  private stompClient: Client | null = null;

  private connectedPromise: Promise<void>;

  private connectedPromiseResolve: (() => void) | null = null;

  private subscriptions: Array<{ topic: string; callback: (message: IMessage) => void }> = [];

  private stompSubscriptions: Map<string, StompSubscription> = new Map();

  constructor() {
    // Initialize the connectedPromise
    this.connectedPromise = new Promise<void>((resolve) => {
      this.connectedPromiseResolve = resolve;
    });
  }

  /**
   * Establishes the WebSocket connection.
   * This method should be called once, ideally in the App.vue component.
   */
  async connect(): Promise<void> {
    // If already connected or reconnecting, return the existing promise
    if (this.stompClient && this.stompClient.active) {
      logger.info('WebSocket is already connected or in the process of reconnecting.');
      return this.connectedPromise;
    }

    try {
      const url = config.API.WS_HOST;
      const token = await authService.getToken(); // Fetch the JWT token
      const tenantId = authService.state.data?.tenant.id; // Fetch the Tenant ID

      const connectHeaders: Record<string, string> = {};
      if (token) connectHeaders.Authorization = `Bearer ${token}`;
      if (tenantId) connectHeaders['X-Tenant-ID'] = tenantId;

      this.stompClient = new Client({
        // debug: (str) => logger.info(str),
        webSocketFactory: () => new SockJS(url),
        connectHeaders, // Pass the headers to the connection
        reconnectDelay: 5000, // Automatically attempt to reconnect every 5 seconds
      });

      this.stompClient.onConnect = () => {
        logger.info('WebSocket connected');
        // Resolve the connectedPromise
        if (this.connectedPromiseResolve) {
          this.connectedPromiseResolve();
          this.connectedPromiseResolve = null;
        }
        // Subscribe to all stored subscriptions
        this.subscriptions.forEach(({ topic, callback }) => {
          const subscription = this.stompClient!.subscribe(topic, callback);
          this.stompSubscriptions.set(topic, subscription);
        });
      };

      this.stompClient.onStompError = (frame: Frame) => {
        logger.error(`Broker error: ${frame.headers.message}`);
      };

      this.stompClient.onWebSocketClose = () => {
        logger.info('WebSocket disconnected');
        // Reset the connectedPromise for reconnection attempts
        this.connectedPromise = new Promise<void>((resolve) => {
          this.connectedPromiseResolve = resolve;
        });
        // Clear the STOMP subscriptions
        this.stompSubscriptions.forEach((sub) => sub.unsubscribe());
        this.stompSubscriptions.clear();
      };

      this.stompClient.activate();
    } catch (error) {
      logger.error(`WebSocket connection error: ${JSON.stringify(error)}`);
      // Reject the connectedPromise if there's a connection error
      if (this.connectedPromiseResolve) {
        this.connectedPromiseResolve();
        this.connectedPromiseResolve = null;
      }
    }

    return this.connectedPromise;
  }

  /**
   * Checks if the WebSocket is active (connected or reconnecting).
   */
  public isConnected(): boolean {
    return this.stompClient ? this.stompClient.active : false;
  }

  /**
   * Subscribes to a specific topic.
   * If the connection is not yet established, it waits until it's ready.
   */
  async subscribeToTopic(topic: string, callback: (message: IMessage) => void): Promise<void> {
    const existingSubscription = this.subscriptions.find((sub) => sub.topic === topic && sub.callback === callback);
    if (existingSubscription) {
      logger.info(`Already subscribed to topic: ${topic}`);
      return;
    }

    // Store the subscription details
    this.subscriptions.push({ topic, callback });

    if (!this.stompClient) {
      logger.error('Cannot subscribe, WebSocket client is not initialized.');
      return;
    }

    // Wait for the connection to be established
    await this.connectedPromise;

    // Subscribe and store the STOMP subscription if not already subscribed
    if (!this.stompSubscriptions.has(topic)) {
      const subscription = this.stompClient.subscribe(topic, callback);
      this.stompSubscriptions.set(topic, subscription);
    }
  }

  /**
   * Unsubscribes from a specific topic.
   * This should be called in the component's beforeDestroy or unmounted hook.
   */
  unsubscribeFromTopic(topic: string): void {
    // Remove from stored subscriptions
    this.subscriptions = this.subscriptions.filter((sub) => sub.topic !== topic);

    // Unsubscribe from the STOMP subscription
    const stompSubscription = this.stompSubscriptions.get(topic);
    if (stompSubscription) {
      stompSubscription.unsubscribe();
      this.stompSubscriptions.delete(topic);
    }
  }

  /**
   * Sends a message to a specific destination.
   */
  sendMessage(destination: string, message: string): void {
    if (!this.stompClient || !this.isConnected()) {
      logger.error('Cannot send message, WebSocket is not connected.');
      return;
    }

    this.stompClient.publish({ destination, body: message });
  }
}

export default new WebSocketService();
