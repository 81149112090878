<template>
  <l-menu v-if="isLoggedIn" v-model="menu" :close-on-content-click="false" transition="scroll-y-transition">
    <template #activator="{ props }">
      <v-btn
        v-tippy="$t('Common.notifications')"
        icon
        data-testid="header_notifications"
        v-bind="props"
        @mouseenter="notificationService.loadNotifications()"
      >
        <v-badge :model-value="notificationService.noOfUnreadNotifications() > 0" color="accent">
          <template #badge>
            {{ notificationService.noOfUnreadNotifications() }}
          </template>
          <v-icon> mdi-bell-outline </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card width="400">
      <v-card-item class="card-item-overflow-visible">
        <div class="d-flex align-center">
          <v-card-title>
            {{ $t('Common.notifications') }}
          </v-card-title>
          <v-spacer />
          <v-btn class="mr-n1" icon="mdi-close" density="comfortable" @click.stop="menu = false" />
        </div>

        <div class="d-flex align-center" :style="{ visibility: fetched ? 'visible' : 'hidden' }">
          <v-switch
            v-model="notificationsShowOnlyUnread"
            class="switch-sm ma-0 mr-2 flex-grow-1"
            hide-details
            color="primary"
            inset
            density="compact"
            @click.stop
          >
            <template #label>
              <span class="text-body-1">{{ $t('App.Bar.Notifications.unreads') }}</span>
            </template>
          </v-switch>

          <v-spacer />

          <div class="">
            <v-btn
              v-blur
              v-tippy="{
                content: emailNotificationsEnabled
                  ? $t('App.Bar.Notifications.disableEmailNotifications')
                  : $t('App.Bar.Notifications.enableEmailNotifications'),
                placement: 'top',
              }"
              variant="text"
              icon="mdi-email"
              size="small"
              :color="emailNotificationsEnabled ? 'primary' : ''"
              density="comfortable"
              @click="emailNotificationsEnabled = !emailNotificationsEnabled"
            />

            <v-btn
              v-blur
              v-tippy="{
                content: $t('App.Bar.Notifications.markAllAsRead'),
                placement: 'top',
              }"
              :disabled="notificationService.noOfUnreadNotifications() === 0"
              variant="text"
              icon="mdi-circle-multiple"
              size="small"
              color="primary"
              density="comfortable"
              @click="notificationService.markAllAsRead()"
            />
          </div>
        </div>
      </v-card-item>

      <v-divider />

      <v-list max-height="60vh" class="overflow-y-scroll pl-4 pr-2">
        <v-list-item v-if="notificationService.visibleNotifications().length === 0 && fetched" three-lines>
          <v-list-item-subtitle v-if="notificationService.getShowOnlyUnread()">
            {{ $t('App.Bar.Notifications.allNotificationsRead') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            {{ $t('App.Bar.Notifications.noNotificationsAvailable') }}
          </v-list-item-subtitle>
        </v-list-item>
        <template v-else-if="fetched">
          <v-list-item
            v-for="notification in notificationService.visibleNotifications()"
            :key="notification.id"
            three-lines
            class="divider-bottom py-2 px-0"
          >
            <div class="d-flex align-start ga-2">
              <AppAvatar v-if="notification.requestorUserId" :user-id="notification.requestorUserId" class="align-self-center" />
              <v-avatar v-else size="36" color="outline" class="align-self-center">
                <v-icon> mdi-bell-plus-outline </v-icon>
              </v-avatar>
              <div>
                <p class="text-body-2">
                  {{ notification.message }}
                </p>
                <v-list-item-subtitle class="opacity-100 mt-1">
                  <v-btn
                    variant="tonal"
                    size="x-small"
                    color="primary"
                    @click="
                      menu = false;
                      notificationService.handleClick(notification);
                    "
                  >
                    {{ notification.actionButtonText }}
                  </v-btn>
                </v-list-item-subtitle>
              </div>

              <div class="ml-auto d-flex align-center">
                <ToggleDateText :date="notification.created" />
                <v-list-item-action>
                  <v-btn
                    v-blur
                    v-tippy="notification.status === 'READ' ? $t('App.Bar.Notifications.markAsUnread') : $t('App.Bar.Notifications.markAsRead')"
                    icon
                    variant="text"
                    density="comfortable"
                    class="ml-1"
                    size="x-small"
                    @click.stop="notificationService.toggleRead(notification)"
                  >
                    <v-icon v-if="notification.status === 'READ'"> mdi-circle-outline </v-icon>
                    <v-icon v-else color="primary"> mdi-circle </v-icon>
                  </v-btn>
                </v-list-item-action>
              </div>
            </div>
          </v-list-item>

          <div class="d-flex flex-column align-center px-4 pt-6">
            <v-icon size="large"> mdi-bell-outline </v-icon>
            <p class="pt-2 pb-4 px-6 text-center text-body-2">
              {{
                notificationsShowOnlyUnread ? $t('App.Bar.Notifications.noMoreUnreadNotifications') : $t('App.Bar.Notifications.noMoreNotifications')
              }}
            </p>
          </div>
        </template>
        <div v-else>
          <v-skeleton-loader class="py-2" type="list-item-three-line" :loading="true" />
          <v-skeleton-loader class="py-2" type="list-item-three-line" :loading="true" />
          <v-skeleton-loader class="py-2" type="list-item-three-line" :loading="true" />
        </div>
      </v-list>
    </v-card>
  </l-menu>
</template>

<script>
import AppAvatar from '@/app/components/AppAvatar.vue';
import notificationService from '@/app/notification/services/notification.service';
import ToggleDateText from '@/app/notification/ToggleDateText.vue';
import { authService } from '@/common/services/auth/auth.service';
import preferencesService from '@/common/services/preferences.service';

export default {
  components: {
    ToggleDateText,
    AppAvatar,
  },

  data() {
    return {
      notificationService,
      fetched: false,
      menu: false,
    };
  },

  computed: {
    isLoggedIn() {
      return authService.state.isLoggedIn;
    },
    notificationsShowOnlyUnread: {
      get() {
        return this.notificationService.getShowOnlyUnread();
      },
      set(value) {
        this.notificationService.setShowOnlyUnread(value);
      },
    },
    emailNotificationsEnabled: {
      get() {
        return preferencesService.state.notificationPreferences.emailNotificationsEnabled;
      },
      set(value) {
        preferencesService.updatePreferences({ notificationPreferences: { emailNotificationsEnabled: value } });
      },
    },
  },

  watch: {
    isLoggedIn(flag) {
      if (flag) {
        this.notificationService.loadNotifications().then(() => (this.fetched = true));
      }
    },
    menu(opened) {
      if (opened) {
        this.$a.l(this.$a.e.APP_NOTIFICATION_PANEL_OPEN);
      }
    },
  },
};
</script>

<style scoped>
.overflow-y-scroll {
  overflow-y: scroll;
}

.divider-bottom {
  border-bottom: 1px solid rgb(var(--v-theme-on-surface), 0.12);
}

:deep(.card-item-overflow-visible .v-card-item__content) {
  overflow: visible;
}
</style>
