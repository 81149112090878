<template>
  <div v-if="!!currentUserId" data-testid="avatar">
    <v-avatar v-tippy="tooltip" :color="avatarData.color" :size="finalSize">
      <span v-if="avatarData.type === 'initials'" :style="{ 'font-size': `${finalSize * 0.4}px` }" data-testid="header_avatar_initials">
        {{ avatarData.content }}
      </span>
      <span v-else>
        <v-icon color="white"> {{ avatarData.content }} </v-icon>
      </span>
    </v-avatar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import userService from '@/common/services/users/user.service';

export default defineComponent({
  props: {
    userId: {
      type: String,
    },
    overrideTooltip: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 36,
    },
  },

  computed: {
    finalSize() {
      return this.small ? 30 : this.size;
    },
    avatarData() {
      return userService.getUser(this.currentUserId ?? 'unknown')!.avatar;
    },
    tooltip() {
      return this.overrideTooltip ?? userService.getFullName(this.currentUserId);
    },
    currentUserId() {
      return this.userId ?? this.$currentUserId();
    },
  },
});
</script>

<style scoped></style>
